import React, { useEffect, useState, useContext } from "react";

import Card from "./card";
import Icon from "../Icon";
import { SessionContext } from "../../context";
// import Pages from "./properties-pages";

import EmptyRow from "../../components/property/empty-row";
import { GET_MY_FAVS } from "../../services";

const ShopGridV1 = ({ isLoading, properties }) => {
  const [favedData, setFavedData] = useState([]);

  const ctx = useContext(SessionContext);
  const isAuthed = !!ctx.isAuthed;

  const getUserFavs = async () => {
    const { data } = await GET_MY_FAVS();
    setFavedData(data?.data);
  };

  useEffect(() => {
    if (isAuthed) getUserFavs();
  }, []);

  useEffect(() => {
    if (!properties?.length) return;

    const $ = window.$;
    $(".go-top")
      .find("a")
      .on("click", function () {
        // onScrollTop();

        $(".quarter-overlay").fadeIn(1);
        $(window).scrollTop(0);
        setTimeout(function () {
          $(".quarter-overlay").fadeOut(300);
        }, 800);
      });
  }, [properties]);
  // ...
  return (
    <div>
      <div className="ltn__product-area ltn__product-gutter mt-80 mb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tab-content ">
                <div className="tab-pane fade active show" id="liton_product_grid">
                  <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                    {!isLoading && (
                      <div className="row go-top">
                        {properties.map((property) => (
                          <Card key={property.id} data={property} faved={favedData || []} />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* <Pages /> */}

              <EmptyRow title="AUCUNE ANNONCE NE CORRESPOND À VOTRE RECHERCHE" isLoading={isLoading} noEntities={!properties.length}>
                <p>nous n'avons trouvé aucun logement correspondant à vos critères.</p>
                <p>Effectuer une nouvelle recherche.</p>
                <p>Ou</p>
                <a href="#ltn__utilize-alert-drawer" className="ltn__utilize-toggle theme-btn-3 btn btn-effect-1 __btn-icon">
                  {/* <FontAwesomeIcon icon="fa-solid fa-bell" /> */}
                  <Icon icon="fa-solid fa-bell" />
                  Créer une alerte avec ces critères de recherche
                </a>
              </EmptyRow>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopGridV1;
