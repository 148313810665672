import React, { useEffect, useState } from "react";
import _ from "underscore";

import { withFormik } from "formik";
import * as yup from "yup";

import Navbar from "../../components/base/navbar";
import FakeHeader from "../../components/section/fake-header";
import EntryText from "../../components/entries/text";
import Footer from "../../components/base/footer";

import AccountNav from "../../components/base//account-nav";
import EmptyRow from "../../components/property/empty-row";

import storage from "../../libs/storage";
import { USER_CHANGE_PASSWORD } from "../../services";

const validationSchema = yup.object({
  currentPassword: yup.string().required().default(""),
  password: yup.string().required().min(6).default(""),
  passwordConfirmation: yup
    .string()
    .required()
    .when(["currentPassword", "password"], {
      is: (currentPassword, password) => {
        return !!currentPassword && !!password;
      },
      then: (schema) => schema.required().oneOf([yup.ref("password")], "Your passwords do not match.")
    })
    .default("")
});

const iniValues = validationSchema.cast();

const AccountDetails = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const cantSave = !_.isEmpty(props.errors);

  const onSaveHandler = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const { currentPassword, password, passwordConfirmation } = props.values || {};

    const { data, ...error } = await USER_CHANGE_PASSWORD({ currentPassword, password, passwordConfirmation });
    setIsLoading(false);

    if (error) return;
    storage.setItem("__SESSION", data);

    props.resetForm({
      values: { ...iniValues, currentPassword: "", password: "", passwordConfirmation: "" }
    });
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <div className="__expended-container">
      <Navbar />
      <FakeHeader />

      <div className="liton__wishlist-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__product-tab-area">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="ltn__tab-menu-list mb-50">
                        <AccountNav tabIdx={1} />
                      </div>
                    </div>

                    <div className="col-lg-8">
                      <div className="tab-content">
                        <div className="tab-pane active show">
                          <div className="ltn__myaccount-tab-content-inner">
                            <div className="ltn__form-box">
                              {!isLoading && (
                                <div>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <EntryText
                                        name="currentPassword"
                                        placeholder=""
                                        label="Mot de passse actuel"
                                        type="password"
                                        value={props.values.currentPassword}
                                        onChange={props.handleChange}
                                        error={props.touched?.currentPassword && props.errors?.currentPassword}
                                      />
                                      <EntryText
                                        name="password"
                                        placeholder=""
                                        label="Nouveau mot de pass"
                                        type="password"
                                        value={props.values.password}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        error={props.touched?.password && props.errors?.password}
                                      />
                                      <EntryText
                                        name="passwordConfirmation"
                                        placeholder=""
                                        label="Confirmez le nouveau mot de passe"
                                        type="password"
                                        value={props.values.passwordConfirmation}
                                        onChange={props.handleChange}
                                        error={props.touched?.passwordConfirmation && props.errors?.passwordConfirmation}
                                      />
                                    </div>
                                  </div>

                                  <div className="btn-wrapper">
                                    <button
                                      type="submit"
                                      className="btn theme-btn-1 btn-effect-1 text-uppercase"
                                      onClick={() => onSaveHandler()}
                                      disabled={cantSave}>
                                      Sauvegarder
                                    </button>
                                  </div>
                                </div>
                              )}

                              <EmptyRow isLoading={isLoading} noEntities={false} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FakeHeader auto />
      <Footer />
    </div>
  );
};

export default withFormik({
  validationSchema,
  validateOnMount: true,
  mapPropsToValues: () => ({ ...iniValues })
})(AccountDetails);
