import React, { useContext, useState } from "react";

import { SessionContext } from "../../context";

import Navbar from "../../components/base/navbar";
import FakeHeader from "../../components/section/fake-header";
import SearchForm from "../../components/section/search-form";
import PropertiesGrid from "../../components/property/properties-grid";
import Footer from "../../components/base/footer";
import PropertiesAction from "../../components/section/properties-action";
import useModal from "../../hooks/useModal";

const ShopLeftSidebarPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const ctx = useContext(SessionContext);
  const isAuthed = ctx.isAuthed;
  const isLoc = ctx?.user?.type_de_compte === "Locataire";
  useModal();
  // ...
  return (
    <div className="__expended-container">
      <Navbar />
      <FakeHeader />

      <SearchForm properties={properties} setProperties={setProperties} isLoading={isLoading} setIsLoading={setIsLoading} />
      <PropertiesGrid isLoading={isLoading} properties={properties} />

      <FakeHeader auto />

      {(!isAuthed || isLoc) && (
        <PropertiesAction
          title="Fatigé de chercher ton appart?"
          subTitle="Reçois des appartements selon tes critères, dès leur inscription."
          isProperties
          ctaLabel="Créer une alerte"
          ctaTarget={isAuthed ? "/account/mes-alertes" : "/register"}
        />
      )}

      <Footer />
    </div>
  );
};

export default ShopLeftSidebarPage;
