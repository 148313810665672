import React from "react";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import * as SolidIcons from "@fortawesome/free-solid-svg-icons";
// import * as RegularIcons from "@fortawesome/free-regular-svg-icons";
// import { solid, regular, brands, icon } from "@fortawesome/fontawesome-svg-core/import.macro";


const Icon = ({ icon = "", onClickHandler = null, style = {} }) => {
  if (!icon) return null;
  return (
    <i
      className={icon}
      style={style}
      onClick={(e) => {
        if (!onClickHandler) return;
        onClickHandler(e);
      }}></i>
  );
  // ...
  // let i = SolidIcons[icon] || RegularIcons[icon] || null;
  // if (!i) return null;
  // ...
  // return <FontAwesomeIcon icon={i} style={{ width: "20px", height: "auto" }} />;
  // return <FontAwesomeIcon icon={icon} />;
  // return (
  //   <FontAwesomeIcon
  //     // color="red"
  //     // icon={SolidIcons[icon]}
  //     icon={icon}
  //   />
  // );
};

export default Icon;
