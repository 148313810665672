import React, { useEffect, useState } from "react";

import cx from "classnames";

import { DAFAULT_MIN_LOYER, DAFAULT_MAX_LOYER } from "../../libs/entries";

const EntryRange = ({ _id, range, label = "", value, onChange, isReadOnly }) => {
  const $ = window.$;

  const siderSelector = `slider-range-${_id}`;
  const amoutSelector = `amount-${_id}`;

  useEffect(() => {
    const [min, max] = value;
    if (range) $(`.${amoutSelector}`).val("$" + min + " - $" + max);
    else $(`.${amoutSelector}`).val("$" + max);

    $(`.${siderSelector}`).slider({
      values: [min, max]
    });
  }, [value]);

  useEffect(() => {
    $(`.${siderSelector}`).slider({
      min: DAFAULT_MIN_LOYER,
      max: DAFAULT_MAX_LOYER,
      range: !!range,
      values: [DAFAULT_MIN_LOYER, DAFAULT_MAX_LOYER],
      slide: function (event, ui) {
        let min = Math.min(ui.values[0], ui.values[1]);
        let max = Math.max(ui.values[0], ui.values[1]);

        onChange([min, max]);
      }
    });
  }, []);

  return (
    <div className={cx("price_filter", { "__no-range": !range, "__read-only": !!isReadOnly })}>
      <p style={{ fontSize: "14px", fontWeight: "700", margin: "0 0 10px 0" }}>{label}</p>
      <div className={siderSelector} />
      <div className="price_slider_amount">
        <input type="text" className={amoutSelector} readOnly />
      </div>

      {isReadOnly && <div className="__readonly-inner" />}
    </div>
  );
};

export default EntryRange;
