import React from "react";

import Navbar from "../../components/base/navbar";
import FakeHeader from "../../components/section/fake-header";
import PropertiesAction from "../../components/section/properties-action";
import Footer from "../../components/base/footer";

const BlogDetailsPage = () => {
  return (
    <div>
      <Navbar />
      <FakeHeader />

      <div className="ltn__page-details-area ltn__blog-details-area mb-120">
        <div className="container">
          <div className="ltn__page-details-inner ltn__blog-details-inner">
            <h1 style={{ margin: "0 0 80px 0" }}>POLITIQUE SUR LA PROTECTION DES RENSEIGNMENTS PERSONNELS</h1>

            <h2>INTRODUCTION</h2>

            <p>
              Le respect de votre vie privée et la protection de vos renseignements personnels est très important pour Locsimple. (« locsimple », « nos », « nous » ou « notre »). Lorsque vous utilisez notre site Internet se trouvant au www.locsimple.com (le « Site ») ou que vous utilisez nos services de recherche d’appartement ou de cession de bail à l’intention des locataires ou les services de location immobilière ou relocalisation des locataires à l’intention des propriétaires (collectivement, les « Services »), locsimple s’assure de mettre en place des mesures de contrôle efficaces visant à protéger la nature confidentielle des renseignements personnels sous sa garde.
              En utilisant notre Site et les Services et afin de vous offrir des Services de qualité, nous pouvons avoir accès à certains renseignements personnels, soit des renseignements qui vous concernent ou qui, pris séparément ou combiné avec d’autres données, permettent de vous identifier. Ces renseignements peuvent inclure : civilité, nom, prénom, courriel, adresse postale, téléphones, renseignements financiers ou en matière de crédit ou de facturation, des renseignements relatifs au marketing et aux préférences de communication, des renseignements relatifs à l’utilisation de notre Site incluant des renseignements techniques sur les visites ou tout autre renseignement collecté à travers des témoins et toute autre information personnelle que vous considérez utile de nous fournir (les « Renseignements personnels »). De façon générale, les Renseignements personnels ne comprennent pas vos coordonnées professionnelles comme votre nom, votre titre, votre adresse ou votre numéro de téléphone au travail.

              La présente Politique sur la protection des renseignements personnels (la "Politique") définit les Renseignements personnels que nous pouvons recueillir auprès de vous, les raisons pour lesquelles nous les recueillons et la manière dont nous les utilisons et comment nous protégeons vos Renseignements personnels. Nous souhaitons également vous informer sur la façon dont nous les transmettons, pour des raisons techniques, à d’autres entités ou sociétés.La présente Politique décrit également les choix que vous pouvez faire concernant la manière dont nous recueillons, utilisons et protégeons vos Renseignements personnels.

              En nous fournissant des Renseignements personnels, par l’intermédiaire de notre Site ou via nos Services, par téléphone, par courriel, en personne ou par tout autre moyen, vous consentez à ce que vos Renseignements personnels soient collectés, utilisés et divulgués comme prévu dans la présente Politique.

            </p>
            <h2>1.	QUELLES SONT LES INFORMATIONS QUE NOUS RECUEILLONS</h2>

            <h4>Utilisation de nos Services</h4>

            <p>
              Nous recueillons les informations suivantes lorsque vous utilisez nos Services :
            </p>

            <ul>
              <li>	Vos informations personnelles, y compris votre nom, votre adresse électronique, votre numéro de téléphone.</li>

              <li>	Les informations de facturation et autres informations de paiement, notamment le nom et l'adresse de facturation, les détails du mode de paiement, tels que le numéro de carte de crédit ou les informations sur le compte bancaire.</li>
              <li>	Les Services que vous avez acquis auprès de nous, y compris le type de plan et les informations sur les transactions liées aux Services.</li>

              <li>	En fonction du Service choisi, il peut vous être demandé des informations supplémentaires, notamment des opinions, des évaluations, des commentaires, des informations sur vos besoins et préférences et des informations nécessaires afin de procéder à la location d’un appartement, notamment : (i) l’adresse de l’appartement qui vous intéresse; (ii) les informations sur les locataires, dont notamment les noms, adresses, adresses électroniques, numéros de téléphone, citoyenneté; (iii) la date que vous voulez emménager, l’âge de vos enfants; (iv) les informations sur les endosseurs, dont notamment leurs noms, adresses, adresses électroniques, numéros de téléphone, citoyenneté; (v) les animaux que vous détenez, dont le type d’animal et son poids; (vi) le numéro de plaque des véhicules que vous détenez; (vii) les noms et numéros de téléphone de personnes à identifier en cas d’urgence.</li>

              <li>	Tout autre Renseignement personnel fourni.</li>
            </ul>

            <h4>Données de connexion, d'utilisation et de support :</h4>

            <li>	Les données de connexion, qui peuvent inclure votre adresse IP, les pages que vous visitez au sein de nos Services, la date et l'heure auxquelles vous avez utilisé les Services, l'adresse de la page Web que vous avez visitée avant d'utiliser les Services, l'URL qui vous a dirigé vers nos Services, le type et les paramètres de votre navigateur, les informations relatives à votre appareil (telles que la marque, le modèle et le système d'exploitation), les informations relatives à la configuration de votre navigateur, les préférences linguistiques, les identifiants uniques et les témoins.</li>

            <li>	Les données d'utilisation et les analyses, qui peuvent inclure la fréquence de connexion, les fonctions que vous utilisez, la durée et le moment de l'utilisation, ainsi que les différents types d'activités entreprises par les utilisateurs, et les informations démographiques que nous pouvons vous demander, telles que votre sexe et votre âge.</li>

            <li>	Des informations générales de localisation, telles que l'adresse IP et la région dans laquelle vous vous trouvez lorsque vous vous connectez et utilisez les Services.</li>

            <li>	Les questions de support à la clientèle, les problèmes et les commentaires généraux que vous choisissez de fournir.</li>

            <h4>Sondages, événements et informations marketing</h4>

            <p>Si vous choisissez de participer à nos sondages, concours, événements, demandes d'informations, nous pouvons recueillir des informations vous concernant en rapport avec le sondage, le concours, la demande d'informations ou l'événement ; vos coordonnées, telles que votre nom, votre adresse électronique, votre numéro de téléphone; et des informations générales sur vous que vous choisissez de fournir, telles que vos préférences en matière de logement, .</p>

            <h4>Utilisation de notre Site ou interaction avec nous par courrier électronique</h4>

            <p>Lorsque vous naviguez sur notre Site ou interagissez avec nous par le biais de courriers électroniques, nous recueillons des informations vous concernant lesquelles sont décrites ci-dessous, dont certaines sont collectées automatiquement :</p>
            <ul>

              <li>	Les données de connexion, qui peuvent inclure votre adresse IP, les pages que vous visitez sur le Site, la date et l'heure de votre visite sur le Site, l'adresse de la page Web que vous avez visitée avant d'utiliser le Site, l'URL qui vous a dirigé vers nos Services, le type et les paramètres de votre navigateur, les informations relatives à votre appareil (telles que la marque, le modèle et le système d'exploitation), les informations relatives à la configuration de votre navigateur, les préférences linguistiques, les identifiants uniques et les témoins.</li>

              <li>	Les données d'utilisation et les analyses, qui peuvent inclure la fréquence de connexion, les fonctions que vous utilisez, la durée et le moment de l'utilisation, ainsi que les différents types d'activités entreprises par les utilisateurs, et les informations démographiques que nous pouvons vous demander, telles que votre sexe et votre âge.</li>

              <li>	Des informations générales de localisation, telles que l'adresse IP et la région dans laquelle vous vous trouvez lorsque vous vous connectez et utilisez les Services.</li>

              <li>	Si vous souhaitez obtenir des informations supplémentaires sur nos Services, utiliser nos fonctions "Créer une alerte" ou "Demande soumission" ou des fonctions similaires, vous inscrire pour recevoir des informations de notre part via courrier électronique, vous inscrire à un événement, un webinaire ou un concours, ou télécharger certains contenus, nous pouvons vous demander de nous fournir vos coordonnées, telles que votre nom, votre numéro de téléphone et votre adresse électronique, ainsi que certaines informations sur vos besoins ou préférences.</li>

              <li>	Les questions de support à la clientèle, les problèmes et les commentaires généraux que vous choisissez de fournir.</li>
            </ul>
            <h4>Appels téléphoniques</h4>
            <ul>
              <li>	Si vous communiquez avec nous par téléphone, nous pouvons enregistrer cet appel et vous demander de nous fournir vos coordonnées, telles que votre nom, votre adresse, votre numéro de téléphone et votre adresse électronique.</li>
            </ul>

            <h4>Visites de nos bureaux</h4>
            <ul>
              <li>	Si vous visitez nos bureaux, il peut vous être demandé de vous enregistrer en tant que visiteur et de fournir votre nom, votre adresse électronique, votre numéro de téléphone, ainsi que l'heure et la date de votre arrivée. De plus, en raison de la pandémie de COVID-19, il peut vous être demandé de fournir des informations concernant votre état de santé, notamment votre température, les symptômes liés au COVID-19, l'exposition à des personnes positives au COVID-19 et les antécédents de voyage récents.</li>
            </ul>

            <p><strong>IMPORTANT</strong> : Si vous nous fournissez des Renseignements personnels concernant d’autres personnes, vous déclarez que vous avez le pouvoir de le faire et, le cas échéant, que vous avez obtenu le consentement nécessaire, et vous reconnaissez que ces informations peuvent être utilisées conformément à la présente Politique.</p>

            <h2>2.	POURQUOI VOS INFORMATIONS SONT-ELLES RECUEILLIES ET COMMENT SONT-ELLES UTILISÉES ?</h2>

            <p>locsimple recueille et utilise vos Renseignements personnels pour :</p>
            <ol type="a">
              <li>vous fournir les Services, notamment en vous aidant dans votre recherche d’appartement ou de cession de bail, si vous êtes un locataire ou vous aidant à faire de la location immobilière ou relocaliser des locataires si vous êtes un propriétaire;</li>
              <li>gérer votre dossier et communiquer avec vous et maintenir votre compte client;</li>
              <li>traiter les paiements des Services;</li>
              <li>vous envoyer des renseignements concernant les Services ou informations que vous avez demandé ou pour répondre à vos requêtes et questions;</li>
              <li>faciliter les communications, notamment en recueillant des informations sur les données de votre appareil et votre numéro de téléphone;</li>
              <li>communiquer avec vous (par le biais de de votre téléphone, d'une alerte, d'un courriel, d'un courrier ou autrement) et vous présenter du matériel publicitaire et des informations supplémentaires sur nos Services, produits, sondages, concours, promotions et offres spéciales, ainsi qu’offrir notre service à la clientèle;</li>
              <li>nous aider à en savoir plus sur vos besoins, préférences et interactions avec notre Service; et</li>
              <li>réaliser des statistiques, des études de marché et de performance pour nous aider à évaluer nos Services, mesurer notre performance et améliorer les expériences, Services et produits que nous offrons.  locsimple n'utilisera pas vos Renseignements personnels à d'autres fins que celles indiquées dans le présent document.</li>
            </ol>

            <p>locsimple s’efforce de limiter la collecte de Renseignements personnels à ce qui est strictement nécessaire afin d’accomplir les fins pour lesquelles ils sont recueillis. Nous ne divulguerons ni n’utiliserons vos Renseignements personnels à des fins autres, mais notez les précisions et exceptions suivantes:</p>

            <ul>
              <li>	Application de la loi : Nous pouvons partager vos Renseignements personnels pour répondre à des demandes d'application de la loi, à des ordonnances de tribunaux ou à d'autres procédures légales ou si nous pensons qu'une telle divulgation est nécessaire pour enquêter, prévenir ou répondre à des activités illégales, des fraudes, des menaces physiques pour vous ou d'autres personnes ou tel que  requis par toute autre loi ou réglementation applicable et dans la mesure nécessaire pour protéger la propriété, les intérêts et les droits de locsimple.</li>
              <li>	Statistiques et rapports agrégés : Nous utilisons et divulguons librement les données sous une forme anonyme, par exemple, à des fins de statistiques, d'analyse, de calibrage, de gestion et d'amélioration de la qualité en relation avec les Services, ou pour le développement ou la fourniture d'autres produits et Services par locsimple ou pour le bénéfice de tous les utilisateurs de ses Services en général. locsimple garantit toutefois que ces données agrégées et anonymisées ne pourront jamais permettre de vous identifier, de quelque manière que ce soit, ni contenir des Renseignements personnels.</li>
              <li>	Nos employés et fournisseurs de services autorisés ("Personnel autorisé") : Le Personnel autorisé peut avoir accès à vos Renseignements personnels dans le but de nous aider à fournir les Services. Le Personnel autorisé accède à ces Renseignements personnels et les utilise conformément à nos instructions, seulement s’ils ont besoin de connaître de telles informations, et dans le cadre d'obligations strictes en matière de confidentialité et de sécurité. Sachez que nous nous assurons que notre Personnel autorisé gère ces renseignements avec toute la discrétion et la rigueur nécessaires, et se conforment à la présente Politique et aux exigences légales et réglementaires en vigueur.</li>
              <li>	Transferts d'entreprises : locsimple peut être impliquée dans la vente de la totalité ou de la quasi-totalité de ses activités ou dans toute autre transaction similaire. Dans le cadre de cette transaction, nous pouvons céder ou divulguer vos Renseignements personnels à l'organisation qui acquiert locsimple, actuelle ou potentielle, mais nous exigerons de cette organisation qu'elle accepte de protéger la confidentialité de vos Renseignements personnels d'une manière conforme à la présente Politique.</li>
              <li>	Consentement explicite : locsimple pourra également divulguer vos Renseignements personnels si vous le demandez ou y consentez expressément.</li>
            </ul>










            <h2>3.	CONSENTEMENT</h2>

            <p>
              Règle générale, locsimple obtiendra directement les Renseignements personnels nécessaires auprès de vous, et ce, avec votre consentement, sous réserve d’exceptions prévues à la loi. Les Renseignements personnels ne seront utilisés qu’aux fins pour lesquelles ils ont été recueilli à moins d’obtenir votre consentement.
              locsimple ne recueillera, n’utilisera, ni ne divulguera les Renseignements personnels sans votre consentement à moins qu’il ne soit autorisé ou obligé de le faire en vertu de la loi.
              À noter que vous avez le droit de refuser de nous communiquer des Renseignements personnels. Vous avez également le droit, sous réserve d’un préavis raisonnable et des restrictions légales ou contractuelles applicables, de retirer votre consentement concernant l’utilisation des Renseignements personnels déjà collectés en communiquant avec notre responsable de la protection des renseignements personnels aux coordonnées ci-bas. Le refus de communiquer des renseignements ou le retrait de votre consentement pourraient nous empêcher de fournir ou de continuer à vous fournir certains de nos Services.
              En outre, nous fournissons des instructions d'"exclusion" ou de "désinscription" sur nos communications électroniques pour vous permettre de vous retirer des communications électroniques futures.

            </p>

            <h2>4.	VOS RENSEIGNEMENTS PERSONNELS ET LES TIERS</h2>

            <p>Les Services ou notre Site peuvent faire référence, intégrer ou établir des liens vers des sites tiers. Ces sites de tierces parties (" Sites de tierces parties "), dont les liens vous sont fournis uniquement pour votre commodité et ne sont pas sous notre contrôle. Ces Sites de tierces parties sont régis par leurs propres politiques de confidentialité et peuvent recueillir vos Renseignements personnels. Les Sites de tierces parties ne sont pas développés, vendus ou conçus par locsimple. Par conséquent, locsimple ne garantit pas et n'est pas responsable de la collecte, de l'utilisation et de la divulgation de vos Renseignements personnels par les Sites de tierces parties. La présente Politique ne s'applique pas aux pratiques des tierces parties et locsimple n'assume aucune responsabilité quant à votre utilisation de ces Sites de tierces parties ou à votre accès aux Sites de tierces parties par l'intermédiaire des Services ou du Site.  En utilisant les Services ou notre Site, ou en accédant à des Sites de tierces parties par l'intermédiaire de nos Services ou de notre Site, vous dégagez locsimple de toute responsabilité quant aux actions, pratiques et omissions de ces tierces parties. Veuillez consulter la Politique de chaque Sites de tierces parties que vous visitez. Nous ne louons ni ne vendons vos Renseignements personnels à des tierces parties.</p>

            <p>En outre, en souscrivant aux Services, vous accordez à locsimple le droit de partager vos Renseignements personnels avec des prestataires de services tiers que nous jugeons acceptables et qui utiliseront ces informations pour nous aider à répondre à vos demandes et à exécuter les Services. Veuillez noter que nous ne partagerons que les Renseignements personnels nécessaires à l'utilisation de ces services tiers, uniquement dans le but de vous fournir les Services, et que nous nous assurerons que ces fournisseurs tiers acceptent de garder ces renseignements confidentiels et en sécurité.</p>

            <p>Les fournisseurs de services conservent les renseignements dont ils ont besoin pour exécuter les fonctions pour lesquelles ils ont été nommés et nous n’autorisons pas ces tiers à utiliser ou à divulguer des renseignements personnels pour leur propre marketing ou à d’autres fins. En aucun temps, locsimple ne vendra ni n’échangera vos renseignements personnels moyennant paiement.</p>

            <h2>5.	TRANSFERT DE RENSEIGNEMENTS PERSONNELS À L’ETRANGER</h2>

            <p>Veuillez noter que certains de nos fournisseurs de services qui traitent, gèrent ou stockent des Renseignements personnels pour notre compte peuvent se trouver à l’étranger. Ainsi, il est possible que certains Renseignements personnels vous concernant soient transférés dans un autre pays et qu’ils soient soumis aux lois de ce pays.</p>
            <p>Nous nous assurons toutefois de protéger les Renseignements personnels que nous avons sous notre garde, y compris les Renseignements personnels confiés à un fournisseur qu’il soit situés au Canada ou à l’étranger. Nos politiques et pratiques en matière de protection des Renseignements personnels requièrent qu’un engagement écrit soit signé par ce fournisseur de services afin de respecter son obligation de préserver la confidentialité et la sécurité des Renseignements personnels qui lui sont confiés. Cela comprend notamment de mettre en place des mesures de sécurité robustes et efficaces, mais également l’interdiction de divulguer vos renseignements à autrui.</p>

            <h2>6.	PROTECTION DES RENSEIGNEMENTS PERSONNELS</h2>

            <p>Nous prenons les mesures appropriées pour assurer la confidentialité des Renseignements personnels et pour protéger ces Renseignements personnels contre la perte, ainsi que contre l'accès, la divulgation, la copie, l'utilisation ou la modification non autorisés, en tenant compte, en particulier, de la sensibilité des renseignements et des fins auxquelles ils sont destinés.</p>

            <p>locsimple utilise des procédures physiques, électroniques et de gestion commercialement raisonnables et appropriées et les standards généralement acceptés dans l’industrie pour sauvegarder et sécuriser les Renseignements personnels que nous recueillons.</p>

            <p>En outre, les Renseignements personnels que nous détenons ne sont donc accessibles qu’aux personnes qui ont qualité pour en prendre connaissance et qui les consultent uniquement lorsque cela est nécessaire dans l’exercice de leurs fonctions.</p>
            <p>Pour ce faire, nous nous assurons notamment de restreindre les accès à nos bureaux et aux équipements informatiques, de former notre personnel et de requérir de nos mandataires et fournisseurs de biens et de services ayant accès à des Renseignements personnels de signer des ententes de confidentialité et de mettre en place des mesures de sécurité jugées équivalentes.</p>
            <p>À noter toutefois, étant donné qu'aucun mécanisme n'offre une sécurité maximale, l'utilisation d'Internet pour transmettre, créer ou stocker des Renseignements personnels comporte toujours un certain risque.</p>
            <p>Si vous pensez que vos Renseignements personnels ont été compromis, nous vous invitons à contacter avec notre responsable de la protection des renseignements personnels aux coordonnées ci-bas mentionnées.</p>

            <h2>7.	MINEURS</h2>

            <p>Notre Site et nos Services sont conçu pour être utilisé par des personnes âgées de 18 ans et plus.  </p>
            <p>locsimple ne recueille pas sciemment de Renseignements personnels auprès d'enfants de moins de 18 ans.</p>

            <p>Cependant, comme il n’y a pas d’âge minimum pour signer un bail seul, si un mineur de moins de 14 ans souhaite utiliser notre Site et nos Services, la collecte des Renseignements personnels de ce mineur ne pourra se faire sans le consentement du titulaire de l’autorité parentale ou du tuteur. Si nous apprenons que nous avons recueilli des Renseignements personnels sans le vouloir auprès de personnes âgées de moins de 14 ans, sans avoir obtenu le consentement du titulaire de l’autorité parentale ou du tuteur, nous veillerons à ce qu'ils soient supprimés de nos dossiers le plus rapidement possible. Si vous êtes le titulaire de l’autorité parentale ou le tuteur d’un mineur de moins de 14 ans et que vous savez que votre enfant mineur a fourni des Renseignements personnels, nous vous invitons à contacter avec notre responsable de la protection des renseignements personnels aux coordonnées ci-bas mentionnées afin que nous puissions être en mesure de faire les actions nécessaires.</p>


            <h2>8.	PÉRIODE DE CONSERVATION</h2>

            <p>À l'exception des informations non identifiables et anonymisées que locsimple peut conserver à perpétuité et en tout lieu raisonnable, locsimple hébergera et conservera vos Renseignements personnels pour la durée nécessaire aux fins prévues dans la présente Politique et afin de nous assurer d’être en conformité avec nos obligations légales et réglementaires. Vous pouvez à tout moment retirer ou modifier votre consentement relatif à l’usage que nous faisons de vos Renseignements personnels, ou demander que nous les supprimions définitivement, sous réserve de conditions imposées par la loi.</p>

            <p>Si vous souhaitez en apprendre davantage sur la durée de conservation de vos Renseignements personnels, nous vous invitons à communiquer avec notre responsable de la vie privée dont les coordonnées figurent ci-bas.</p>

            <h2>9.	ACCÈS, RECTIFICATION ET MISE À JOUR DES RENSEIGNEMENTS PERSONNELS</h2>

            <p>Vous avez le droit d'accéder à vos Renseignements personnels et d'en obtenir des copies. Vous avez également le droit de mettre à jour, de rectifier et de corriger tout Renseignement personnel qui, selon vous, est inexact ou incomplet. Pour ce faire, veuillez soumettre une demande écrite à cet effet à écrivant au responsable de la protection des renseignements personnels à l’adresse ci-bas, qui vous répondra par écrit au plus tard dans les 30 jours de la date de réception de toute demande à cet égard.</p>

            <p>Si vous désirez obtenir des copies de vos Renseignements personnels, il se pourrait que nous devions vous exiger des frais minimes, lesquels vous seraient préalablement divulgués, et ce, simplement afin de couvrir les coûts de la transcription, de la reproduction ou de la transmission.</p>

            <h2>10.	COMMUNICATIONS ÉLECTRONIQUES (E-MAILS)</h2>

            <p>Votre adresse électronique peut être utilisée pour vous envoyer des communications électroniques de temps à autre. À tout moment, vous pouvez nous demander de cesser de vous envoyer des communications électroniques en contactant le responsable de la protection des renseignements personnels de locsimple aux coordonnées figurant ci-bas ou en utilisant les instructions de "désinscription" figurant au bas de ces communications.</p>

            <h2>11.	TÉMOINS ET AUTRES TECHNOLOGIES DE SUIVI SUR NOTRE SITE, PAR LE BIAIS DE NOS SERVICES ET DANS LES COMMUNICATIONS PAR COURRIER ÉLECTRONIQUE.</h2>

            <p>Nous utilisons des outils courants de collecte d'informations, tels que des outils de collecte de données d'utilisation, des témoins (« cookies ») et des technologies similaires pour collecter automatiquement des informations pouvant contenir des Renseignements personnels à partir de votre ordinateur ou de votre appareil mobile lorsque vous naviguez sur notre Site, utilisez nos Services ou interagissez avec les courriers électroniques que nous vous avons envoyés.</p>

            <p>En accédant et en naviguant sur notre Site et en utilisant nos Services, vous consentez à l'utilisation de témoins conformément aux termes de la présente Politique. Vous pouvez contrôler et gérer les témoins de différentes manières. N'oubliez pas que la suppression ou le blocage des témoins peut avoir un impact négatif sur votre expérience utilisateur et que certaines parties de notre Site peuvent ne plus être entièrement accessibles.</p>

            <p>La plupart des navigateurs acceptent automatiquement les témoins, mais vous pouvez choisir d'accepter ou non les témoins par le biais des contrôles de votre navigateur, qui se trouvent souvent dans le menu "Outils" ou "Préférences" de votre navigateur. Vous trouverez de plus amples informations sur la manière de modifier les paramètres de votre navigateur ou de bloquer, gérer ou filtrer les témoins dans le fichier d'aide de votre navigateur ou sur des sites tels que www.allaboutcookies.org.</p>



            <h2>12.	MODIFICATIONS DE LA PRÉSENTE POLITIQUE</h2>

            <p>locsimple réserve le droit d’apporter à tout moment de faire des modifications à la présente Politique. Toutes telles modifications seront affichées sur le Site et entreront en vigueur immédiatement après leur affichage. Nous prendrons des mesures raisonnables, au besoin, pour informer les particuliers concernés de toute modification importante. Lorsque nous apporterons des modifications à la présente Politique, nous réviserons la date de " dernière mise à jour " indiquée au début de la présente Politique.
              Vous pouvez communiquer avec notre responsable de la protection de la vie privée à l’adresse ou aux numéros indiqués ci-dessus pour obtenir un exemplaire à jour de cette Politique.</p>

            <h2>13.	CONDITIONS D'UTILISATION DU SITE ET DES SERVICES</h2>

            <p>Les conditions d'utilisation du Site et des Services, qui sont disponibles à l'adresse www.locsimple.com contiennent des dispositions importantes qui déclinent et excluent la responsabilité de locsimple et d'autres personnes en ce qui concerne votre utilisation des Services, ainsi que des dispositions déterminant le droit applicable et la juridiction exclusive pour la résolution de tout litige concernant votre utilisation Du Site et des Services. Chacune de ces dispositions s'applique également à tout litige qui pourrait survenir en rapport avec la présente Politique et la collecte, l'utilisation et la divulgation de vos Renseignements personnels, et elles ont la même force et le même effet que si elles avaient été reproduites directement dans la présente Politique.</p>



            <h2>14.	QUESTIONS, COMMENTAIRES, PLAINTES ET RESPONSABLES DE LA PROTECTOIN DE LA VIE PRIVÉE</h2>

            <p>locsimple est responsable des Renseignements personnels sous sa garde ou son contrôle et elle a désigné un responsable de la protection de la vie privée qui doit assurer le respect de cette Politique et des lois applicables en matière de protection sur la vie privée.</p>
            <p>Si vous avez des questions, des commentaires, des préoccupations ou une plainte concernant la présente Politique et les pratiques de locsimple en matière de confidentialité, nous vous invitons à contacter notre responsable de la protection de vie privée, soit Mr. David BLOUIN, aux coordonnées suivantes :</p>

            <p style={{ marginBottom: '0', marginTop: '0' }}>Locsimple.</p>
            <p style={{ marginBottom: '0', marginTop: '0' }}>CP 82024 QUEBEC SUCC LAC SAINT-CHARLES</p>
            <p style={{ marginBottom: '0', marginTop: '0' }}>Québec (Québec)</p>
            <p style={{ marginBottom: '0', marginTop: '0' }}>G3G 3C1</p>
            <p style={{ marginBottom: '0', marginTop: '0' }}>Courriel : infos@locsimple.com</p>

            <p>Vous avez également la possibilité de déposer une plainte par écrit à la Commission d’Accès à l’information- Direction de la surveillance à l’adresse suivante : 900-2045, rue Stanley, Montréal, Québec, H4A 2V4 ou par courriel au cai.communications@cai.gouv.qc.ca.</p>


          </div>
        </div>
      </div>

      <PropertiesAction />
      <Footer />
    </div>
  );
};

export default BlogDetailsPage;
