import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import _ from "underscore";

import Icon from "../Icon";
import MuiEntryText from "../entries/text";
import LoginSocial from "../../components/base/login-social";

import { USER_LOGIN, VALIDATE_ACCESS_TOKEN } from "../../services";
import storage from "../../libs/storage";

import useQuery from "../../hooks/useQuery";

import { withFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  identifier: yup.string().required().default(""),
  password: yup.string().required().default("")
});

const iniValues = validationSchema.cast();

const LoginForm = ({ values, errors, ...props }) => {
  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const cantSave = !_.isEmpty(errors);

  let login_provider = "";
  if (location.pathname === "/login/google") login_provider = "google";
  if (location.pathname === "/login/facebook") login_provider = "facebook";
  const access_token = query.get("access_token");

  const onTryLogin = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const { error, data } = await USER_LOGIN(values);
    setIsLoading(false);
    if (error) return setLoginError("Une erreur s'est produite réessayez");

    storage.setItem("__SESSION", data);
    navigate("/");
  };

  useEffect(() => {
    const validateAccessToken = async () => {
      if (isLoading) return;
      setIsLoading(true);
      const { error, reason, data } = await VALIDATE_ACCESS_TOKEN(access_token, login_provider);
      setIsLoading(false);
      if (error) return setLoginError("Token d'access n'est pas valide");

      storage.setItem("__SESSION", data);
      // Maybe decode jwt to add additional step to choose account type
      navigate("/");
    };

    if (access_token && access_token.length > 100 && login_provider && login_provider.length > 0) {
      validateAccessToken().catch(console.error);
    }
  }, [access_token, login_provider]);

  return (
    <div>
      <div className="ltn__login-area pb-65">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area text-center">
                <h1 className="section-title">
                  Se connecter <br />à votre compte <br />via
                </h1>
              </div>
            </div>
          </div>

          <LoginSocial />
          <h4 style={{textAlign: "center" }}>Ou</h4>
          {!!loginError && (
            <div className="row">
              <div className="col-lg-12 form-messege">
                <p className="error text-center">{loginError}</p>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-lg-6">
              <div className="account-login-inner">
                <div className="ltn__form-box contact-form-box">
                  <MuiEntryText type="text" name="identifier" label="Email*" value={values.email} onChange={props.handleChange} />
                  <MuiEntryText type="password" name="password" label="Mot de passe*" value={values.password} onChange={props.handleChange} />

                  <div className="btn-wrapper mt-0">
                    <button className="theme-btn-1 btn btn-block" type="submit" onClick={onTryLogin} disabled={cantSave} style={{ height: "60px" }}>
                      {!isLoading && "Se connecter"}
                      {isLoading && (
                        <Icon
                          icon="fa-1x fa-solid fa-circle-notch fa-spin"
                          style={{
                            "--fa-animation-duration": "1s"
                          }}
                        />
                      )}
                    </button>
                  </div>
                  <div className="go-to-btn mt-20">
                    <a href="#" title="Forgot Password?" data-bs-toggle="modal" data-bs-target="#ltn_forget_password_modal">
                      <small>Mot de passe oublié?</small>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="account-create text-center pt-50">
                <h4>Vous n'avez pas de compte?</h4>
                <div className="btn-wrapper go-top">
                  <Link to="/register" className="theme-btn-1 btn black-btn">
                    Créez votre compte
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFormik({
  validationSchema,
  validateOnMount: true,
  mapPropsToValues: () => ({ ...iniValues })
})(LoginForm);
