import React, { Component } from "react";
import Copyright from "./copyright";

// import { onScrollTop } from "../../libs/dom";

class Footer extends Component {
  componentDidMount() {
    const $ = window.$;
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = process.env.PUBLIC_URL + "/assets/js/main.js";
    document.body.appendChild(minscript);
    // ...
    $(".go-top")
      .find("a")
      .on("click", function () {
        // onScrollTop();

        $(".quarter-overlay").fadeIn(1);
        $(window).scrollTop(0);
        setTimeout(function () {
          $(".quarter-overlay").fadeOut(300);
        }, 800);
      });
    // ...
    $(document).on("click", ".theme-btn-1 ", function () {
      $("div").removeClass("modal-backdrop");
      $("div").removeClass("show");
      $("div").removeClass("fade");
      $("body").attr("style", "");
    });
  }

  render() {
    return (
      <footer className="ltn__footer-area">
        <Copyright />
      </footer>
    );
  }
}

export default Footer;
