import React, { useContext } from "react";

import Navbar from "../../components/base/navbar";
import Banner from "../../components/section/banner";
import OwnerAbout from "../../components/section/owner-about";
import Brand from "../../components/section/brand";
import PropertiesAction from "../../components/section/properties-action";
import Footer from "../../components/base/footer";
import { SessionContext } from "../../context";

const Home = () => {
  const ctx = useContext(SessionContext);
  const isAuthed = !!ctx.isAuthed;
  // ...
  return (
    <div>
      <Navbar />
      <Banner />
      <OwnerAbout />
      <Brand />
      {!isAuthed && (
        <PropertiesAction
          isHome
          title="Tu es propriétaire?"
          subTitle="Inscrits tes annonces afin que vos annoces soient automatiquement envoyées à plusieurs locataires."
          ctaTarget={"/register"}
          ctaLabel={"Afficher Mes annonces"}
          bg="section-bg-1"
        />
      )}
      <Footer />
    </div>
  );
};

export default Home;
