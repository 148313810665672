import React from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";

const MuiEntrySelect = ({ name, value, label = "", placeholder = "", onChange, onBlur, options, error, disabled }) => {
  let curValue = placeholder || "";

  if (value && options?.length > 0) {
    options.forEach((opt) => {
      if (opt.value === value) curValue = opt.label;
    });
  }

  return (
    <div className="MuiEntry MuiEntrySelect">
      <FormControl fullWidth error={!!error} disabled={disabled}>
        <InputLabel>{label}</InputLabel>
        <Select name={name} value={value} placeholder={placeholder} onChange={onChange} onBlur={onBlur}>
          {options.map((opt, idx) => (
            <MenuItem key={idx} value={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
        {!!error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default MuiEntrySelect;
