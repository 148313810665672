import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { GET_MY_VISITE, DELETE_VISITE } from "../../services";

import Navbar from "../../components/base/navbar";
import FakeHeader from "../../components/section/fake-header";
import Footer from "../../components/base/footer";

import AccountNav from "../../components/base//account-nav";
import ConfirmBox from "../../components/base/confirm-box";

import CardRow from "../../components/property/visite-row";
import EmptyRow from "../../components/property/empty-row";
import useModal from "../../hooks/useModal";

const Account = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [alerts, setAlerts] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [edit, setEdit] = useState({});

  const getUserVisites = async () => {
    setIsLoading(true);
    const { data } = await GET_MY_VISITE();

    const alerts = (data?.data || []).map((a) => ({ id: a.id, ...a.attributes }));
    setAlerts(alerts);
    setEdit({});
    setIsLoading(false);
  };

  const onDeleteVisite = async (isDelete) => {
    if (!isDelete) return setDeleteId(null);
    if (isLoading) return;

    await DELETE_VISITE(deleteId);
    getUserVisites();
  };

  useModal();

  useEffect(() => {
    getUserVisites();
  }, []);

  const doneDemandes = alerts.filter((d) => d.etat_visite !== "en_attente");
  const attsDemandes = alerts.filter((d) => d.etat_visite === "en_attente");

  return (
    <div className="__expended-container">
      <Navbar />
      <FakeHeader />

      <div className="liton__wishlist-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__product-tab-area">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="ltn__tab-menu-list mb-50">
                        <AccountNav tabIdx={4} />
                      </div>
                    </div>

                    <div className="col-lg-8">
                      <div className="tab-content">
                        <div className="tab-pane active show" id="ltn_tab_1_3">
                          <div className="ltn__myaccount-tab-content-inner">
                            {!!alerts.length && (
                              <>
                                {!!doneDemandes.length && (
                                  <div style={{ margin: "0 0 50px 0" }}>
                                    <div className="__cards">
                                      <div className="__cards__header">
                                        <div className="__title">Mes Visite</div>
                                        <div className="__actions">
                                          <div>Actions</div>
                                        </div>
                                      </div>
                                      <div className="__cards__body">
                                        {doneDemandes.map((data, idx) => (
                                          <CardRow
                                            key={idx}
                                            data={data}
                                            isProp
                                            onEditVisite={() => {
                                              const lId = data.logement.data.id;
                                              const vId = data.id;
                                              navigate(`/properties/${lId}/review-visite/${vId}`);
                                            }}
                                            onDeleteVisite={(id) => setDeleteId(id)}
                                          />
                                        ))}
                                      </div>
                                    </div>

                                    {/* <Pages /> */}
                                  </div>
                                )}

                                {!!attsDemandes.length && (
                                  <div>
                                    <div className="__cards">
                                      <div className="__cards__header">
                                        <div className="__title">En Attente</div>
                                        <div className="__actions">
                                          <div>Actions</div>
                                        </div>
                                      </div>
                                      <div className="__cards__body">
                                        {attsDemandes.map((data, idx) => (
                                          <CardRow
                                            key={idx}
                                            data={data}
                                            isProp
                                            onEditVisite={() => {
                                              const lId = data.logement.data.id;
                                              const vId = data.id;
                                              navigate(`/properties/${lId}/review-visite/${vId}`);
                                            }}
                                            onDeleteVisite={(id) => setDeleteId(id)}
                                          />
                                        ))}
                                      </div>
                                    </div>

                                    {/* <Pages /> */}
                                  </div>
                                )}
                              </>
                            )}

                            <EmptyRow title="Vous n'avez pas de demandes" isLoading={isLoading} noEntities={!alerts.length}></EmptyRow>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmBox title="cette visite?" handler={onDeleteVisite} />

      <FakeHeader auto />
      <Footer />
    </div>
  );
};

export default Account;
