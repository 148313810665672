import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { SessionContext } from "../../context";

import Icon from "../Icon";
import AlertDrawer from "../drawers/alert-drawer";

const Banner = () => {
  const publicUrl = process.env.PUBLIC_URL;
  const ctx = useContext(SessionContext);
  const isAuthed = ctx.isAuthed;
  const isPro = !!ctx.isPro;

  return (
    <div className="ltn__slider-area ltn__slider-3  section-bg-1">
      <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
        <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3-normal ltn__slide-item-3">
          <div className="ltn__slide-item-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 align-self-center">
                  <div className="slide-item-info">
                    <div className="slide-item-info-inner ltn__slide-animation">
                      <h1 className="slide-title-1 animated">
                        Fatigué.e de chercher <br /> ton appart?
                      </h1>
                      <div className="slide-brief animated">
                        <p>
                          <strong>Reçois des appartements selon tes critères, dès leur inscription.</strong> <br /> Cela n'aura jamais été aussi{" "}
                          <strong>simple</strong>!
                        </p>
                      </div>
                      <div className="btn-wrapper animated ">
                        {isAuthed && !isPro && (
                          <a href="#ltn__utilize-alert-drawer" className="ltn__utilize-toggle theme-btn-1 btn btn-effect-3 __btn-icon">
                            <Icon icon="fa-solid fa-bell" style={{ color: "#fff", fontSize: "25px" }} />
                            Créer une alerte
                          </a>
                        )}
                        {!isAuthed && (
                          <>
                            <Link to="/register" className="theme-btn-1 btn btn-effect-1 go-top">
                              Créer une alerte
                            </Link>
                            <Link to="/login" className="theme-btn-3 btn btn-effect-1 go-top">
                              Se connecter
                            </Link>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="slide-item-img">
                    <img src={publicUrl + "/images/img1.jpg"} alt="#" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AlertDrawer setEdit={() => {}} />
    </div>
  );
};

export default Banner;
