import React, { useEffect, useState } from "react";

import Navbar from "../../components/base/navbar";
import FakeHeader from "../../components/section/fake-header";
import Footer from "../../components/base/footer";

import { GET_USER_PROFILE, GET_MY_PROPERTIES, GET_MY_BILLINGS } from "../../services";

import AccountNav from "../../components/base//account-nav";
import CardRow from "../../components/property/bill-row";
import EmptyRow from "../../components/property/empty-row";

const Account = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [forfait, setForfait] = useState({});
  const [logements, setLogement] = useState([]);
  const [myBillings, setMyBillings] = useState([]);

  const getUsrBillings = async () => {
    setIsLoading(true);

    const { data: user } = await GET_USER_PROFILE();
    const { data: properties } = await GET_MY_PROPERTIES(user?.id);
    const { data: billings } = await GET_MY_BILLINGS(user?.id);

    if (user?.forfait) setForfait(user.forfait);
    if (properties?.data?.length > 0) setLogement(properties.data);
    if (billings?.data?.length > 0) setMyBillings(billings.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getUsrBillings();
  }, []);

  return (
    <div className="__expended-container">
      <Navbar />
      <FakeHeader />

      <div className="liton__wishlist-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__product-tab-area">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="ltn__tab-menu-list mb-50">
                        <AccountNav tabIdx={3} />
                      </div>
                    </div>

                    <div className="col-lg-8">
                      <div className="tab-content">
                        <div className="tab-pane active show" id="ltn_tab_1_2">
                          <div className="ltn__myaccount-tab-content-inner">
                            <div>
                              {forfait?.Nom && (
                                <p>
                                  Votre forfait est <strong>{forfait.Nom}</strong>
                                </p>
                              )}
                            </div>

                            {!isLoading && !!myBillings.length && (
                              <>
                                <div className="ltn__my-properties-table table-responsive">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th scope="col">Mes Facturations</th>
                                        <th scope="col" />
                                        <th scope="col" />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {myBillings.map((data, idx) => (
                                        <CardRow data={data} key={idx} />
                                      ))}
                                    </tbody>
                                  </table>
                                </div>

                                {/* <Pages /> */}
                              </>
                            )}

                            <EmptyRow title="Vous n'avez pas de facturations" isLoading={isLoading} noEntities={!myBillings.length} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FakeHeader auto />
      <Footer />
    </div>
  );
};

export default Account;
