import React, { useEffect, useState } from "react";
import { withFormik } from "formik";
import _ from "underscore";

import MuiEntryText from "../entries/text";
import MuiEntryAutocompleteGrouped from "../entries/autocomplete-grouped";
import EntryRange from "../entries/range";

import { GET_INIT_DATA, ADD_SOUMISION } from "../../services";
import { baseIniData, getInitOptions } from "../../libs/entries";

import * as yup from "yup";

const validationSchema = yup
  .object({
    nom: yup.string().required(),
    prenom: yup.string().required(),
    courriel: yup.string().required(),
    téléphone: yup.string().required(),
    ville: yup.string().required(),
    nombre_de_logement: yup.string().required(),
    prix_des_logements: yup.string().required()
  })
  .default({
    nom: "",
    prenom: "",
    courriel: "",
    téléphone: "",
    ville: "",
    nombre_de_logement: "",
    prix_des_logements: "0"
  });

const iniValues = validationSchema.cast();

const AlertForm = (props) => {
  const [initData, setInitData] = useState(baseIniData);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const cantSave = !_.isEmpty(errors);

  const onSaveSoumission = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const { error } = await ADD_SOUMISION(props.values);
    setIsLoading(false);
    if (error) return;
    props.resetForm({ values: { ...iniValues } });
  };

  const onInitData = async () => {
    const { data } = await GET_INIT_DATA();
    setInitData(data);
  };

  useEffect(() => {
    props.resetForm({ values: { ...iniValues } });
    onInitData();
  }, []);

  const { villeOptions, animauxOptions } = getInitOptions(initData);

  return (
    <div id="ltn__utilize-soumission-drawer" className="ltn__utilize ltn__utilize-cart-menu">
      <div className="ltn__utilize-menu-inner" style={{ padding: "0" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__utilize-menu-head">
                <span className="ltn__utilize-menu-title">Soumision gratuite</span>
                <button className="ltn__utilize-close">×</button>
              </div>
            </div>
          </div>
        </div>

        <div className="mini-cart-product-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <MuiEntryText
                  classes="input-item-name ltn__custom-icon"
                  name="nom"
                  label="Nom"
                  value={props.values.nom}
                  onChange={props.handleChange}
                />
              </div>
              <div className="col-lg-6">
                <MuiEntryText
                  classes="input-item-name ltn__custom-icon"
                  name="prenom"
                  label="Prénom"
                  value={props.values.prenom}
                  onChange={props.handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <MuiEntryText type="email" name="courriel" label="Adresse courriel" value={props.values.courriel} onChange={props.handleChange} />
              </div>
              <div className="col-lg-6">
                <MuiEntryText name="téléphone" label="Téléphone" value={props.values.téléphone} onChange={props.handleChange} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <MuiEntryText
                  type="number"
                  name="nombre_de_logement"
                  label="Nombre de logement"
                  value={props.values.nombre_de_logement}
                  onChange={props.handleChange}
                />
              </div>
              <div className="col-lg-12">
                <MuiEntryAutocompleteGrouped
                  isOnlyOption
                  name="secteurs"
                  label="Région/Ville/Secteur"
                  value={props.values?.ville ? [{ label: props.values?.ville, value: props.values?.ville }] : []}
                  onChange={(ville) => {
                    props.setFieldValue("ville", ville);
                  }}
                  options={villeOptions}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <EntryRange
                  _id="soumission"
                  range={false}
                  label="Loyer maximum"
                  value={[0, parseInt(props.values.prix_des_logements, 10)]}
                  onChange={(range) => {
                    props.setFieldValue("prix_des_logements", `${range[1]}`);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mini-cart-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="btn-wrapper">
                  <button className="theme-btn-1 btn btn-effect-1 ltn__utilize-close" onClick={() => onSaveSoumission()} disabled={cantSave}>
                    Envoyer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFormik({
  validationSchema,
  validateOnMount: true,
  mapPropsToValues: () => ({ ...iniValues })
})(AlertForm);
