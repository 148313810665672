import React from 'react';

import cx from 'classnames';

const EntryText = ({ classes, name, value, placeholder = '', onChange }) => {
  return (
    <div className={cx('input-item', { [classes]: !!classes })}>
      <textarea
        name={name}
        placeholder={placeholder}
        // ...
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default EntryText;
