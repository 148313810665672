import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { SessionContext } from "../../context";

const OwnerAbout = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const ctx = useContext(SessionContext);
  const isAuthed = ctx.isAuthed;
  const isPro = !!ctx.isPro;

  return (
    <div className="ltn__about-us-area pt-80 pb-80 ">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 align-self-center">
            <div className="about-us-img-wrap about-img-left">
              <img src={publicUrl + "images/img2.jpg"} alt="About Us Image" />
            </div>
          </div>
          <div className="col-lg-6 align-self-center">
            <div className="about-us-info-wrap">
              <div className="section-title-area ltn__section-title-2--- mb-30">
                <h1 className="section-title-1">Tu es propriétaire ou gestionnaire d'immeuble?</h1>
                <div style={{ margin: "0 0 50px 0" }} />
                <p>
                  <strong>Augmente tes chances de louer rapidement tes appartements.</strong>
                </p>
                <p>
                  Plusieurs locataires recevront <strong>automatiquement</strong> tes apparts qui correspondent à leurs crtières.
                </p>
                <div style={{ margin: "0 0 50px 0" }} />
                <div className="go-top">
                  {isAuthed && isPro && (
                    <Link to="/account/mes-logements" className="theme-btn-1 btn btn-effect-1">
                      Mes annonces
                    </Link>
                  )}
                  <Link to="/owner" className="theme-btn-3 btn btn-effect-1 go-top">
                    Voir comment
                  </Link>
                  {!isAuthed && (
                    <Link to="/register" className="theme-btn-1 btn btn-effect-1">
                      Créer un compte
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnerAbout;
