import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/base/navbar";
import FakeHeader from "../../components/section/fake-header";
import Footer from "../../components/base/footer";
import ConfirmBox from "../../components/base/confirm-box";
import Icon from "../../components/Icon";
import AccountNav from "../../components/base//account-nav";
import CardRow from "../../components/property/card-row";
import EmptyRow from "../../components/property/empty-row";
import useModal from "../../hooks/useModal";

import { GET_USER_PROFILE, GET_MY_PROPERTIES, DELETE_PROPERTY } from "../../services";

const Account = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [myProperties, setMyProperties] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [addWillExceed, setAddWillExceed] = useState({ status: false, prix: null });

  const getUsrProperties = async () => {
    setIsLoading(true);

    const { data: user } = await GET_USER_PROFILE();
    const { data } = await GET_MY_PROPERTIES(user?.id);

    const createdLogementsCount = data?.data?.length || 0;
    const { prix, prix_par, nombre_max_annonces } = user?.forfait || {};
    let addWillExceed = prix_par === "appartement" && createdLogementsCount >= nombre_max_annonces;

    setMyProperties(data?.data || []);
    if (addWillExceed) setAddWillExceed({ status: true, prix });
    setIsLoading(false);
  };

  const onDeleteProperties = async (isDelete) => {
    if (!isDelete) return setDeleteId(null);
    if (isLoading) return;

    await DELETE_PROPERTY(deleteId);
    getUsrProperties();
  };

  useModal();

  useEffect(() => {
    getUsrProperties();
  }, []);

  return (
    <div className="__expended-container">
      <Navbar />
      <FakeHeader />

      <div className="liton__wishlist-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__product-tab-area">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="ltn__tab-menu-list mb-50">
                        <AccountNav tabIdx={2} />
                      </div>
                    </div>

                    <div className="col-lg-8">
                      <div className="tab-content">
                        <div className="tab-pane active show" id="ltn_tab_1_2">
                          <div className="ltn__myaccount-tab-content-inner">
                            {!isLoading && !!myProperties.length && (
                              <>
                                <div className="__cards">
                                  <div className="__cards__header">
                                    <div className="__title">Mes annonces</div>
                                    {/* <div className="__actions">
                                      <div>Actions</div>
                                    </div> */}
                                  </div>
                                  <div className="__cards__body">
                                    {myProperties.map((data, idx) => (
                                      <CardRow data={data} key={idx} onDeleteProperties={(id) => setDeleteId(id)} />
                                    ))}
                                  </div>
                                </div>

                                {/* <Pages /> */}
                              </>
                            )}

                            {!!myProperties.length && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  margin: "50px 0 0 0"
                                }}>
                                {addWillExceed.status && (
                                  <p style={{ color: "#5c727d", fontSize: "13px", fontWeight: "bold", margin: "0 0 40px 0" }}>
                                    *Tout logement supplémentaire vous coûtera {addWillExceed.prix}$/mois
                                  </p>
                                )}

                                <Link className="theme-btn-3 btn btn-effect-1 __btn-icon" to="/properties/add">
                                  <Icon icon="fa-solid fa-plus" />
                                  Ajouter
                                </Link>
                              </div>
                            )}

                            <EmptyRow title="Vous n'avez pas de logements" isLoading={isLoading} noEntities={!myProperties.length}>
                              <Link className="theme-btn-3 btn btn-effect-1 __btn-icon" to="/properties/add">
                                <Icon icon="fa-solid fa-plus" />
                                Ajouter
                              </Link>
                            </EmptyRow>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmBox title="ce logement?" handler={onDeleteProperties} />

      <FakeHeader auto />
      <Footer />
    </div>
  );
};

export default Account;
