import React, { useContext } from "react";

import { Link } from "react-router-dom";
import cx from "classnames";

import { SessionContext } from "../../context";

const proLinks = [
  { label: "Détails du compte", link: "/account/details", icon: "fas fa-user" },
  { label: "Changer mon mot de passe", link: "/account/password", icon: "fas fa-user" },
  { label: "Mes annonces", link: "/account/mes-logements", icon: "fa-solid fa-list" },
  { label: "Mes Facturations", link: "/account/mes-facturations", icon: "fa-solid fa-list" },
  { label: "Demande de visite", link: "/account/mes-visite", icon: "fa-solid fa-list" }
];

const locLinks = [
  { label: "Détails du compte", link: "/account/details", icon: "fas fa-user" },
  { label: "Changer mon mot de passe", link: "/account/password", icon: "fas fa-user" },
  { label: "Cession de bail", link: "/account/mes-bails", icon: "fa-solid fa-list" },
  { label: "Mes Favories", link: "/account/mes-favories", icon: "fa-solid fa-heart" },
  { label: "Mes Alertes", link: "/account/mes-alertes", icon: "fa-solid fa-list" },
  { label: "Mes Demandes", link: "/account/mes-demandes", icon: "fa-solid fa-list" }
];

const AccountNav = ({ tabIdx }) => {
  const ctx = useContext(SessionContext);
  const isPro = !!ctx.isPro;
  const links = isPro ? proLinks : locLinks;

  return (
    <div className="nav">
      {links.map((l, idx) => (
        <Link key={idx} className={cx({ active: tabIdx === idx, show: tabIdx === idx })} to={l.link}>
          {l.label} <i className={l.icon} />
        </Link>
      ))}
    </div>
  );
};

export default AccountNav;
