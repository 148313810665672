const qs = require("qs");
var _ = require("lodash");

//
// My Properties Queries
// live: returns only published entries (default)
// preview: returns both draft entries & published entries
export const myPropertiesQuery = (userId) => {
  const query = qs.stringify({ filters: { proprietaire: { id: { $eq: userId } } } }, { encodeValuesOnly: true });
  return `/logements/?populate=deep,5&publicationState=preview&${query}`;
};

//
// Properties Searc
export const filtersQuery = ({
  secteurs = [],
  pieces = [],
  animaux = [],
  min_m = 0,
  max_m = 0,
  // ...
  dispo_array = []
}) => {
  const query = qs.stringify(
    {
      filters: {
        $and: [
          { situation_geographique: { secteur: { $in: secteurs } } },
          { caracteristiques: { nombre_de_pieces: { $in: pieces }, animaux_acceptes: { $eq: animaux } } },
          { description: { montant_du_loyer: { $between: [min_m, max_m] } } },
          { $or: dispo_array.map((o) => ({ description: { disponibilite: { $gte: o.split("_")[0], $lte: o.split("_")[1] } } })) }
        ]
      }
    },
    { encodeValuesOnly: true }
  );
  // ...
  return `/logements/?populate=deep,5&publicationState=live&${query}`;
};

//
// My Bails
export const myBailsQuery = (userId) => {
  const query = qs.stringify({ filters: { proprietaire: { id: { $eq: userId } } } }, { encodeValuesOnly: true });
  return `/bails/?populate=deep,5&publicationState=preview&${query}`;
};
