import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const Brand = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";

  return (
    <div className="ltn__brand-logo-area ltn__brand-logo-1 section-bg-1 pb-80">
      <div className="pt-80 pb-60 plr--9">
        <h3 className="section-title-style-3" style={{textAlign: 'center'}}>Qui utilise LocSimple ?</h3>
      </div>
      <div className="plr--9">
        <div className="container-fluid">
          <div className="row ltn__brand-logo-active">
            <div className="col-lg-12">
              <div className="ltn__brand-logo-item">
                <img src={publicUrl + "assets/partenaires/Logo_DavidBlouin.png"} alt="Brand Logo" />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__brand-logo-item">
                <img src={publicUrl + "assets/partenaires/Logo_CedetonBail.png"} alt="Brand Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brand;
