import AddIcon from "@mui/icons-material/AddCircleOutline";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";
import cx from "classnames";
import { withFormik, ErrorMessage } from "formik";

import MuiEntryText from "../entries/text";
import MuiEntrySelect from "../entries/select";
import MuiEntryCheckbox from "../entries/checkbox";
import MuiEntryTextRich from "../entries/text-rich";

import Gallery from "./manage-form-gallery";
import Address from "./manage-form-address";
import Map from "./manage-form-map";

import storage from "../../libs/storage";
import { onScrollTop } from "../../libs/dom";

import { GET_BAIL, GET_PROPERTY, ADD_BAIL, ADD_PROPERTY, EDIT_BAIL, EDIT_PROPERTY } from "../../services";
import { amenitiesList, lotSizesOptions, animauxOptionsForProprio } from "../../libs/entries";
import { validationSchema } from "./manage-form-data";

const iniValues = validationSchema.cast();

const AddPropertyForm = ({ isBail, ...props }) => {
  const navigate = useNavigate();
  const params = useParams();
  let propId = params.id;
  const proprietaire = storage.getItem("__SESSION")?.user?.id || null;
  // const proprietaireEmail = storage.getItem("__SESSION")?.user?.email || null;

  const [isLoading, setIsLoading] = useState(false);
  const [publishedAt, setPublishedAt] = useState(null);
  const [saveError, setSaveError] = useState("");
  const cantSave = !_.isEmpty(props?.errors);

  const onSaveHandler = async (toPublish) => {
    let method = !!propId ? EDIT_PROPERTY : ADD_PROPERTY;
    if (isBail) method = !!propId ? EDIT_BAIL : ADD_BAIL;
    if (isLoading) return;
    setIsLoading(true);

    let req = { ...props.values };
    if (proprietaire) req.proprietaire = proprietaire;
    if (!toPublish) req.publishedAt = null;
    else if (toPublish && publishedAt) req.publishedAt = publishedAt;
    else req.publishedAt = new Date();

    const { error, data } = await method(propId, req);
    setIsLoading(false);
    if (error) {
      return setSaveError("Une erreur s'est produite réessayez");
    }
    if (data.checkout_url) {
      window.location.replace(data.checkout_url);
    } else {
      const id = data?.data?.id;
      navigate(isBail ? "/account/mes-bails" : `/properties/${id}`);
      onScrollTop();
    }
  };

  const onManageCalender = (k) => {
    const isFound = props.values.disponibilite_visite?.slots?.indexOf(k) > -1;
    let tmp = [];

    if (!isFound) tmp = [...(props.values?.disponibilite_visite?.slots || []), k];
    else tmp = [...props.values.disponibilite_visite?.slots].filter((x) => x !== k);

    props.setFieldValue("disponibilite_visite.slots", tmp);
  };

  const onGetEntity = async (propId) => {
    const { error, data } = isBail ? await GET_BAIL(propId) : await GET_PROPERTY(propId);
    if (error) return setSaveError("Une erreur s'est produite réessayez");

    const _data = data.data.attributes;
    const gallerie = _data?.gallerie?.data || [];
    const proprietaire = _data?.proprietaire?.data?.id || null;

    setPublishedAt(_data.publishedAt);
    props.resetForm({ values: { ...iniValues, ..._data, gallerie, proprietaire, publishedAt: null } });
    props.setValues({ ...iniValues, ..._data, gallerie, proprietaire, publishedAt: null });
  };

  useEffect(() => {
    if (!propId) return;
    onGetEntity(propId);
  }, [propId]);

  return (
    <div className="ltn__appointment-area pb-120">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__appointment-inner">
              <div action="#">
                <h2>1. Description</h2>
                <p>
                  <small>Les champs marqués avec un * sont obligatoires.</small>
                </p>
                <h6>{isBail ? "Titre du bail" : "Titre de la propriété"} *</h6>

                <div className="row">
                  <div className="col-md-12">
                    <MuiEntryText
                      name="titre"
                      label="Titre*"
                      value={props.values?.titre}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.touched?.titre && props.errors?.titre}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <h6>Disponibilité *</h6>
                    <MuiEntryText
                      type="date"
                      isShrinked
                      name="description.disponibilite"
                      label="Disponible à partir du (*date)"
                      value={props.values.description?.disponibilite}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.touched?.description?.disponibilite && props.errors?.description?.disponibilite}
                    />
                  </div>
                  <div className="col-md-6">
                    <h6>Montant du loyer *</h6>
                    <MuiEntryText
                      type="number"
                      name="description.montant_du_loyer"
                      label="Montant du loyer *"
                      value={props.values.description?.montant_du_loyer}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.touched?.description?.montant_du_loyer && props.errors?.description?.montant_du_loyer}
                    />
                  </div>
                </div>

                <Gallery propId={propId} {...props} />

                <Address {...props} />
                <Map data={props.values} />

                <h2>4. Détails</h2>
                <h6>Détails du logement</h6>
                <div className="row">
                  <div className="col-md-6">
                    <MuiEntrySelect
                      label="Type *"
                      name="caracteristiques.nombre_de_pieces"
                      value={props.values.caracteristiques?.nombre_de_pieces}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      options={lotSizesOptions}
                      error={props.touched?.caracteristiques?.nombre_de_pieces && props.errors?.caracteristiques?.nombre_de_pieces}
                    />
                  </div>
                  <div className="col-md-6">
                    <MuiEntryText
                      type="number"
                      name="caracteristiques.surface"
                      label="Surface (en pied2)"
                      value={props.values.caracteristiques?.surface}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.touched?.caracteristiques?.surface && props.errors?.caracteristiques?.surface}
                    />
                  </div>

                  <div className="col-md-6">
                    <MuiEntryText
                      type="number"
                      name="caracteristiques.chambres"
                      label="Chambres (*que des chiffres)"
                      value={props.values.caracteristiques?.chambres}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.touched?.caracteristiques?.chambres && props.errors?.caracteristiques?.chambres}
                    />
                  </div>
                  <div className="col-md-6">
                    <MuiEntryText
                      type="number"
                      name="caracteristiques.salles_de_bain"
                      label="Salles de bain (*que des chiffres)"
                      value={props.values.caracteristiques?.salles_de_bain}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.touched?.caracteristiques?.salles_de_bain && props.errors?.caracteristiques?.salles_de_bain}
                    />
                  </div>
                  <div className="col-md-6">
                    <MuiEntrySelect
                      label="Animal *"
                      name="caracteristiques.animaux_acceptes"
                      value={props.values.caracteristiques?.animaux_acceptes}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      options={animauxOptionsForProprio}
                      error={props.touched?.caracteristiques?.animaux_acceptes && props.errors?.caracteristiques?.animaux_acceptes}
                    />
                  </div>
                  <div className="col-md-12">
                    <MuiEntryTextRich
                      name="description.description"
                      placeholder="Description*"
                      value={props.values.description?.description}
                      onChange={props.setFieldValue}
                      onBlur={props.handleBlur}
                      error={props.touched?.description?.description && props.errors?.description?.description}
                    />
                  </div>
                </div>

                <div className="row mb-30">
                  {amenitiesList.map((a, idx) => (
                    <div key={idx} className="col-lg-3 col-md-6">
                      <div style={{ fontWeight: "bold", margin: "0 0 20px 0" }}>{a.label}</div>
                      {a.options.map((o, oIdx) => (
                        <div key={oIdx}>
                          <label className="checkbox-item">
                            {o.label}
                            <input
                              type="checkbox"
                              name={`${a.name}.${o.name}`}
                              checked={props.values?.[a.name]?.[o.name]}
                              onChange={props.handleChange}
                            />
                            <span className="checkmark" />
                          </label>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>

                {!isBail && (
                  <>
                    <h2 style={{ margin: "60px 0 40px 0" }}>5. Avisez vo(s) locataire(s) avant les visites</h2>
                    {/* <h6>Détails du logement</h6> */}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <p style={{ color: "#000" }}>L'appartement est</p>
                          </div>

                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-6">
                                <MuiEntryCheckbox
                                  name="type_de_la_location"
                                  label="Vacant"
                                  value={props.values.type_de_la_location === "vacant"}
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    if (checked) props.setFieldValue("type_de_la_location", "vacant");
                                    else props.setFieldValue("type_de_la_location", "");
                                  }}
                                />
                              </div>
                              <div className="col-md-6">
                                <MuiEntryCheckbox
                                  name="type_de_la_location"
                                  label="Habité"
                                  value={props.values.type_de_la_location === "habite"}
                                  onChange={(e) => {
                                    const checked = e.target.checked;
                                    if (checked) props.setFieldValue("type_de_la_location", "habite");
                                    else props.setFieldValue("type_de_la_location", "");
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {props.values.type_de_la_location === "habite" && (
                        <div style={{ margin: "30px 0 0 0" }} className="col-md-7">
                          <MuiEntryCheckbox
                            name="locataire_actuel_recoie_invitation"
                            label="Je souhaite que le(s) locataire(s) actuel recoie une invitation afin d'appouver ou refuser la methode de communication 24h avant les visites eventuels"
                            value={!!props.values.locataire_actuel_recoie_invitation}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              props.setFieldValue("locataire_actuel_recoie_invitation", !!checked);
                            }}
                          />
                          <MuiEntryCheckbox
                            name="aviser_locataire_actuel"
                            label="Je comprend que je dois continuer d'aviser le(s) locataire(s) actuel(s) de methode traditionelle jusq'a leur approbation de la nouvelle methode de communication"
                            value={!!props.values.aviser_locataire_actuel}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              props.setFieldValue("aviser_locataire_actuel", !!checked);
                            }}
                          />
                        </div>
                      )}

                      {props.values.type_de_la_location === "habite" && !!props.values.locataire_actuel_recoie_invitation && (
                        <div className="manage-form-habite">
                          <div className="manage-form-habite__list">
                            {props.values.locataires_actuel?.personnes?.map((p, pIdx) => (
                              <div key={pIdx} className="manage-form-habite__item">
                                <div className="manage-form-habite__item__entries">
                                  <MuiEntryText
                                    label="Nom"
                                    value={props.values.locataires_actuel.personnes[pIdx].nom}
                                    onChange={(e) => {
                                      const v = e.target.value;
                                      props.setFieldValue(`locataires_actuel.personnes[${pIdx}].nom`, v);
                                    }}
                                  />
                                  <MuiEntryText
                                    label="Prenom"
                                    value={props.values.locataires_actuel.personnes[pIdx].prenom}
                                    onChange={(e) => {
                                      const v = e.target.value;
                                      props.setFieldValue(`locataires_actuel.personnes[${pIdx}].prenom`, v);
                                    }}
                                  />
                                  <MuiEntryText
                                    label="Courriel"
                                    value={props.values.locataires_actuel.personnes[pIdx].courriel}
                                    onChange={(e) => {
                                      const v = e.target.value;
                                      props.setFieldValue(`locataires_actuel.personnes[${pIdx}].courriel`, v);
                                    }}
                                  />
                                  <MuiEntryText
                                    label="Telephone"
                                    value={props.values.locataires_actuel.personnes[pIdx].telephone}
                                    onChange={(e) => {
                                      const v = e.target.value;
                                      props.setFieldValue(`locataires_actuel.personnes[${pIdx}].telephone`, v);
                                    }}
                                  />
                                </div>
                                <div className="manage-form-habite__item__remove">
                                  {!!pIdx && (
                                    <span
                                      onClick={() => {
                                        const tmp = (props.values.locataires_actuel?.personnes || []).filter((item, iPIdx) => {
                                          return iPIdx !== pIdx;
                                        });
                                        props.setFieldValue("locataires_actuel.personnes", [...tmp]);
                                      }}>
                                      Remove
                                    </span>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="manage-form-habite__add">
                            <div
                              onClick={() => {
                                const tmp = props.values.locataires_actuel?.personnes || [];
                                props.setFieldValue("locataires_actuel.personnes", [...tmp, { nom: "", prenom: "", courriel: "", telephone: "" }]);
                              }}>
                              <AddIcon />
                              <span>Add new blues</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <h2 style={{ margin: "60px 0 40px 0" }}>6. Questionnaire et planification de visite</h2>

                    <div className="row">
                      <div className="col-md-12">
                        <p style={{ color: "#000" }}>Selectionne les questions aux quels le(s) locataire(s) devrons repondre.</p>
                      </div>
                      <div className="col-md-12">
                        <MuiEntryCheckbox
                          name="fumeur_vapoteur"
                          label="Occupant(s) fumeur ou vapoteur"
                          value={!!props.values.fumeur_vapoteur}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            props.setFieldValue("fumeur_vapoteur", !!checked);
                          }}
                        />
                        <MuiEntryCheckbox
                          name="besoin_stationnement"
                          label="Avez vous besoin d'un stationnement?"
                          value={!!props.values.besoin_stationnement}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            props.setFieldValue("besoin_stationnement", !!checked);
                          }}
                        />
                        <MuiEntryCheckbox
                          name="enquete_prelocation"
                          label="Etes vous a l'aise avec les enquetes de prélocation (Vérification de crédit, criminel, regie du logement, etc)"
                          value={!!props.values.enquete_prelocation}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            props.setFieldValue("enquete_prelocation", !!checked);
                          }}
                        />
                        <MuiEntryCheckbox
                          name="vous_avez_des_animaux"
                          label="Avez vous des animaux?"
                          value={!!props.values.vous_avez_des_animaux}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            props.setFieldValue("vous_avez_des_animaux", !!checked);
                          }}
                        />
                        <MuiEntryCheckbox
                          name="date_amenagement_souaite"
                          label="Vous souhaitez aménagé quelle date?"
                          value={!!props.values.date_amenagement_souaite}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            props.setFieldValue("date_amenagement_souaite", !!checked);
                          }}
                        />
                      </div>

                      <div style={{ margin: "40px 0 0 0" }} className="col-md-10">
                        <div className="manage-form-calender">
                          <div className="__label">Quel sont vos disponibilités pour effectuer vos visites</div>
                          <div className="__inner">
                            <div className="__inner__row">
                              <div></div>
                              <div>Lundi</div>
                              <div>Mardi</div>
                              <div>Mercredi</div>
                              <div>Jeudi</div>
                              <div>Vendredi</div>
                              <div>Samedi</div>
                              <div>Dimanche</div>
                            </div>
                            <div className="__inner__row">
                              <div>9h a 12h</div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("09__12__Lundi") > -1 })}
                                onClick={() => onManageCalender("09__12__Lundi")}>
                                Matin
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("09__12__Mardi") > -1 })}
                                onClick={() => onManageCalender("09__12__Mardi")}>
                                Matin
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("09__12__Mercredi") > -1 })}
                                onClick={() => onManageCalender("09__12__Mercredi")}>
                                Matin
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("09__12__Jeudi") > -1 })}
                                onClick={() => onManageCalender("09__12__Jeudi")}>
                                Matin
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("09__12__Vendredi") > -1 })}
                                onClick={() => onManageCalender("09__12__Vendredi")}>
                                Matin
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("09__12__Samedi") > -1 })}
                                onClick={() => onManageCalender("09__12__Samedi")}>
                                Matin
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("09__12__Dimanche") > -1 })}
                                onClick={() => onManageCalender("09__12__Dimanche")}>
                                Matin
                              </div>
                            </div>
                            <div className="__inner__row">
                              <div>12h a 17h</div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("12__17__Lundi") > -1 })}
                                onClick={() => onManageCalender("12__17__Lundi")}>
                                PM
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("12__17__Mardi") > -1 })}
                                onClick={() => onManageCalender("12__17__Mardi")}>
                                PM
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("12__17__Mercredi") > -1 })}
                                onClick={() => onManageCalender("12__17__Mercredi")}>
                                PM
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("12__17__Jeudi") > -1 })}
                                onClick={() => onManageCalender("12__17__Jeudi")}>
                                PM
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("12__17__Vendredi") > -1 })}
                                onClick={() => onManageCalender("12__17__Vendredi")}>
                                PM
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("12__17__Samedi") > -1 })}
                                onClick={() => onManageCalender("12__17__Samedi")}>
                                PM
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("12__17__Dimanche") > -1 })}
                                onClick={() => onManageCalender("12__17__Dimanche")}>
                                PM
                              </div>
                            </div>
                            <div className="__inner__row">
                              <div>17h a 21h</div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("17__21__Lundi") > -1 })}
                                onClick={() => onManageCalender("17__21__Lundi")}>
                                Soir
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("17__21__Mardi") > -1 })}
                                onClick={() => onManageCalender("17__21__Mardi")}>
                                Soir
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("17__21__Mercredi") > -1 })}
                                onClick={() => onManageCalender("17__21__Mercredi")}>
                                Soir
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("17__21__Jeudi") > -1 })}
                                onClick={() => onManageCalender("17__21__Jeudi")}>
                                Soir
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("17__21__Vendredi") > -1 })}
                                onClick={() => onManageCalender("17__21__Vendredi")}>
                                Soir
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("17__21__Samedi") > -1 })}
                                onClick={() => onManageCalender("17__21__Samedi")}>
                                Soir
                              </div>
                              <div
                                className={cx({ __selected: props.values.disponibilite_visite?.slots?.indexOf("17__21__Dimanche") > -1 })}
                                onClick={() => onManageCalender("17__21__Dimanche")}>
                                Soir
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ margin: "40px 0 0 0" }} className="col-md-12">
                        <p style={{ color: "#000" }}>Rappel de la visite au locataire potentiel.</p>
                      </div>
                      <div className="col-md-12">
                        <MuiEntryCheckbox
                          name="rappel_visite_24"
                          label="Rappel 24h avant la visite"
                          value={!!props.values.rappel_visite_24}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            props.setFieldValue("rappel_visite_24", !!checked);
                          }}
                        />
                        <MuiEntryCheckbox
                          name="rappel_visite_12"
                          label="Rappel 12h avant la visite"
                          value={!!props.values.rappel_visite_12}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            props.setFieldValue("rappel_visite_12", !!checked);
                          }}
                        />
                        <MuiEntryCheckbox
                          name="rappel_visite_5"
                          label="Rappel 5h avant la visite"
                          value={!!props.values.rappel_visite_5}
                          onChange={(e) => {
                            const checked = e.target.checked;
                            props.setFieldValue("rappel_visite_5", !!checked);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={cx("alert alert-warning mt-50", { "d-none": !cantSave })} role="alert">
          <p className="alert__title">Veuillez remplir les champs obligatoires</p>
        </div>

        <div className={cx("alert alert-danger mt-50", { "d-none": !saveError })} role="alert">
          {saveError}
        </div>

        <div className="mt-50">
          {!publishedAt && (
            <div className="btn-wrapper text-center go-top">
              <button
                className="theme-btn-3 btn btn-effect-1"
                type="submit"
                onClick={() => onSaveHandler(false)}
                style={{ width: "223px", height: "60px" }}
                disabled={cantSave}>
                Brouillon
              </button>
              <button
                className="theme-btn-1 btn btn-effect-1"
                type="submit"
                onClick={() => onSaveHandler(true)}
                style={{ width: "223px", height: "60px" }}
                disabled={cantSave}>
                Publier
              </button>
            </div>
          )}

          {!!publishedAt && (
            <div className="btn-wrapper text-center go-top">
              <button
                className="theme-btn-3 btn btn-effect-1"
                type="submit"
                onClick={() => onSaveHandler(true)}
                style={{ width: "223px", height: "60px" }}
                disabled={cantSave}>
                Sauvegarder
              </button>
              {/* <button
                className="theme-btn-1 btn btn-effect-1"
                type="submit"
                onClick={() => onSaveHandler(false)}
                style={{ width: "223px", height: "60px" }}
                disabled={cantSave}>
                dépublier
              </button> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withFormik({
  validationSchema,
  validateOnMount: true,
  mapPropsToValues: () => ({ ...iniValues })
})(AddPropertyForm);
