import * as yup from "yup";

export const validationSchema = yup
  .object({
    etat_visite: yup.string(),
    nbr_adultes: yup
      .number()
      .nullable()
      .min(1, "Nombre d'adutes doit être supérieur ou égal à 1")
      .max(99999999, "Nombre d'adutes doit être inférieur ou égal à 99999999")
      .required("Nombre d'adutes est obligatoire"),

    nbr_enfants: yup
      .number()
      .nullable()
      .min(0, "Nombre d'enfats doit être supérieur ou égal à 0")
      .max(99999999, "Nombre d'enfats doit être inférieur ou égal à 99999999")
      .required("Nombre d'adutes est obligatoire"),

    ages_enfants: yup.object().shape({
      ages: yup.array().of(
        yup.object().shape({
          age: yup
            .number()
            .nullable()
            .transform((v) => (isNaN(v) ? 0 : v))
            .min(1, "Age de l'enfant doit être supérieur ou égal à 1")
            .max(99999999, "Age de l'enfant doit être inférieur ou égal à 99999999")
            .required("Age de l'enfant est obligatoire")
        })
      )
    }),

    condidats_locataires: yup.object().shape({
      personnes: yup
        .array()
        .of(
          yup.object().shape({
            prenom: yup.string().required("Ce champs est obligatoire"),
            nom: yup.string().required("Ce champs est obligatoire"),
            telephone: yup.string().required("Ce champs est obligatoire"),
            courriel: yup.string().email("Ce champs est obligatoire").required("Ce champs est obligatoire")
          })
        )
        .min(1)
    }),

    lie_endosseur: yup.boolean().required(),
    endosseur: yup
      .object({
        personnes: yup.array()
      })
      .when("lie_endosseur", {
        is: true,
        then: yup.object().shape({
          personnes: yup
            .array()
            .of(
              yup.object().shape({
                prenom: yup.string().required("Ce champs est obligatoire"),
                nom: yup.string().required("Ce champs est obligatoire"),
                telephone: yup.string().required("Ce champs est obligatoire"),
                courriel: yup.string().email("Ce champs est obligatoire").required("Ce champs est obligatoire")
              })
            )
            .min(1)
        })
      }),

    date_amenagement_souaite: yup.date().nullable().required(),
    date_visite: yup.date().nullable().notRequired(),
    date_visite_options: yup.object({
      list: yup
        .array(
          yup.object({
            date: yup.date().nullable(),
            times: yup.array()
          })
        )
        .test("FirtOnly", { option: "First option is required" }, function () {
          const { parent } = this;
          const first = parent?.list?.[0];
          return !!first.date && !!first.times.length;
        })
    }),

    duree_location: yup.string().required(),
    type_visite: yup.string().required(),

    fumeur_vapoteur: yup.boolean().required(),
    enquete_prelocation: yup.boolean().required(),
    besoin_stationnement: yup.boolean().required(),
    vous_avez_des_animaux: yup.boolean().required(),
    precision_animaux: yup.object().shape({
      chien: yup.object().shape({
        age: yup
          .number()
          .nullable()
          .transform((v) => (isNaN(v) ? 0 : v))
          .min(1, "Age doit être supérieur ou égal à 1")
          .max(99999999, "Age doit être inférieur ou égal à 99999999"),
        poids: yup
          .number()
          .nullable()
          .transform((v) => (isNaN(v) ? 0 : v))
          .min(1, "Poids doit être supérieur ou égal à 1")
          .max(99999999, "Poid doit être inférieur ou égal à 99999999")
      }),
      chat: yup.object().shape({
        age: yup
          .number()
          .nullable()
          .transform((v) => (isNaN(v) ? 0 : v))
          .min(1, "Age doit être supérieur ou égal à 1")
          .max(99999999, "Age doit être inférieur ou égal à 99999999"),
        poids: yup
          .number()
          .nullable()
          .transform((v) => (isNaN(v) ? 0 : v))
          .min(1, "Poids doit être supérieur ou égal à 1")
          .max(99999999, "Poid doit être inférieur ou égal à 99999999")
      }),
      autre: yup.object().shape({
        age: yup
          .number()
          .nullable()
          .transform((v) => (isNaN(v) ? 0 : v))
          .min(1, "Age doit être supérieur ou égal à 1")
          .max(99999999, "Age doit être inférieur ou égal à 99999999"),
        poids: yup
          .number()
          .nullable()
          .transform((v) => (isNaN(v) ? 0 : v))
          .min(1, "Poids doit être supérieur ou égal à 1")
          .max(99999999, "Poid doit être inférieur ou égal à 99999999")
      })
    }),

    note: yup.string().notRequired()
  })
  .default({
    etat_visite: "en_attente",
    nbr_adultes: 1,
    nbr_enfants: 0,

    ages_enfants: { ages: [] },

    condidats_locataires: { personnes: [{ prenom: "", nom: "", telephone: "", courriel: "" }] },

    lie_endosseur: false,
    endosseur: { personnes: [{ prenom: "", nom: "", telephone: "", courriel: "" }] },

    date_amenagement_souaite: null,
    date_visite: null,

    date_visite_options: {
      list: [
        { date: null, times: [] },
        { date: null, times: [] },
        { date: null, times: [] }
      ]
    },

    duree_location: "m3_6mois",
    type_visite: "presentiel",
    fumeur_vapoteur: true,
    besoin_stationnement: true,
    enquete_prelocation: true,
    vous_avez_des_animaux: true,
    precision_animaux: { chat: { age: null, poids: null }, chien: { age: null, poids: null }, autre: { age: null, poids: null } },
    note: ""
  });
