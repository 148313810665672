import React from "react";
import GoogleMapReact from "google-map-react";

import Icon from "../Icon";

const FormMap = ({ data }) => {
  const sg = data?.situation_geographique || {};
  const lat = sg?.gps?.lat;
  const long = sg?.gps?.long;
  // ...
  if (!lat || !long) return null;
  // ...
  return (
    <div className="GMapPreview mb-50" key={`${lat}__${long}`}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyATnAMKedqC5rxgTVLCtAoXbPbqrchMPeU",
          libraries: ["places"]
        }}
        defaultCenter={{ lat: parseFloat(lat), lng: parseFloat(long) }}
        defaultZoom={15}
        options={{ draggable: false, fullscreenControl: false }}
      />
      <div className="__marker">
        <Icon icon="fa-2x fa-solid fa-location-dot" style={{ color: "#5c727d", color: "red" }} />
      </div>
    </div>
  );
};

export default FormMap;
