import React from "react";


const LoginSocial = () => {
  return (
    <div className="row justify-content-md-center social-btn" style={{ margin: "0 0 40px 0", textAlign: "center" }}>
      <div style={{ margin: "0 0 20px 0" }} className="col-md-auto">
        <a className="btn go-top fb btn-social" href={`${process.env.REACT_APP_API_BASE_URL}/connect/facebook`} style={{ width: "200px" }}>
          <span>
            <svg viewBox="0 0 19 18" fill="none" xmlns="https://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.8333 9.05437C18.8333 4.05425 14.8033 0 9.83325 0C4.86316 0 0.833252 4.05425 0.833252 9.05437C0.833252 13.5735 4.12388 17.3193 8.427 17.9992V11.6725H6.14124V9.05437H8.427V7.05958C8.427 4.79073 9.77097 3.53646 11.8265 3.53646C12.8113 3.53646 13.8415 3.7135 13.8415 3.7135V5.94193H12.706C11.5887 5.94193 11.2391 6.6396 11.2391 7.35667V9.05437H13.735L13.3364 11.6725H11.2395L11.2395 18C15.5426 17.3205 18.8333 13.5747 18.8333 9.05437V9.05437Z" fill="#1877f2"></path></svg>
          </span>
          Facebook
        </a>
      </div>
      <div style={{ margin: "0 0 20px 0" }} className="col-md-auto">
        <a className="btn go-top google btn-social" href={`${process.env.REACT_APP_API_BASE_URL}/connect/google`} style={{ width: "200px" }}>
          <span>
            <svg viewBox="0 0 19 18" fill="none" xmlns="https://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.4999 9.19514C18.4999 8.55479 18.4387 7.94445 18.3367 7.35413L9.68359 7.35413V10.8361H14.6224C14.4081 11.9567 13.7652 12.9172 12.7856 13.5576V15.8188H15.755C17.4897 14.2479 18.4897 11.9467 18.4897 9.19514H18.4999Z" fill="#4285f4"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M9.68357 18C12.1632 18 14.2448 17.1895 15.7652 15.8188L12.7958 13.5575C11.9693 14.0978 10.9183 14.418 9.68357 14.418C7.29582 14.418 5.26521 12.8371 4.54072 10.7059H1.47949L1.47949 13.0372C2.9897 15.9789 6.09174 18 9.68357 18Z" fill="#34a853"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M4.54082 10.7059C4.35714 10.1656 4.2551 9.58532 4.2551 8.995C4.2551 8.40467 4.35714 7.82435 4.54082 7.28405V4.95276H1.47959C0.857143 6.17343 0.5 7.54419 0.5 8.995C0.5 10.4458 0.857143 11.8266 1.47959 13.0372L4.55102 10.7059H4.54082Z" fill="#fbbc05"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M9.68357 3.57201C11.0305 3.57201 12.2448 4.02226 13.1938 4.92276L15.8264 2.34133C14.2346 0.89053 12.153 3.69829e-05 9.68357 3.69829e-05C6.09174 -0.00996856 2.9897 2.01115 1.47949 4.95278L4.55092 7.28407C5.27541 5.15289 7.29582 3.57201 9.69378 3.57201H9.68357Z" fill="#ea4335"></path></svg>
          </span>
          Google
        </a>
      </div>
    </div>
  );
};

export default LoginSocial;
