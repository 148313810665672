import React, { useEffect, useState, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";

import { GET_PROPERTY, GET_VISITE } from "../../services";

import Navbar from "../../components/base/navbar";
import Slider from "../../components/property/slider";
import Footer from "../../components/base/footer";
import DemandeVisite from "../../components/property/demande-visite-form";

const PropertyDetails = () => {
  const { id, vid } = useParams();
  const { pathname } = useLocation();

  const [data, setData] = useState({ logement: {}, visite: {} });
  const [isLoading, setIsLoading] = useState(false);

  const isProView = pathname.includes("/review-visite");
  const isReadOnly = !!vid;

  const getProperty = async () => {
    if (isLoading) return;
    setIsLoading(true);

    //? Logement/visite
    const l = await GET_PROPERTY(id);
    const v = !!vid ? await GET_VISITE(vid) : { error: false, data: {} };
    setIsLoading(false);

    if (l.error || v.error) return;

    setData({
      logement: l?.data?.data?.attributes || {},
      visite: v?.data?.data?.attributes || {}
    });
  };

  useEffect(() => {
    getProperty();
  }, []);

  return (
    <div>
      <Navbar />
      <Slider gallerie={data.logement?.gallerie} />
      <DemandeVisite lId={id} vId={vid} logement={data.logement} visite={data.visite} isProView={isProView} isReadOnly={isReadOnly} />
      <Footer />
    </div>
  );
};

export default PropertyDetails;
