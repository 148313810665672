import React, { useEffect, useState, useContext } from "react";
import { withFormik } from "formik";
import _ from "underscore";
import { SessionContext } from "../../context";
import * as yup from "yup";
import Icon from "../Icon";
import { SEND_MESSAGE } from "../../services";

import EntryText from "../entries/text";
import EntryTextarea from "../entries/textarea";

const validationSchema = yup
  .object({
    // name: yup.string().required(),
    // email: yup.string().required(),
    message: yup.string().required()
  })
  .default({
    // name: "",
    // email: "",
    message: ""
  });

const iniValues = validationSchema.cast();

const PropertyDetails = ({ id, isBail, data, ...props }) => {
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [success, setSuccess] = useState(false);

  const cantSave = !_.isEmpty(errors);
  const ctx = useContext(SessionContext);

  // ...
  const onContactHandler = async () => {

    const message = {
      email: ctx?.user?.email,
      lastname: ctx?.user?.lastname,
      firstname: ctx?.user?.firstname,
      ...props.values,
      titreProp: data?.titre,
      emailProp: data?.proprietaire?.data?.attributes?.email,
      firstnameProp: data?.proprietaire?.data?.attributes?.firstname,
      lastnameProp: data?.proprietaire?.data?.attributes?.lastname,
      id,
      isBail
    }
    // ...
    if (isLoading) return;
    setIsLoading(true);
    // ...
    const { error, reason } = await SEND_MESSAGE(message);
    // ...
    props.values.message = "";
    setIsLoading(false);
    setErrorMessage(error);
    setSuccess(!error)
  };

  const onValidate = async () => {
    const validate = await props.validateForm(props.values);
    setErrors({ ...validate });
  };

  useEffect(() => {
    onValidate();
  }, [props.values]);

  return (
    <div className="widget ltn__form-widget">
      <h4 className="ltn__widget-title ltn__widget-title-border-2">Demande d'informations</h4>
      <div>
        {/* <EntryText
          classes="input-item-textarea ltn__custom-icon"
          name="name"
          placeholder="Votre nom*"
          value={props.values.name}
          onChange={props.handleChange}
        />
        <EntryText
          classes="input-item-textarea ltn__custom-icon"
          name="email"
          placeholder="Votre e-Mail*"
          value={props.values.email}
          onChange={props.handleChange}
        /> */}
        <EntryTextarea
          classes="input-item-textarea ltn__custom-icon"
          name="message"
          placeholder="Écrire un message..."
          value={props.values.message}
          onChange={props.handleChange}
        />
        {!!errorMessage && (
          <div className="row">
            <div className="col-lg-12 form-messege">
              <p className="error">Une erreur s'est produite réessayez</p>
            </div>
          </div>
        )}
        {!!success && (
          <div className="row">
            <div className="col-lg-12 form-messege">
              <p className="success">Votre demande a été bien envoyé!</p>
            </div>
          </div>
        )}
        <button type="submit" className="btn theme-btn-1" onClick={() => onContactHandler()} disabled={cantSave}>
          {!isLoading && "Envoyer"}
          {isLoading && (
            <Icon
              icon="fa-1x fa-solid fa-circle-notch fa-spin"
              style={{
                "--fa-animation-duration": "1s"
              }}
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema
})(PropertyDetails);
