import * as yup from "yup";

export const validationSchema = yup
  .object({
    proprietaire: yup.string().nullable().notRequired(),
    // ...
    titre: yup.string().max(90, "90 caractères au maximum").required("Ce champs est obligatoire"),
    description: yup.object({
      description: yup.string().max(3000, "3000 caractères au maximum sont autorisé dans ce champs").required("Ce champs est obligatoire"),
      disponibilite: yup.string().required("Ce champs est obligatoire"),
      montant_du_loyer: yup
        .number()
        .nullable()
        .min(1, "le montant doit être supérieur ou égal à 1")
        .max(5000, "le montant doit être inférieur ou égal à 5000")
        .required("Ce champs est obligatoire")
    }),
    // ...
    gallerie: yup.array().min(2),
    // ...
    situation_geographique: yup.object({
      adresse: yup.string().required("Ce champs est obligatoire"),
      pays: yup.string().required("Ce champs est obligatoire"),
      secteur: yup.string().required("Ce champs est obligatoire"),
      ville: yup.string().required("Ce champs est obligatoire"),
      code_postale: yup.string().notRequired(),
      gps: yup.object({ lat: yup.string().required("Ce champs est obligatoire"), long: yup.string().required("Ce champs est obligatoire") })
    }),
    // ...
    caracteristiques: yup.object({
      surface: yup
        .number()
        .nullable()
        .min(0, "la surface doit être supérieur ou égal à 0")
        .max(99999999, "la surface doit être inférieur ou égal à 99999999")
        .notRequired(),
      nombre_de_pieces: yup.string().required("Ce champs est obligatoire"),
      chambres: yup
        .number()
        .nullable()
        .min(0, "Chambres  doit être supérieur ou égal à 0")
        .max(99999999, "Chambres doit être inférieur ou égal à 99999999")
        .required("Ce champs est obligatoire"),
      salles_de_bain: yup
        .number()
        .nullable()
        .min(0, "Salles de bain doit être supérieur ou égal à 0")
        .max(99999999, "Salles de bain être inférieur ou égal à 99999999")
        .required("Ce champs est obligatoire"),
      animaux_acceptes: yup.string().required("Ce champs est obligatoire")
    }),
    // ...
    charge_inclus: yup.object({
      chauffage: yup.bool(),
      electricite: yup.bool(),
      eau_chaude: yup.bool(),
      internet: yup.bool(),
      cable_tv: yup.bool()
    }),
    // ...
    stationnement: yup.object({
      stationnement_exterieur: yup.bool(),
      stationnement_interieur: yup.bool(),
      stationnement_dans_la_rue: yup.bool(),
      aucun_stationnement: yup.bool()
      // TODO: add number
    }),
    // ...
    inclus_appartement: yup.object({
      frigidaire: yup.bool(),
      cuisinaire: yup.bool(),
      laveuse: yup.bool(),
      secheuse: yup.bool(),
      lave_vaisselle: yup.bool(),
      entierement_meuble: yup.bool(),
      air_climatise: yup.bool(),
      entree_lave_vaisselle: yup.bool()
    }),
    // ...
    immeuble: yup.object({
      concierge: yup.bool(),
      terrasse_au_toit: yup.bool(),
      entree_scurise: yup.bool(),
      salle_entrainement: yup.bool(),
      ascenseur: yup.bool(),
      piscine: yup.bool(),
      espace_rangement: yup.bool(),
      buanderie: yup.bool()
    }),

    //# Point 5
    type_de_la_location: yup.string(),
    locataire_actuel_recoie_invitation: yup.boolean(),
    aviser_locataire_actuel: yup.boolean(),

    //# Point 6
    fumeur_vapoteur: yup.boolean(),
    besoin_stationnement: yup.boolean(),
    enquete_prelocation: yup.boolean(),
    vous_avez_des_animaux: yup.boolean(),
    date_amenagement_souaite: yup.boolean(),
    // ...
    locataires_actuel: yup.object({
      personnes: yup.array()
    }),
    disponibilite_visite: yup.object({
      slots: yup.array()
    }),
    // ...
    rappel_visite_24: yup.boolean(),
    rappel_visite_12: yup.boolean(),
    rappel_visite_5: yup.boolean()
  })

  .default({
    proprietaire: null,
    // ...
    titre: "",
    description: {
      description: "",
      disponibilite: "",
      montant_du_loyer: null
    },
    // ...
    gallerie: [],
    // ...
    situation_geographique: {
      adresse: "",
      pays: "Canada",
      secteur: "",
      ville: "",
      code_postale: "",
      gps: { lat: "", long: "" }
    },
    // ...
    caracteristiques: {
      surface: null,
      nombre_de_pieces: "",
      chambres: null,
      salles_de_bain: null,
      animaux_acceptes: ""
    },
    charge_inclus: {
      chauffage: false,
      electricite: false,
      eau_chaude: false,
      internet: false,
      cable_tv: false
    },
    // ...
    stationnement: {
      stationnement_exterieur: false,
      stationnement_interieur: false,
      stationnement_dans_la_rue: false,
      aucun_stationnement: false
      // TODO: add number
    },
    // ...
    inclus_appartement: {
      frigidaire: false,
      cuisinaire: false,
      laveuse: false,
      secheuse: false,
      lave_vaisselle: false,
      entierement_meuble: false,
      air_climatise: false,
      entree_lave_vaisselle: false
    },
    // ...
    immeuble: {
      concierge: false,
      terrasse_au_toit: false,
      entree_scurise: false,
      salle_entrainement: false,
      ascenseur: false,
      piscine: false,
      espace_rangement: false,
      buanderie: false
    },

    //# Point 5
    type_de_la_location: "",
    locataire_actuel_recoie_invitation: false,
    aviser_locataire_actuel: false,

    //# Point 6
    fumeur_vapoteur: false,
    besoin_stationnement: false,
    enquete_prelocation: false,
    vous_avez_des_animaux: false,
    date_amenagement_souaite: false,
    // ...
    locataires_actuel: { personnes: [{ nom: "", prenom: "", courriel: "", telephone: "" }] },
    disponibilite_visite: { slots: [] },
    // ...
    rappel_visite_24: false,
    rappel_visite_12: false,
    rappel_visite_5: false
  });
