import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";

import Icon from "../Icon";
import MuiEntryText from "../entries/text";

import { USER_RESET_PASSWORD } from "../../services";
import storage from "../../libs/storage";

import { withFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
    password: yup.string().required().default(""),
    password_confirmation: yup.string().oneOf([yup.ref("password"), null], "Mot de passe non valide").required("Required"),
});

const iniValues = validationSchema.cast();

const ResetPasswordForm = ({ values, errors, ...props }) => {

    let { token } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [loginError, setLoginError] = useState("");
    const cantSave = !_.isEmpty(errors);

    const onTryResetPassword = async () => {
        if (isLoading) return;
        setIsLoading(true);
        // ...
        let req = {
            code: token,
            password: values.password,
            passwordConfirmation: values.password_confirmation,
        };

        const { error, data } = await USER_RESET_PASSWORD(req);
        setIsLoading(false);

        if (error) return setLoginError("Une erreur s'est produite réessayez");
        storage.setItem("__SESSION", data);

        navigate("/");
    };

    return (
        <div className="ltn__login-area pb-110">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area text-center">
                            <h1 className="section-title">
                                Réinitialiser votre mot de passe
                            </h1>
                        </div>
                    </div>
                </div>

                {!!loginError && (
                    <div className="row">
                        <div className="col-lg-12 form-messege">
                            <p className="error text-center">{loginError}</p>
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="account-login-inner">
                            <div className="ltn__form-box contact-form-box">

                                <MuiEntryText type="password" name="password" label="Mot de passe*" value={values.password} onChange={props.handleChange} />
                                <MuiEntryText type="password" name="password_confirmation" label="Confimer votre mot de passe*" value={values.password_confirmation} onChange={props.handleChange} />

                                <div className="btn-wrapper">
                                    <button
                                        className="theme-btn-1 btn reverse-color btn-block"
                                        type="submit"
                                        onClick={onTryResetPassword}
                                        style={{ width: "223px", height: "60px" }}
                                        disabled={cantSave}>
                                        {!isLoading && "valider"}
                                        {isLoading && (
                                            <Icon
                                                icon="fa-1x fa-solid fa-circle-notch fa-spin"
                                                style={{
                                                    "--fa-animation-duration": "1s"
                                                }}
                                            />
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withFormik({
    validationSchema,
    validateOnMount: true,
    mapPropsToValues: () => ({ ...iniValues })
})(ResetPasswordForm);
