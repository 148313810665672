import React, { Component } from "react";
import { Link } from "react-router-dom";

import SoumissionDrawer from "../drawers/soumission-drawer";

class AboutV3 extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="ltn__about-us-area pt-100 pb-200">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img src={publicUrl + "images/service-cle-en-main.jpg"} alt="About Us Image" />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h1 className="section-title">
                    Vous cherchez un service clé en main<span>?</span>
                  </h1>
                  <p>loc-simple.com offre un service clé en main afin que vous aillez l'esprit libre du début jusqu'à la fin.</p>
                </div>
                <ul className="ltn__list-item-half clearfix">
                  <li>
                    <i className="flaticon-home-2" />
                    Affichage
                  </li>
                  <li>
                    <i className="flaticon-park" />
                    Visite des lieux
                  </li>
                  <li>
                    <i className="flaticon-financial" />
                    Enquêtes de prélocation
                  </li>
                  <li>
                    <i className="flaticon-call-center-agent" />
                    Répondre aux prospects
                  </li>
                  <li>
                    <i className="flaticon-buy-home" />
                    Signature des baux
                  </li>
                  <li>
                    <i className="flaticon-plus" />
                    Et encore plus
                  </li>
                </ul>
                <div className="btn-wrapper animated">
                  <a href="#ltn__utilize-soumission-drawer" className="theme-btn-1 btn btn-effect-1 ltn__utilize-toggle">
                    Demande de soumission
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SoumissionDrawer />
      </div>
    );
  }
}

export default AboutV3;
