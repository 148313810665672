import React, { useState } from "react";

import Navbar from "../../components/base/navbar";
import OwnerSubmission from "../../components/section/owner-submission";
import Footer from "../../components/base/footer";
import PlansList from "../../components/section/plans-list";
import ContactezNous from "../../components/section/contactez-nous";
import PageHeader from "../../components/base/page-header";

const Owner = () => {
  const [contactType, setContactType] = useState("");

  return (
    <div>
      <Navbar />
      <PageHeader />
      <PlansList setContactType={setContactType} />
      <OwnerSubmission />
      <ContactezNous contactType={contactType} />
      <Footer />
    </div>
  );
};

export default Owner;
