import React, { useState } from "react";

import DayJS from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import moment from "moment";

import MuiEntryAutocomplete from "./autocomplete";

function getTimeOptions(date, slots) {
  if (!date) return [];
  const dt = moment(date);
  const d = dt.day();
  const i = slots[d];

  //? Interval
  const st = moment(date);
  const et = moment(date);
  st.set({ hour: i[0], minute: 0, second: 0, millisecond: 0 });
  et.set({ hour: i[1], minute: 0, second: 0, millisecond: 0 });

  //? Options
  let options = [];
  const ct = moment(st);

  while (ct <= et) {
    options.push({ label: ct.format("HH:mm"), value: ct.format("YYYY-MM-DD hh:mm a") });
    ct.add(15, "minutes");
  }

  return options;
}

const MuiEntryText = ({ value, onChange, onChangeTimes, slots, usedDates, disabled }) => {
  const now = new Date();
  let maxDate = now;
  maxDate.setDate(now.getDate() + 14);

  const date = value?.date || null;
  const timeOptions = getTimeOptions(date, slots);
  const thisUsedDates = usedDates.filter((ud) => {
    if (!date) return true;
    const tf = moment(date).format("YYYY-MM-DD");
    return ud !== tf;
  });

  return (
    <div className="MuiEntry MuiEntryDate">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Date"
          value={!!date ? DayJS(new Date(date)) : null}
          minDate={DayJS(new Date())}
          maxDate={DayJS(maxDate)}
          format="DD-MM-YYYY"
          disabled={disabled}
          onChange={(date) => {
            onChange(new Date(date));
          }}
          shouldDisableDate={(date) => {
            const d = new Date(date);
            const f = moment(d).format("YYYY-MM-DD");
            if (thisUsedDates.indexOf(f) > -1) return true;
            const days = (Object.keys(slots) || []).map((d) => parseInt(d, 10));
            return days.indexOf(d.getDay()) === -1;
          }}
        />

        <MuiEntryAutocomplete
          label="Time"
          value={value?.times || []}
          options={[...timeOptions]}
          disabled={!value?.date}
          readOnly={disabled}
          onChange={(t) => {
            if (disabled) return;
            onChangeTimes([...t]);
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default MuiEntryText;
