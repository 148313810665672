import React from 'react';

import Navbar from '../../components/base/navbar';
import FakeHeader from '../../components/section/fake-header';
import Error from '../../components/section/error';
import PropertiesAction from '../../components/section/properties-action';
import Footer from '../../components/base/footer';

const ErrorPage = () => {
  return (
    <div>
      <Navbar />
      <FakeHeader />
      <Error />
      <PropertiesAction />
      <Footer />
    </div>
  );
};

export default ErrorPage;
