import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';

const FakeHeader = ({ auto, margin }) => {
  const matchesPhone = useMediaQuery('(max-width:992px)');
  let style = { margin: margin || matchesPhone ? "0 0 20px 0": "0 0 60px 0" };
  // ...
  if (auto) {
    style = { flex: 1 };
  }
  // ...
  return <div style={{ ...style }} />;
};

export default FakeHeader;
