import React, { useContext, useEffect, useRef, useState } from "react";
import _ from "underscore";

import { withFormik } from "formik";
import * as yup from "yup";

import Navbar from "../../components/base/navbar";
import FakeHeader from "../../components/section/fake-header";
import MuiEntryPhoto from "../../components/entries/photo";
import MuiEntryText from "../../components/entries/text";
import Footer from "../../components/base/footer";

import AccountNav from "../../components/base//account-nav";
import EmptyRow from "../../components/property/empty-row";

import storage from "../../libs/storage";
import { SessionContext } from "../../context";
import { GET_USER_PROFILE, EDIT_USER_PROFILE, ADD_FILE } from "../../services";

const validationSchema = yup.object({
  photo: yup.object().nullable().default({}),
  societe: yup.string().notRequired().default(""),
  telephone: yup.string().notRequired().default(""),
  firstname: yup.string().notRequired().default(""),
  lastname: yup.string().notRequired().default(""),
  birthdate: yup.string().notRequired().nullable(),
  email: yup.string().required().default("")
});

const iniValues = validationSchema.cast();

const AccountDetails = (props) => {
  const inpRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const cantSave = !_.isEmpty(props.errors);
  const userId = storage.getItem("__SESSION")?.user?.id || null;

  const ctx = useContext(SessionContext);
  const isPro = ctx?.isPro;

  const onSaveHandler = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const { photo, societe, telephone, firstname, lastname, email, birthdate } = props.values || {};
    let req = { photo, firstname, lastname, email, birthdate, telephone };

    if (isPro) {
      req.societe = societe;
    }


    await EDIT_USER_PROFILE(userId, req);
    setIsLoading(false);
  };

  const onAddFile = async (e) => {
    const target = e.target;
    const files = target.files;
    if (!files.length) return;

    let form = new FormData();
    const file = files[0];
    form.append("files", file, file.name);

    const { error, data: newFiles } = await ADD_FILE(form);
    if (error) return;

    if (newFiles?.[0]) props.setFieldValue("photo", newFiles[0]);

    if (inpRef && inpRef.current) {
      inpRef.current.value = "";
    }
  };

  const onDeleteFile = (e) => {
    e.preventDefault();
    props.setFieldValue("photo", null);
  };

  const getUserProfile = async () => {
    setIsLoading(true);

    const { data } = await GET_USER_PROFILE();
    props.resetForm({
      values: {
        ...iniValues,
        photo: data?.photo || null,
        societe: data?.societe || "",
        telephone: data?.telephone || "",
        email: data?.email,
        firstname: data?.firstname,
        lastname: data?.lastname,
        birthdate: data?.birthdate
      }
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="__expended-container">
      <Navbar />
      <FakeHeader />

      <div className="liton__wishlist-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__product-tab-area">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="ltn__tab-menu-list mb-50">
                        <AccountNav tabIdx={0} />
                      </div>
                    </div>

                    <div className="col-lg-8">
                      <div className="tab-content">
                        <div className="tab-pane active show">
                          <div className="ltn__myaccount-tab-content-inner">
                            <div className="ltn__form-box">
                              {!isLoading && (
                                <div>
                                  <div className="row mb-50">
                                    <div className="col-md-12 mb-40" style={{ display: "flex", justifyContent: "center" }}>
                                      <MuiEntryPhoto
                                        classes="input-item-name ltn__custom-icon"
                                        name="photo"
                                        value={props.values.photo}
                                        onChange={onAddFile}
                                        onDelete={onDeleteFile}
                                      />
                                    </div>

                                    {isPro && (
                                      <div className="col-md-12">
                                        <MuiEntryText
                                          classes="input-item-name ltn__custom-icon"
                                          name="societe"
                                          label="Nom de votre société"
                                          value={props.values.societe}
                                          onChange={props.handleChange}
                                        />
                                      </div>
                                    )}

                                    <div className="col-md-6">
                                      <MuiEntryText
                                        classes="input-item-name ltn__custom-icon"
                                        name="firstname"
                                        label="Prénom"
                                        value={props.values.firstname}
                                        onChange={props.handleChange}
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <MuiEntryText
                                        classes="input-item-name ltn__custom-icon"
                                        name="lastname"
                                        label="Nom"
                                        value={props.values.lastname}
                                        onChange={props.handleChange}
                                      />
                                    </div>
                                    <div>
                                      <MuiEntryText
                                        type="date"
                                        name="birthdate"
                                        label="Date de naissance"
                                        value={props.values.birthdate}
                                        onChange={props.handleChange}
                                        isShrinked
                                      />
                                    </div>
                                    <div className="col-md-12">
                                      <MuiEntryText
                                        classes="input-item-email ltn__custom-icon"
                                        name="email"
                                        type="email"
                                        label="Email*"
                                        value={props.values.email}
                                        onChange={props.handleChange}
                                        readOnly={true}
                                      />
                                    </div>
                                    <div className="col-md-12">
                                      <MuiEntryText
                                        classes="input-item-phone ltn__custom-icon"
                                        name="telephone"
                                        label="Numero de telephone"
                                        value={props.values.telephone}
                                        onChange={props.handleChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="btn-wrapper">
                                    <button
                                      type="submit"
                                      className="btn theme-btn-1 btn-effect-1 text-uppercase"
                                      onClick={() => onSaveHandler()}
                                      disabled={cantSave}>
                                      Sauvegarder
                                    </button>
                                  </div>
                                </div>
                              )}

                              <EmptyRow isLoading={isLoading} noEntities={false} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FakeHeader auto />
      <Footer />
    </div>
  );
};

export default withFormik({
  validationSchema,
  validateOnMount: true,
  mapPropsToValues: () => ({ ...iniValues })
})(AccountDetails);
