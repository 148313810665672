import React from "react";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const EmptyTag = () => <></>;

const MuiEntryAutocomplete = ({ name, value = [], label = "", onChange, options, disabled, readOnly }) => {
  const onChangeHandler = (checked, values) => {
    onChange([...values]);
  };

  return (
    <div className="MuiEntry MuiEntryAutocomplete">
      <Autocomplete
        multiple
        name={name}
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(o, v) => o.value === v.value}
        disableClearable={readOnly}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} className="MuiEntryAutocomplete__option">
              <Checkbox checked={selected} />
              {option.label}
            </li>
          );
        }}
        renderTags={() => EmptyTag}
        renderInput={(params) => <TextField {...params} label={label} placeholder={!!value?.length ? `${value.length} options choisies` : ""} />}
        onChange={onChangeHandler}
        value={value}
        disabled={!!disabled}
      />
    </div>
  );
};

export default MuiEntryAutocomplete;
