import React from "react";

import Navbar from "../../components/base/navbar";
import FakeHeader from "../../components/section/fake-header";
import ManageForm from "../../components/property/manage-form";
import Footer from "../../components/base/footer";

const PropertyManage = () => {
  return (
    <div>
      <Navbar />
      <FakeHeader />
      <ManageForm isBail />
      <Footer />
    </div>
  );
};

export default PropertyManage;
