import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/base/navbar";
import FakeHeader from "../../components/section/fake-header";
import Footer from "../../components/base/footer";
import Icon from "../../components/Icon";
import ConfirmBox from "../../components/base/confirm-box";
import AccountNav from "../../components/base//account-nav";
import CardRow from "../../components/property/bail-row";
import EmptyRow from "../../components/property/empty-row";
import useModal from "../../hooks/useModal";

import storage from "../../libs/storage";
import { GET_MY_BAILS, DELETE_BAIL } from "../../services";

const AccountBails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [myBails, setMyBails] = useState([]);
  const [deleteId, setDeleteId] = useState(null);

  const getUsrBails = async () => {
    setIsLoading(true);
    const userId = storage.getItem("__SESSION")?.user?.id;
    const { error, data } = await GET_MY_BAILS(userId);

    setMyBails(data?.data || []);
    setIsLoading(false);
  };
  const onDeleteProperties = async (isDelete) => {
    if (!isDelete) return setDeleteId(null);
    if (isLoading) return;

    await DELETE_BAIL(deleteId);
    getUsrBails();
  };

  useModal();

  useEffect(() => {
    getUsrBails();
  }, []);

  return (
    <div className="__expended-container">
      <Navbar />
      <FakeHeader />

      <div className="liton__wishlist-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__product-tab-area">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="ltn__tab-menu-list mb-50">
                        <AccountNav tabIdx={2} />
                      </div>
                    </div>

                    <div className="col-lg-8">
                      <div className="tab-content">
                        <div className="tab-pane active show" id="ltn_tab_1_2">
                          <div className="ltn__myaccount-tab-content-inner">
                            {!isLoading && !!myBails.length && (
                              <>
                                <div className="__cards">
                                  <div className="__cards__header">
                                    <div className="__title">Cession de bail</div>
                                    <div className="__actions">
                                      <div>Actions</div>
                                    </div>
                                  </div>
                                  <div className="__cards__body">
                                    {myBails.map((data, idx) => (
                                      <CardRow data={data} key={idx} onDeleteProperties={(id) => setDeleteId(id)} />
                                    ))}
                                  </div>
                                </div>

                                {/* <Pages /> */}
                              </>
                            )}

                            <EmptyRow title="Vous n'avez pas de bails à ceder" isLoading={isLoading} noEntities={!myBails.length}>
                              <Link className="theme-btn-3 btn btn-effect-1 __btn-icon" to="/bails/add">
                                <Icon icon="fa-solid fa-plus" />
                                Ajouter
                              </Link>
                            </EmptyRow>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmBox title="ce bail?" handler={onDeleteProperties} />

      <FakeHeader auto />
      <Footer />
    </div>
  );
};

export default AccountBails;
