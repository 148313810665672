import React from "react";
import Moment from "react-moment";

const CardRow = ({ isProp, data, onEditVisite, onDeleteVisite }) => {
  const l = data?.logement?.data?.attributes;
  const address = l?.situation_geographique?.adresse || "";
  const status = data?.etat_visite || "";
  const isValid = status === "valide";

  const list = (data?.condidats_locataires?.personnes || []).map((p) => `${p.prenom} ${p.nom}`).join(", ");

  return (
    <div className="__card">
      <div className="__card__info __alert" style={{ padding: "0 20px 0 0" }}>
        <div>
          <span className="__label">Address</span>
          <span className="__value">{address}</span>
        </div>
        <div>
          <span className="__label">Status</span>
          <span className="__value">{status}</span>
        </div>

        {isProp && (
          <div>
            <span className="__label">Locataire Potentiels:</span>
            <span className="__value">{list}</span>
          </div>
        )}

        {isValid && !!data.date_visite && (
          <div>
            <span className="__label">Date:</span>
            <span className="__value">
              <Moment format="MMMM YYYY, hh:mm">{data.date_visite}</Moment>
            </span>
          </div>
        )}
      </div>

      <div className="__card__actions">
        <span onClick={() => onEditVisite(data)} style={{ margin: "0 10px 0 0" }}>
          <div>
            <i className="fa-solid fa-eye" style={{ cursor: "pointer" }} />
          </div>
        </span>
        <span>
          <a href="#ltn__utilize-confirm-box" className="ltn__utilize-toggle">
            <i className="fa-solid fa-trash-can" style={{ cursor: "pointer" }} onClick={() => onDeleteVisite(data.id)} />
          </a>
        </span>
      </div>
    </div>
  );
};

export default CardRow;
