import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import Icon from "../Icon";
import FakeFooter from "./fake-footer";

const CallToActon = ({
  isHome,
  isProperties,
  title = "A la recherche de votre logement de rêve?",
  subTitle = "Nous pouvons vous aider à réaliser votre rêve d'un logement",
  ctaLabel = "Parcourir",
  ctaTarget = "/properties",
  bg = ""
}) => {
  // ...
  return (
    <div className={cx("ltn__call-to-action-area call-to-action-6 before-bg-bottom", { [bg]: !!bg })}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---">
              <div className="coll-to-info text-color-white">
                <h1>{title}</h1>
                <p>{subTitle}</p>
                {isHome && (
                  <p style={{ lineHeight: "0" }}>
                    C'est aussi <b>simple</b> que cela.
                  </p>
                )}
                {isProperties && (
                  <p style={{ lineHeight: "0" }}>
                    Cela n'aura jamais été aussi{" "} <b>simple</b>
                  </p>
                )}
              </div>

              <div className="btn-wrapper go-top">
                <Link className="theme-btn-1 btn-effect-1 btn btn-white __btn-icon" to={ctaTarget}>
                  {ctaLabel} <i className="icon-next" />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <FakeFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToActon;
