import React from "react";

import Navbar from "../../components/base/navbar";
import FakeHeader from "../../components/section/fake-header";
import RegisterForm from "../../components/section/register-form";
import Footer from "../../components/base/footer";

const Register = () => {
  return (
    <div>
      <Navbar />
      <FakeHeader />
      <RegisterForm />
      <Footer />
    </div>
  );
};

export default Register;
