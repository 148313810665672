import React from "react";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const MuiEntryCheckbox = ({ name, label, value, onChange, disabled }) => {
  return (
    <div className="MuiEntry MuiEntryCheckbox">
      <FormGroup>
        <FormControlLabel label={label} control={<Checkbox name={name} checked={value} onChange={onChange} />} disabled={disabled} />
      </FormGroup>
    </div>
  );

  // return (
  //   <div className="input-item">
  //     <label className="checkbox-inline">
  //       <input type="checkbox" name={name} value={value} onChange={onChange} />
  //       &nbsp; {label}
  //     </label>
  //   </div>
  // );
};

export default MuiEntryCheckbox;
