import { DateTime } from "luxon";

const NEXT_MONTH = 12;

export const getDateRanges = (isToSave) => {
  let mi = 1;
  let out = [];
  // ...
  const now = DateTime.now();
  let mn = now.set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 });
  out[0] = { label: "Maintenant", value: `${now.minus({ month: 3 }).toFormat("yyyy-LL-dd")}_${now.toFormat("yyyy-LL-dd")}` };
  if (isToSave) out[0].label = `${now.toFormat("dd LLLL yyyy")}`;
  // ...
  while (mi <= NEXT_MONTH) {
    out[mi] = { label: mn.monthLong, value: `${mn.startOf("month").toFormat("yyyy-LL-dd")}_${mn.endOf("month").toFormat("yyyy-LL-dd")}` };
    if (isToSave) out[mi].label = `${mn.toFormat("LLLL yyyy")}`;
    mn = mn.plus({ month: 1 });
    mi++;
  }
  // ...
  return out;
};

export const dureLocation = [
  { label: "Moins de 3 mois", value: "moins_3mois" },
  { label: "3 à 6 mois", value: "m3_6mois" },
  { label: "6 à 12 mois", value: "m6_12mois" },
  { label: "12 mois et plus", value: "m12_plus" },
];

export const lotSizesOptions = [
  { label: "Loft (1/½)", value: "Loft (1/½)" },
  { label: "2 ½", value: "2 ½" },
  { label: "3 ½", value: "3 ½" },
  { label: "4 ½", value: "4 ½" },
  { label: "5 ½", value: "5 ½" },
  { label: "6 ½", value: "6 ½" },
  { label: "7 ½", value: "7 ½" },
  { label: "8 ½", value: "8 ½" },
  // { label: "Chambre", value: "Chambre" },
  { label: "Maison", value: "Maison" },
  // { label: "Bureau", value: "Bureau" },
  { label: "Chalet", value: "Chalet" }
];

export const animauxOptions = [
  { label: "Chat et chien autorisés", value: "Chat et chien" },
  { label: "Chat autorisé", value: "Chat" },
  { label: "Non applicable", value: "Non applicable" }
];

export const animauxOptionsForProprio = [
  { label: "Chat et chien autorisés", value: "Chat et chien" },
  { label: "Chat autorisé", value: "Chat" },
  { label: "Aucun animaux", value: "Aucun" }
];

// export const animauxOptions = [
//   { label: "Chien autorisé", value: "Chien autorise" },
//   { label: "Chat autorisé", value: "Chat autorise" },
//   { label: "Non applicable", value: "Non applicable" }
// ];

// export const animauxOptionsForProprio = [
//   { label: "Chien autorisé", value: "Chien autorise" },
//   { label: "Petit chien autorisé", value: "Chien autorise" },
//   { label: "Chat autorisé", value: "Chat autorise" },
//   { label: "Aucun animaux", value: "Aucun animaux" }
// ];

export const amenitiesOptions = [
  { label: "Stationnement", name: "stationnement" },
  { label: "Eau chaude", name: "eau_chaude" },
  { label: "Entree laveuse secheuse", name: "entree_laveuse_secheuse" },
  { label: "Chauffage", name: "chauffage" },
  { label: "Electricite", name: "electricite" },
  { label: "Entree lave vaiselle", name: "entree_lave_vaiselle" },
  { label: "Air climatise", name: "air_climatise" }
];

export const amenitiesList = [
  {
    label: "Charge inclus",
    name: "charge_inclus",
    options: [
      { label: "Chauffage", name: "chauffage" },
      { label: "Electricite", name: "electricite" },
      { label: "Eau chaude", name: "eau_chaude" },
      { label: "Internet", name: "internet" },
      { label: "Cable TV", name: "cable_tv" }
    ]
  },
  {
    label: "Stationnement",
    name: "stationnement",
    options: [
      { label: "Stationnement exterieur", name: "stationnement_exterieur" },
      { label: "Stationnement interieur", name: "stationnement_interieur" },
      { label: "Dans la rue", name: "stationnement_dans_la_rue" },
      { label: "Aucun", name: "aucun_stationnement" }
    ]
  },
  {
    label: "Inclus dans l'appartement",
    name: "inclus_appartement",
    options: [
      { label: "Frigidaire", name: "frigidaire" },
      { label: "Cuisinaire", name: "cuisinaire" },
      { label: "Laveuse", name: "laveuse" },
      { label: "Secheuse", name: "secheuse" },
      { label: "Lave vaisselle", name: "lave_vaisselle" },
      { label: "Entierement meublé", name: "entierement_meuble" },
      { label: "Air Climatisé", name: "air_climatise" },
      { label: "Entrée lave vaiselle", name: "entree_lave_vaisselle" }
    ]
  },
  {
    label: "Immeuble",
    name: "immeuble",
    options: [
      { label: "Concierge", name: "concierge" },
      { label: "Terrasse au toit", name: "terrasse_au_toit" },
      { label: "Entrée sécurisé", name: "entree_scurise" },
      { label: "Salle d'entrainement", name: "salle_entrainement" },
      { label: "Ascenseur", name: "ascenseur" },
      { label: "Piscine", name: "piscine" },
      { label: "Espace de rangement", name: "espace_rangement" },
      { label: "Buanderie", name: "buanderie" }
    ]
  }
];

export const baseIniData = {
  all_villes: [],
  villes: [],
  montant_du_loyer: [],
  disponibilite: [],
  nombre_de_pieces: [],
  animaux_acceptes: []
};

export const DAFAULT_MIN_LOYER = 100;
export const DAFAULT_MAX_LOYER = 5000;

export const getDecodedFilters = (params, data) => {
  return {
    secteurs:
      params
        .get("secteurs")
        ?.split(",")
        ?.filter((v) => !!v) || [],
    pieces:
      params
        .get("pieces")
        ?.split(",")
        ?.filter((v) => !!v) || [],
    animaux:
      params
        .get("animaux")
        ?.split(",")
        ?.filter((v) => !!v) || [],

    min_m: DAFAULT_MIN_LOYER,
    max_m: DAFAULT_MAX_LOYER,

    dispo_array:
      params
        .get("dispo_array")
        ?.split(",")
        ?.filter((v) => !!v) || []
  };
};

export const getInitOptions = (data) => {
  const villeOptions = [];

  data?.all_villes
    ?.map((v) => {
      const inners = Array.from(new Set(v.secteurs)).map((i) => ({ label: i, value: i }));
      return { label: v.ville, value: v.ville, inners };
    })
    .filter((v) => !!v.inners.length)
    .forEach((v) => {
      v.inners
        .map((i) => ({ ...i, groupBy: v.value }))
        .forEach((i) => {
          villeOptions.push(i);
        });
    });

  return {
    villeOptions: villeOptions,
    piecesOptions: lotSizesOptions,
    animauxOptions: animauxOptions
  };
};
