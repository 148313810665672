import React from "react";

const CardRow = ({ data, onEditAlert, onDeleteAlert }) => {
  return (
    <div className="__card">
      <div className="__card__info __alert" style={{ padding: "0 20px 0 0" }}>
        <div>
          <span className="__label">Nombre de pièces: </span>
          <span className="__value">{data.nombre_de_pieces?.data?.join(", ") || ""}</span>
        </div>

        <div>
          <span className="__label">Montant du loyer minimum: </span>
          <span className="__value">{data.montant_du_loyer_minimum}</span>
        </div>

        <div>
          <span className="__label">Montant du loyer maximum: </span>
          <span className="__value">{data.montant_du_loyer_maximum}</span>
        </div>

        <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
          <span className="__label">Secteurs: </span>
          <span className="__value">{data.secteurs?.data?.join(", ") || ""}</span>
        </div>
      </div>

      <div className="__card__actions">
        <span onClick={() => onEditAlert(data)} style={{ margin: "0 10px 0 0" }}>
          <a href="#ltn__utilize-alert-drawer" className="ltn__utilize-toggle">
            <i className="fa-solid fa-eye" style={{ cursor: "pointer" }} />
          </a>
        </span>
        <span>
          <a href="#ltn__utilize-confirm-box" className="ltn__utilize-toggle">
            <i className="fa-solid fa-trash-can" style={{ cursor: "pointer" }} onClick={() => onDeleteAlert(data.id)} />
          </a>
        </span>
      </div>
    </div>
  );
};

export default CardRow;
