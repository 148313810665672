import React, { useState, useContext, useEffect } from "react";
import { ASK_FOR_CONTACT } from "../../services";

import MuiEntryText from "../entries/text";
import Icon from "../Icon";

import { withFormik } from "formik";

import * as yup from "yup";

import { SessionContext } from "../../context";

const validationSchema = yup
  .object({
    nom: yup.string().required(),
    prenom: yup.string().required(),
    email: yup.string().required(),
    telephone: yup.string().required(),
    note: yup.string().required()
  })
  .default({
    nom: "",
    prenom: "",
    email: "",
    telephone: "",
    plan: "",
    note: ""
  });

const iniValues = validationSchema.cast();

const ContactezNous = ({ contactType, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [wsError, setWsError] = useState("");
  const [wsSuccess, setWsSuccess] = useState("");
  const ctx = useContext(SessionContext);

  const onTryAskForContact = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const { error, reason, data } = await ASK_FOR_CONTACT(props.values);
    setIsLoading(false);
    if (error) return setWsError("Une erreur s'est produite réessayez");
    return setWsSuccess("Votre demande de contact à été bien envoyé");
  };

  useEffect(() => {
    let plan = contactType ? contactType : "";
    props.resetForm({ values: { ...iniValues, plan } });
  }, [contactType]);

  return (
    <div>
      <div className="ltn__modal-area ltn__add-to-cart-modal-area----">
        <div className="modal fade" id="ltn_contactez_nous_modal" tabIndex={-1}>
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="ltn__quick-view-modal-inner">
                  <div className="modal-product-item">
                    <div className="row">
                      <div className="col-12">
                        <div className="modal-product-info text-center">
                          <h4>Etre rappelé</h4>
                          <p style={{ margin: "0" }}>info@locasimple.com</p>
                          <p>581-995-5758 Poste 5</p>

                          <div className="row">
                            <div className="col-lg-6">
                              <MuiEntryText name="nom" label="Nom" value={props.values.nom} onChange={props.handleChange} />
                            </div>
                            <div className="col-lg-6">
                              <MuiEntryText name="prenom" label="Prénom" value={props.values.prenom} onChange={props.handleChange} />
                            </div>

                            <div className="col-lg-12">
                              <MuiEntryText
                                type="email"
                                name="email"
                                label="Adresse courriel"
                                value={props.values.email}
                                onChange={props.handleChange}
                              />
                            </div>

                            <div className="col-lg-12">
                              <MuiEntryText name="telephone" label="Téléphone" value={props.values.telephone} onChange={props.handleChange} />
                            </div>

                            <div className="col-lg-12">
                              <MuiEntryText multiline name="note" label="Note" value={props.values.note} onChange={props.handleChange} />
                            </div>
                          </div>

                          {wsError.length > 2 && (
                            <div style={{ marginTop: "0" }} className={"alert alert-danger mt-50"} role="alert">
                              {wsError}
                            </div>
                          )}

                          {wsSuccess.length > 2 && (
                            <div style={{ marginTop: "0" }} className={"alert alert-success mt-50"} role="alert">
                              {wsSuccess}
                            </div>
                          )}

                          <div className="btn-wrapper mt-0">
                            <button onClick={onTryAskForContact} className="theme-btn-1 btn btn-full-width-2 btn-effect-4">
                              {!isLoading && "Valider"}
                              {isLoading && (
                                <Icon
                                  icon="fa-1x fa-solid fa-circle-notch fa-spin"
                                  style={{
                                    "--fa-animation-duration": "1s"
                                  }}
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFormik({
  validationSchema,
  validateOnMount: true,
  mapPropsToValues: () => ({ ...iniValues })
})(ContactezNous);
