import React, { useState } from "react";
import { USER_ASK_RESET_PASSWORD } from "../../services";
import Icon from "../Icon";
import { withFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  email: yup.string().email("Email non valide").required("Required")
});

const iniValues = validationSchema.cast();

const LoginForgot = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [success, setSuccess] = useState(false);

  const onTryResetPassword = async () => {
    if (isLoading) return;
    setIsLoading(true);
    const { error, reason, data } = await USER_ASK_RESET_PASSWORD(props.values);
    setIsLoading(false);
    if (error) setLoginError("Une erreur s'est produite réessayez");
    if (!error) setLoginError("");
    setSuccess(!error);
  };

  return (
    <div>
      <div className="ltn__modal-area ltn__add-to-cart-modal-area----">
        <div className="modal fade" id="ltn_forget_password_modal" tabIndex={-1}>
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="ltn__quick-view-modal-inner">
                  <div className="modal-product-item">
                    <div className="row">
                      <div className="col-12">
                        <div className="modal-product-info text-center">
                          <h4>Mot de passe oublié?</h4>
                          <p className="added-cart"> Entrez votre email d'inscription.</p>
                          {/* <form action="#" className="ltn__form-box"> */}
                          <input
                            type="text"
                            name="email"
                            placeholder="Entrez votre email d'inscription*"
                            value={props.values.email}
                            onChange={props.handleChange}
                          />
                          {!!loginError && (
                            <div className="row">
                              <div className="col-lg-12 form-messege">
                                <p className="error text-center">{loginError}</p>
                              </div>
                            </div>
                          )}
                          {!!success && (
                            <div className="row">
                              <div className="col-lg-12 form-messege">
                                <p className="success text-center">Vérifier votre boite email pour réinitialiser votre mot de passe!</p>
                              </div>
                            </div>
                          )}
                          <div className="btn-wrapper mt-0">
                            <button onClick={onTryResetPassword} className="theme-btn-1 btn btn-full-width-2">
                              {!isLoading && "Valider"}
                              {isLoading && (
                                <Icon
                                  icon="fa-1x fa-solid fa-circle-notch fa-spin"
                                  style={{
                                    "--fa-animation-duration": "1s"
                                  }}
                                />
                              )}
                            </button>
                          </div>
                          {/* </form> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema
})(LoginForgot);
