import axios from "axios";

import storage from "../libs/storage";
import { filtersQuery, myPropertiesQuery, myBailsQuery } from "./queries";

const qs = require("qs");

//# Axios Instance
const HTTP = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 99999
});

//# Request Interceptors
HTTP.interceptors.request.use(
  async (config) => {
    config.headers.Accept = "application/json";
    // config.headers["Content-Type"] = "application/json";
    // ...
    const session = storage.getItem("__SESSION") || {};
    const jwt = session.jwt;
    // ...
    if (jwt) config.headers.Authorization = `Bearer ${jwt}`;
    else config.headers.Authorization = null;
    // ...
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
//# Response Interceptors
HTTP.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // Force user to logout
    }
    return Promise.reject(error);
  }
);

//# Init AppData
export const GET_INIT_DATA = async () => {
  try {
    const res = await HTTP.get("/initialdata");
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Login
export const USER_LOGIN = async (req) => {
  try {
    const res = await HTTP.post("/auth/local", req);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Validate access token
export const VALIDATE_ACCESS_TOKEN = async (access_token, login_provider) => {
  try {
    const res = await HTTP.get(`/auth/${login_provider}/callback?access_token=${access_token}`);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Reset password
export const USER_ASK_RESET_PASSWORD = async (req) => {
  try {
    const res = await HTTP.post("/auth/forgot-password", req);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Reset password
export const USER_RESET_PASSWORD = async (req) => {
  try {
    const res = await HTTP.post("/auth/reset-password", req);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Register
export const USER_REGISTER = async (req) => {
  try {
    const res = await HTTP.post("/auth/local/register", req);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Change Password
export const USER_CHANGE_PASSWORD = async (req) => {
  try {
    const res = await HTTP.post("/auth/change-password", req);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Profile
export const GET_USER_PROFILE = async (userId) => {
  try {
    const res = await HTTP.get("/users/me/?populate=deep,5");
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const EDIT_USER_PROFILE = async (userId, req) => {
  try {
    const res = await HTTP.put(`/users/${userId}`, req);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Properties All
export const GET_SEARCH_PROPERTIES = async (filters) => {
  try {
    const res = await HTTP.get(filtersQuery(filters));
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const GET_MY_PROPERTIES = async (userId) => {
  try {
    const res = await HTTP.get(myPropertiesQuery(userId));
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Property Item
export const GET_PROPERTY = async (id) => {
  try {
    const res = await HTTP.get(`/logements/${id}?populate=deep,3`);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const ADD_PROPERTY = async (id, req) => {
  try {
    const res = await HTTP.post("/logements", { data: req });
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const EDIT_PROPERTY = async (id, req) => {
  try {
    const res = await HTTP.put(`/logements/${id}`, { data: req });
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const DELETE_PROPERTY = async (id) => {
  try {
    const res = await HTTP.delete(`/logements/${id}`);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Favorites
export const GET_MY_FAVS = async (userId) => {
  try {
    const res = await HTTP.get(`/favorites?populate=deep,5`);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const ADD_FAV = async (req) => {
  try {
    const res = await HTTP.post("/favorites", { data: req });
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const DELETE_FAV = async (id) => {
  try {
    const res = await HTTP.delete(`/favorites/${id}`);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Bails
export const GET_MY_BAILS = async (userId) => {
  try {
    const res = await HTTP.get(myBailsQuery(userId));
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Bails Item
export const GET_BAIL = async (id) => {
  try {
    const res = await HTTP.get(`/bails/${id}?populate=deep,5`);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const ADD_BAIL = async (id, req) => {
  try {
    const res = await HTTP.post("/bails", { data: req });
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const EDIT_BAIL = async (id, req) => {
  try {
    const res = await HTTP.put(`/bails/${id}`, { data: req });
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const DELETE_BAIL = async (id) => {
  try {
    const res = await HTTP.delete(`/bails/${id}`);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Files
export const ADD_FILE = async (req) => {
  try {
    const res = await HTTP.post(`/upload`, req, { headers: { "Content-Type": "multipart/form-data" } });
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const ROT_FILE = async (req) => {
  try {
    const res = await HTTP.post(`/images`, req);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const DELETE_FILE = async (id) => {
  try {
    const res = await HTTP.delete(`/upload/files/${id}`);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Alerts
export const GET_MY_ALERTS = async (id) => {
  try {
    const res = await HTTP.get(`/alertes`);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const ADD_ALERT = async (req) => {
  try {
    const res = await HTTP.post("/alertes", { data: req });
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const EDIT_ALERT = async (id, req) => {
  try {
    const res = await HTTP.put(`/alertes/${id}`, { data: req });
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const DELETE_ALERT = async (id) => {
  try {
    const res = await HTTP.delete(`/alertes/${id}`);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Vistes
export const GET_MY_VISITE = async (id) => {
  try {
    const res = await HTTP.get(`/visites?populate=deep,5`);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const GET_VISITE = async (id) => {
  try {
    const res = await HTTP.get(`/visites/${id}?populate=deep,5`);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const ADD_VISITE = async (req) => {
  try {
    const res = await HTTP.post("/visites", { data: req });
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const EDIT_VISITE = async (id, req) => {
  try {
    const res = await HTTP.put(`/visites/${id}`, { data: req });
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
export const DELETE_VISITE = async (id) => {
  try {
    const res = await HTTP.delete(`/visites/${id}`);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Soumision Item
export const ADD_SOUMISION = async (req) => {
  // ...
  try {
    const res = await HTTP.post("/soumissions", { data: req });
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Billings
export const GET_MY_BILLINGS = async (userId) => {
  try {
    const query = qs.stringify({ filters: { client: { id: { $eq: userId } } } });
    const res = await HTTP.get(`/invoices?populate=deep,5&${query}`);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Init AppData
export const CHECKOUT = async (idLogement) => {
  try {
    const res = await HTTP.get(`/checkout/${idLogement}`);
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# Soumision Item
export const ASK_FOR_CONTACT = async (req) => {
  // ...
  try {
    const res = await HTTP.post("/demande-de-contacts", { data: req });
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};

//# SEND message
export const SEND_MESSAGE = async (req) => {
  try {
    const res = await HTTP.post("/sendMessage", { data: req });
    const data = res.data;
    return { error: false, reason: "", data };
  } catch (err) {
    return { error: true, reason: err?.code || "ERROR", data: null };
  }
};
