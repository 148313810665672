import React from "react";

import Icon from "../Icon";

const EmptyRow = ({ title = "", sub = "", isLoading, noEntities, children }) => {
  if (!isLoading && !noEntities) return null;
  // ...
  return (
    <div className="__empty_card_row">
      <div className="__inner">
        {!isLoading && (
          <>
            <h5 className="__title">{title}</h5>
            <div>{children}</div>
          </>
        )}

        {isLoading && (
          <div className="row">
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "400px" }}>
              <div>
                <Icon
                  icon="fa-2x fa-solid fa-circle-notch fa-spin"
                  style={{
                    color: "#327abe",
                    "--fa-animation-duration": "1s"
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {/* {isLoading && (
          <Icon
            icon="fa-1x fa-solid fa-circle-notch fa-spin"
            style={{
              "--fa-animation-duration": "1s"
            }}
          />
        )} */}
      </div>
    </div>
  );
};

export default EmptyRow;
