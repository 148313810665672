import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { SessionContext } from "../../context";

const plansList = [
  {
    title: "Affichage",
    description: "Gratuit",
    links: [
      {
        modal: false,
        target: "/account/mes-logements",
        label: "Continuer"
      }
    ],
    features: ["Jusqu'à 3 annonces", "Envoie d'alerte aux locataires potentiels", "49.99$ + tx / mois pour chaque annonce supplémentaire"]
  },
  {
    title: "Team LocSimple",
    description: "299.99$ / mois + tx",
    links: [
      {
        modal: true,
        target: "#ltn_contactez_nous_modal",
        label: "Contactez nous"
      }
    ],
    features: [
      "Associer votre site web à LocSimple",
      "Nombre illimité d'annonces",
      "Envoie d'alerte aux locataires potentiels",
      "15 % de rabais sur l'agent de location"
    ]
  },
  {
    title: "L'agent de location",
    description: "319.99$ / appartement + tx",
    links: [
      {
        modal: true,
        target: "#ltn_contactez_nous_modal",
        label: "Contactez nous"
      }
    ],
    features: [
      "Affichage de l'annonce",
      "Envoie d'alerte aux locataires potentiels",
      "Visite des lieux aux locataires potentiels",
      "Calendrier de visites",
      "Suivi après visite"
    ]
  }
];

const Plans = ({ setContactType }) => {
  const ctx = useContext(SessionContext);
  const isAuthed = !!ctx.isAuthed;
  const isPro = !!ctx.isPro;

  return (
    <div className="ltn__feature-area section-bg-1 pt-60 pb-60 mb-120---">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area ltn__section-title-2--- text-center">
              <h1 className="section-title">Nos tarifs</h1>
            </div>
          </div>
        </div>

        <div className="row justify-content-center go-top">
          {plansList.map((plan, idx) => (
            <div key={idx} className="col-lg-4 col-sm-6 col-12 mb-35">
              <div style={{ height: "100%" }} className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1">
                <div style={{ display: "flex", flexDirection: "column", height: "100%" }} className="ltn__feature-info">
                  <h3>{plan.title}</h3>

                  <ul style={{ textAlign: "left", flexGrow: "1" }}>
                    {plan?.features?.map((feature, idx) => (
                      <li key={idx}>{feature}</li>
                    ))}
                  </ul>

                  <p style={{ fontWeight: "bold", fontSize: "1.2rem", marginBottom: "1rem" }}>{plan.description}</p>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {!ctx.isAuthed && (
                      <Link style={{ margin: "8px 0 0 0" }} className={"theme-btn-1 btn btn-effect-1"} to="/register">
                        <span style={{ margin: "0 5px 0 0" }}>Créer mon compte</span>
                      </Link>
                    )}

                    {ctx.isAuthed && isPro && (
                      <>
                        {plan.links.map((link, idx) => {
                          if (link.modal === false) {
                            return (
                              <Link key={idx} style={{ margin: "8px 0 0 0" }} className={"theme-btn-1 btn btn-effect-1"} to={link.target}>
                                <span style={{ margin: "0 5px 0 0" }}>{link.label}</span>
                              </Link>
                            );
                          } else {
                            return (
                              <button
                                key={idx}
                                data-bs-toggle="modal"
                                data-bs-target={link.target}
                                style={{ margin: "8px 0 0 0" }}
                                className={idx % 2 === 1 ? "theme-btn-3 btn btn-effect-1" : "theme-btn-3 btn btn-effect-1"}
                                onClick={() => setContactType(plan.title)}>
                                <span style={{ margin: "0 5px 0 0" }}>{link.label}</span>
                              </button>
                            );
                          }
                        })}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Plans;
