import React from "react";
import { Link } from "react-router-dom";

const CardRow = ({ data, onDeleteProperties }) => {
  const id = data.id;
  const d = data.attributes;

  const linkEdit = `/bails/edit/${id}`;
  const publicUrl = process.env.REACT_APP_AWS_CLOUDFRONT_DISTRIBUTION;
  const thumbnail = `${d?.gallerie?.data?.[0]?.attributes?.hash}${d?.gallerie?.data?.[0]?.attributes?.ext}`;
  const isPublished = !!d?.publishedAt;

  return (
    <div className="__card">
      <div className="__card__img go-top">
        <Link to={linkEdit}>{thumbnail && <img src={publicUrl + "/" + thumbnail} alt="#" />}</Link>
      </div>

      <div className="__card__info">
        <h6 className="__card__info__name mb-10 go-top">
          <Link to={linkEdit}>
            {d?.titre}
            <span>{isPublished ? "En ligne" : "Brouillon"}</span>
          </Link>
        </h6>
        <div>
          <small>
            <i className="icon-placeholder" /> {d?.situation_geographique?.adresse}
          </small>
        </div>
        <div>
          <small>
            {d?.caracteristiques?.nombre_de_pieces || ""} ({d?.caracteristiques?.surface || ""} pied2)
          </small>
        </div>
        <div>
          <small>
            <span>{d?.caracteristiques?.chambres || ""} </span>
            chambres
          </small>
          <span>, </span>
          <small>
            <span>{d?.caracteristiques?.salles_de_bain || ""} </span>
            salles de bain
          </small>
        </div>
      </div>

      <div className="__card__actions">
        <Link to={linkEdit}>
          <i className="fa-solid fa-edit" />
        </Link>

        <a href="#ltn__utilize-confirm-box" className="ltn__utilize-toggle">
          <i className="fa-solid fa-trash-can" onClick={() => onDeleteProperties(id)} style={{ cursor: "pointer" }} />
        </a>
      </div>
    </div>
  );
};

export default CardRow;
