import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Navigate, Route } from "react-router-dom";

import Home from "./papers/Home";
import Owner from "./papers/Owner";
import Properties from "./papers/Properties";
import PropertyManage from "./papers/PropertyManage";
import PropertyDetails from "./papers/PropertyDetails";
import DemandeVisite from "./papers/DemandeVisite";

import BailManage from "./papers/BailManage";

import AccountDetails from "./papers/AccountDetails";
import AccountPassword from "./papers/AccountPassword";
import AccountMys from "./papers/AccountMys";
import AccountBails from "./papers/AccountBails";
import AccountFavs from "./papers/AccountFavs";
import AccountAlerts from "./papers/AccountAlerts";
import AccountDemandes from "./papers/AccountDemandes";
import AccountVisites from "./papers/AccountVisites";
import AccountBillings from "./papers/AccountBillings";

import Ombording from "./papers/Ombording";
import Login from "./papers/Login";
import Register from "./papers/Register";
import ResetPassword from "./papers/ResetPassword";

import Terms from "./papers/Terms";
import Privacy from "./papers/Privacy";

import Error404 from "./papers/Error404";

import storage from "./libs/storage";
import { SessionContext } from "./context";

const iniSession = { isLoading: true, isAuthed: false, isPro: false, isLoc: false, user: null, isOmbording: false };

const Root = () => {
  const [session, setSession] = useState(iniSession);

  const onInitSession = () => {
    const session = storage.getItem("__SESSION");
    const jwt = session?.jwt;
    const user = session?.user;
    const isPro = user?.type_de_compte === "Proprietaire" || user?.type_de_compte === "Gestionnaire";
    const isLoc = user?.type_de_compte === "Locataire";
    const isOmbording = !user?.type_de_compte;

    if (!!jwt && !!user) setSession({ isLoading: false, isAuthed: true, isPro, isLoc, user, isOmbording });
    else setSession({ ...iniSession, isLoading: false });
  };

  useEffect(() => {
    onInitSession();
  }, []);

  window.addEventListener("storage", () => {
    onInitSession();
  });

  if (session.isLoading) return null;

  if (session.isAuthed && session.isOmbording)
    return (
      <SessionContext.Provider value={session}>
        <Router>
          <Ombording />
        </Router>
      </SessionContext.Provider>
    );

  return (
    <SessionContext.Provider value={session}>
      <Router>
        <Routes>
          {/* PUBLIC */}
          <Route exact path="/" element={<Home />} />
          <Route exact path="/owner" element={<Owner />} />
          <Route exact path="/properties" element={<Properties />} />

          {/* CGU & PAP */}
          <Route path="/terms-and-conditions" element={<Terms />} />
          <Route path="/privacy-and-policy" element={<Privacy />} />

          {/* LOGIN */}
          <Route path="/login" element={!session?.isAuthed ? <Login /> : <Navigate to={"/account/details"} replace />} />
          <Route path="/login/facebook" element={!session?.isAuthed ? <Login /> : <Navigate to={"/account/details"} replace />} />
          <Route path="/login/google" element={!session?.isAuthed ? <Login /> : <Navigate to={"/account/details"} replace />} />

          {/* RESET-PASSWORD */}
          <Route path="/reset-password/:token" element={!session?.isAuthed ? <ResetPassword /> : <Navigate to={"/account/details"} replace />} />

          {/* REGISTER */}
          <Route path="/register/:plan" element={!session?.isAuthed ? <Register /> : <Navigate to={"/account/details"} replace />} />
          <Route path="/register" element={!session?.isAuthed ? <Register /> : <Navigate to={"/account/details"} replace />} />

          {/* PROPERTY */}
          <Route exact path="/properties/add" element={session?.isAuthed && session?.isPro ? <PropertyManage /> : <Navigate to={"/"} replace />} />
          <Route
            exact
            path="/properties/edit/:id"
            element={session?.isAuthed && session?.isPro ? <PropertyManage /> : <Navigate to={"/"} replace />}
          />
          <Route exact path="/properties/:id" element={<PropertyDetails />} />

          {/* VISITE */}
          <Route exact path="/properties/:id/demande-visite" element={<DemandeVisite />} />
          <Route exact path="/properties/:id/demande-visite/:vid" element={<DemandeVisite />} />
          <Route exact path="/properties/:id/review-visite/:vid" element={<DemandeVisite />} />

          {/* BAILS */}
          <Route exact path="/bails/add" element={session?.isAuthed && session?.isLoc ? <BailManage /> : <Navigate to={"/"} replace />} />
          <Route exact path="/bails/edit/:id" element={session?.isAuthed && session?.isLoc ? <BailManage /> : <Navigate to={"/"} replace />} />

          {/* ACCOUNT */}
          <Route path="/account/details" element={session?.isAuthed ? <AccountDetails /> : <Navigate to={"/"} replace />} />
          <Route path="/account/password" element={session?.isAuthed ? <AccountPassword /> : <Navigate to={"/"} replace />} />
          <Route path="/account/mes-logements" element={session?.isAuthed && session?.isPro ? <AccountMys /> : <Navigate to={"/"} replace />} />
          <Route path="/account/mes-bails" element={session?.isAuthed && session?.isLoc ? <AccountBails /> : <Navigate to={"/"} replace />} />
          <Route path="/account/mes-favories" element={session?.isAuthed && session?.isLoc ? <AccountFavs /> : <Navigate to={"/"} replace />} />
          <Route path="/account/mes-alertes" element={session?.isAuthed && session?.isLoc ? <AccountAlerts /> : <Navigate to={"/"} replace />} />
          <Route path="/account/mes-demandes" element={session?.isAuthed && session?.isLoc ? <AccountDemandes /> : <Navigate to={"/"} replace />} />
          <Route
            path="/account/mes-facturations"
            element={session?.isAuthed && session?.isPro ? <AccountBillings /> : <Navigate to={"/"} replace />}
          />
          <Route path="/account/mes-visite" element={session?.isAuthed && session?.isPro ? <AccountVisites /> : <Navigate to={"/"} replace />} />

          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
    </SessionContext.Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("locsimple"));
root.render(<Root />);
