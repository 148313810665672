import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import Icon from "../Icon";

import { GET_INIT_DATA, GET_SEARCH_PROPERTIES } from "../../services";
import { baseIniData, getDecodedFilters, getInitOptions } from "../../libs/entries";
import useMediaQuery from '@mui/material/useMediaQuery';
import MuiEntryAutocomplete from "../entries/autocomplete";
import MuiEntryAutocompleteGrouped from "../entries/autocomplete-grouped";
import EntryRange from "../entries/range";
import AlertDrawer from "../drawers/alert-drawer";

const SearchForm = ({ isLoading, setIsLoading, setProperties }) => {
  const params = useQuery();
  const navigate = useNavigate();
  const [initData, setInitData] = useState(baseIniData);
  const [filters, setFilters] = useState({ secteurs: [], pieces: [], animaux: [], min_m: 0, max_m: 0, dispo_array: [] });
  const [saveError, setSaveError] = useState("");
  const matchesBK = useMediaQuery('(max-width:992px)');

  const getPropertiesList = async (filters) => {
    if (isLoading) return;
    setIsLoading(true);

    const filtersValues = {
      min_m: filters.min_m,
      max_m: filters.max_m,
      animaux: filters.animaux.map((i) => i.value),
      pieces: filters.pieces.map((i) => i.value),
      secteurs: filters.secteurs.map((i) => i.value)
    };

    const { error, data } = await GET_SEARCH_PROPERTIES(filtersValues);

    setIsLoading(false);
    if (error) return setSaveError("Une erreur s'est produite réessayez");

    setProperties(data?.data || []);
  };

  const onSearchProperties = async (filters) => {
    const secteurs = filters.secteurs.map((p) => p.value).join(",");
    const pieces = filters.pieces.map((p) => p.value).join(",");
    const animaux = filters.animaux.map((a) => a.value).join(",");
    const min_m = filters.min_m;
    const max_m = filters.max_m;
    const dispo_array = filters.dispo_array.join(",");
    navigate(`/properties?secteurs=${secteurs}&pieces=${pieces}&animaux=${animaux}&min_m=${min_m}&max_m=${max_m}&dispo_array=${dispo_array}`, {
      replace: true
    });
    getPropertiesList(filters);
  };

  useEffect(() => {
    const onInitFilters = async () => {
      const { data } = await GET_INIT_DATA();
      let { animaux, dispo_array, max_m, min_m, pieces, secteurs } = getDecodedFilters(params, data);

      let filters = {
        secteurs: secteurs.map((v) => ({ label: v, value: v })),
        pieces: pieces.map((v) => ({ label: v, value: v })),
        animaux: animaux.map((v) => ({ label: v, value: v })),
        dispo_array,
        max_m,
        min_m
      };

      setInitData(data);
      setFilters(filters);
      onSearchProperties(filters);
    };

    onInitFilters();
  }, []);

  const { villeOptions, piecesOptions, animauxOptions } = getInitOptions(initData);
  

  return (
    <div className="ltn__car-dealer-form-area pb-115---">
      <div className="container">
        <div className="row">
          <div className="ltn__car-dealer-form-tab">
            <div className="tab-content bg-white box-shadow-1 position-relative">
              <div className="tab-pane fade active show" id="ltn__form_tab_1_1">
                <div className="car-dealer-form-inner">
                  <div className={matchesBK ? "ltn__car-dealer-form-box for-mobile" : "ltn__car-dealer-form-box"}>
                    <div className="__filters">
                      <MuiEntryAutocompleteGrouped
                        name="secteurs"
                        label="Région/Ville/Secteur"
                        value={filters.secteurs}
                        options={[...villeOptions]}
                        onChange={(secteurs) => {
                          setFilters((s) => ({ ...s, secteurs }));
                        }}
                      />

                      <MuiEntryAutocomplete
                        name="pieces"
                        label="Pièces"
                        value={filters.pieces}
                        options={[...piecesOptions]}
                        onChange={(pieces) => {
                          setFilters((s) => ({ ...s, pieces }));
                        }}
                      />
                      <MuiEntryAutocomplete
                        name="animaux"
                        label="Animaux"
                        value={filters.animaux}
                        options={[...animauxOptions]}
                        onChange={(animaux) => {
                          setFilters((s) => ({ ...s, animaux }));
                        }}
                      />

                      <EntryRange
                        _id="search"
                        range={true}
                        label="Loyer mensuel"
                        value={[parseInt(filters.min_m, 10), parseInt(filters.max_m, 10)]}
                        onChange={(range) => {
                          setFilters((s) => ({ ...s, min_m: range[0], max_m: range[1] }));
                        }}
                      />
                      
                      <button
                        type="submit"
                        className="btn theme-btn-1 btn-effect-1 text-uppercase mb-10 mt-10"
                        onClick={() => onSearchProperties(filters)}
                        style={{ padding: "17px 40px" }}>
                        <Icon icon="fa-solid fa-magnifying-glass" />
                      </button>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AlertDrawer
        populate={{
          custom: true,
          montant_du_loyer_minimum: filters.min_m || 100,
          montant_du_loyer_maximum: filters.max_m || 5000,
          secteurs: { data: filters.secteurs || [] },
          nombre_de_pieces: { data: filters.pieces || [] },
          animaux_acceptes: { data: filters.animaux || [] }
        }}
      />
    </div>
  );
};

export default SearchForm;
