import React, { useEffect, useState } from "react";
import Slider from "react-slick";

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <a className="slick-next slick-arrow" onClick={onClick}><i className="fas fa-arrow-right" alt="Arrow Icon"></i></a>
  )
}

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <a className="slick-prev slick-arrow" onClick={onClick}><i className="fas fa-arrow-left" alt="Arrow Icon"></i></a>
  )
}

const ProductSliderV1 = ({ gallerie }) => {
  const [images, setImages] = useState([]);
  let publicUrl = process.env.REACT_APP_AWS_CLOUDFRONT_DISTRIBUTION;

  const settings = {
    rtl: false,
    arrows: true,
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "450px",
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      { breakpoint: 1600, settings: { centerPadding: "250px" } },
      { breakpoint: 1200, settings: { centerPadding: "200px" } },
      { breakpoint: 992, settings: { arrows: false, dots: true, centerMode: false, centerPadding: "150px" } },
      { breakpoint: 768, settings: { centerPadding: "0px" } }
    ]
  };

  useEffect(() => {
    const $ = window.$;
    if (!images.length) return;
    $("a[data-rel^=lightcase]").lightcase({
      transition:
        "elastic" /* none, fade, fadeInline, elastic, scrollTop, scrollRight, scrollBottom, scrollLeft, scrollHorizontal and scrollVertical */,
      swipe: true,
      maxWidth: 1170,
      maxHeight: 600
    });
  }, [images]);

  useEffect(() => {
    let images =
      gallerie?.data?.map((i) => ({
        id: i.id,
        url: publicUrl + "/" + i.attributes?.hash + i.attributes?.ext,
        alt: ""
      })) || [];

    setImages(images);
  }, [gallerie]);

  if (!images.length) return <div className="ltn__img-slider-area mb-60" />;


  return (
    <div className="ltn__img-slider-area mb-60">
      <div className="container-fluid">
        <Slider className="row ltn__image-slider-5-active slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all" {...settings}>
          {images.map((img, idx) => (
            <div key={idx} className="col-lg-12">
              <div className={`ltn__img-slide-item-4`}>
                <a href={img.url} data-rel="lightcase:myCollection">
                  <img src={img.url} alt={img.alt} />
                </a>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ProductSliderV1;
