import React from "react";
import _ from "underscore";

const ConfirmBox = ({ title, handler }) => {
  // ...
  return (
    <div id="ltn__utilize-confirm-box" className="ltn__utilize ltn__utilize-cart-menu ltn__utilize-confirm-box">
      <div className="ltn__utilize-menu-inner" style={{ padding: "0" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__utilize-menu-head">
                <span className="ltn__utilize-menu-title">Êtes-vous sûr de vouloir supprimer {title}</span>
                <button className="ltn__utilize-close">×</button>
              </div>
            </div>
          </div>
        </div>

        <div className="mini-cart-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="btn-wrapper">
                  <button className="theme-btn-3 btn btn-effect-1 ltn__utilize-close" onClick={() => handler()}>
                    Cancel
                  </button>
                  <button className="theme-btn-1 btn btn-effect-1 ltn__utilize-close" onClick={() => handler(true)}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmBox;
