import React, { useState } from "react";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import { CHECKOUT } from "../../services";

const CardRow = ({ data, isFav, onDeleteProperties }) => {
  const [isLoading, setIsLoading] = useState(false);

  const id = data.id;
  const favId = data.favId;
  const d = data.attributes;
  const linkTo = `/properties/${id}`;
  const linkEdit = `/properties/edit/${id}`;
  const publicUrl = process.env.REACT_APP_AWS_CLOUDFRONT_DISTRIBUTION;
  const thumbnail = `${d?.gallerie?.data?.[0]?.attributes?.hash}${d?.gallerie?.data?.[0]?.attributes?.ext}`;
  const isPublished = !!d?.publishedAt;
  const isPayed = !!d?.payed;

  const onTryCheckout = async () => {
    if (isLoading) return;
    setIsLoading(true);
    // // ...
    const { error, data } = await CHECKOUT(id);

    setIsLoading(false);
    if (error) return;

    window.location.href = data?.checkout_url;
  };

  // ...
  return (
    <div className="__card" style={!isPayed ? { backgroundColor: "#0000000d" } : {}}>
      <div className="__card__img go-top">
        <Link to={linkTo}>{thumbnail && <img src={publicUrl + "/" + thumbnail} alt="#" />}</Link>
      </div>

      <div className="__card__info">
        <h6 className="__card__info__name go-top">
          <Link to={linkTo}>
            {d?.titre}
          </Link>
        </h6>
        {!isFav && <span style={{color: !isPayed?'red':isPublished ? "green" : "blue"}}>{!isPayed ? "(Cette annonce est désactivée)" : isPublished ? "(Cette annonce est en ligne)" : "(Cette annonce est en brouillon)"}</span>}
        <div>
          <small>
            {/* {d?.caracteristiques?.nombre_de_pieces || ""} de ({d?.caracteristiques?.surface || ""} pied2) à {d?.situation_geographique?.ville} */}
            {d?.situation_geographique?.adresse}
          </small>
        </div>
        <div>
          <small>
            <span>{d?.caracteristiques?.chambres || ""} </span>
            chambres
          </small>
          <span>, </span>
          <small>
            <span>{d?.caracteristiques?.salles_de_bain || ""} </span>
            salles de bain
          </small>
        </div>
      </div>

      <div className="__card__actions">
        {!isFav ? (
          <>
            {!isPayed ? (
              <button style={{ borderRadius: "26px", padding: "12px 30px" }} className="theme-btn-1 btn" onClick={onTryCheckout}>
                {isLoading ? (
                  <Icon
                    icon="fa-1x fa-solid fa-circle-notch fa-spin"
                    style={{
                      "--fa-animation-duration": "1s"
                    }}
                  />
                ) : (
                  <Icon icon="fa-solid fa-cart-shopping" />
                )}
              </button>
            ) : (
              <>
                <span>
                  <Link to={linkEdit}>
                    <i className="fa-solid fa-edit" />
                  </Link>
                </span>
                <span>
                  <a href="#ltn__utilize-confirm-box" className="ltn__utilize-toggle">
                    <i className="fa-solid fa-trash-can" onClick={() => onDeleteProperties(isFav ? favId : id)} style={{ cursor: "pointer" }} />
                  </a>
                </span>
              </>
            )}
          </>
        ) : (
          <>
            <a href="#ltn__utilize-confirm-box" className="ltn__utilize-toggle">
              <i className="fa-solid fa-trash-can" onClick={() => onDeleteProperties(isFav ? favId : id)} style={{ cursor: "pointer" }} />
            </a>
          </>
        )}
      </div>
    </div>
  );
};

export default CardRow;
