const storage = window.localStorage;

const sessionBroadcast = () => {
  window.dispatchEvent(new Event("storage"));
};

export const setItem = (key, value) => {
  const isObject = value instanceof Object;
  storage.setItem(key, isObject ? JSON.stringify(value) : value);
  // ...
  sessionBroadcast();
};

export const getItem = (key) => {
  const value = storage.getItem(key) || "{}";
  return JSON.parse(value);
};

export default { setItem, getItem };
