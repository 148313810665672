import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';

const PageHeader = () => {
  let publicUrl = process.env.PUBLIC_URL;
  const matchesPhone = useMediaQuery('(max-width:600px)');
  const matchesTablet = useMediaQuery('(max-width:980px)');
  const img = matchesPhone ? "/images/formulaire.jpg":  matchesTablet ? "/images/accueil.jpg" : "/images/titre-page.jpg";

  // ...
  return (
    <div
      className="text-left bg-overlay-white-30 bg-image pb-150 pt-150"
      data-bs-bg={publicUrl + img}
      style={{ margin: "0" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__breadcrumb-inner">
              <h1 className="page-title">Propriétaires</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
