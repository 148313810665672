import React, { useEffect, useState, useRef, useCallback } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

import Cropper from "cropperjs";
import cx from "classnames";

import Icon from "../Icon";
// import { ReactPanZoom } from "../custom/Ra";

import { ADD_FILE, ROT_FILE, DELETE_FILE } from "../../services";

const GalleryImage = ({ index, data, moveGalleryImage, onRotateFile, onDeleteFile, isProcessing }) => {
  const ref = useRef(null);
  const cRef = useRef();
  const imgRef = useRef({});

  const [isLoading, setIsLoading] = useState(false);

  const [{ handlerId }, drop] = useDrop({
    accept: "__gallery",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index;
      if (dragIndex === index) return;
      moveGalleryImage(dragIndex, index);
      item.index = index;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: "__gallery",
    canDrag: true,
    item: () => {
      return { data, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(drop(ref));

  const onHandleImg = async (rot) => {
    const canvas = cRef.current;
    const ctx = canvas.getContext("2d");

    const dir = imgRef.current.r;
    const w = imgRef.current.w;
    const h = imgRef.current.h;

    let width = 480;
    let height = width / (w / h);
    canvas.width = 480;
    canvas.height = 480;

    //? Clean
    ctx.clearRect(0, 0, 480, 480);
    ctx.setTransform(1, 0, 0, 1, 0, 0);

    //? Draw
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(dir * (Math.PI / 180));
    ctx.drawImage(imgRef.current.img, -width / 2, -height / 2, width, height);

    //? Reset
    ctx.translate(-canvas.width / 2, -canvas.height / 2);
    ctx.rotate(-dir * (Math.PI / 180));

    //? Update
    if (!!rot) {
      const id = data.id;
      onRotateFile(index, id, dir);
    }
  };

  useEffect(() => {
    if (!data?.url) return;
    const img = new Image();
    img.src = `${data.url}?rotate=90`;
    console.log("#URL :: ", `${data.url}?rotate=90`);
    img.setAttribute("crossorigin", "anonymous");
    img.onload = () => {
      imgRef.current = { img, w: img.width, h: img.height, r: 0 };
      onHandleImg();
    };
  }, [data]);

  return (
    <div ref={ref} key={index} className={cx("FormGallery__item", { __processing: !!isProcessing })} draggable={true} handlerId={handlerId}>
      <div className="__overlay">
        {!isProcessing && (
          <>
            <Icon
              icon="fa-solid fa-rotate-left"
              style={{
                color: "white"
              }}
              onClickHandler={() => {
                imgRef.current.r = (imgRef.current.r - 90) % 360;
                onHandleImg(true);
              }}
            />

            <Icon
              icon="fa-solid fa-trash"
              style={{
                color: "red"
              }}
              onClickHandler={() => {
                onDeleteFile(data.id);
              }}
            />

            <Icon
              icon="fa-solid fa-rotate-right"
              style={{
                color: "white"
              }}
              onClickHandler={() => {
                imgRef.current.r = (imgRef.current.r + 90) % 360;
                onHandleImg(true);
              }}
            />
          </>
        )}

        {isProcessing && (
          <>
            <Icon
              icon="fa-1x fa-solid fa-circle-notch fa-spin"
              style={{
                "--fa-animation-duration": "1s"
              }}
            />
          </>
        )}
      </div>

      <canvas ref={cRef}></canvas>
    </div>
  );
};

const FormGallery = ({ propId, values, setFieldValue, onBlur, ...props }) => {
  const inpRef = useRef();

  const savedFiles = values?.gallerie || [];
  const publicUrl = process.env.REACT_APP_AWS_CLOUDFRONT_DISTRIBUTION;

  const [galleryImages, setGalleryImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(-1);

  const onAddFile = async (e) => {
    const target = e.target;
    const files = target.files;
    if (!files.length) return;

    let form = new FormData();
    Array.from(files).forEach((file) => {
      form.append(`files`, file);
    });

    setIsUploading(true);
    const { error, data: newFiles } = await ADD_FILE(form);
    setIsUploading(false);

    if (error) return;
    setFieldValue("gallerie", [...savedFiles, ...newFiles.map((f) => ({ id: f.id, attributes: { ...f } }))]);

    if (inpRef && inpRef.current) {
      inpRef.current.value = "";
    }
  };

  const onRotateFile = async (idx, id, dir) => {
    setIsProcessing(idx);
    const { error, data: files } = await ROT_FILE({ id, dir });
    await DELETE_FILE(id);
    setIsProcessing(-1);
    if (error || files?.length === 0) return;

    const tmpF = [...files.map((f) => ({ id: f.id, attributes: { ...f } }))]?.[0];
    const tmp = [...savedFiles];
    tmp[idx] = tmpF;

    setFieldValue("gallerie", tmp);
  };

  const onDeleteFile = async (id) => {
    const { error, data } = await DELETE_FILE(id);
    if (error) return;
    // ...
    const deleteId = data.id;
    setFieldValue("gallerie", [...savedFiles.filter((f) => f.id !== deleteId)]);
  };

  useEffect(() => {
    const images = savedFiles.map((f) => ({ id: f.id, url: publicUrl + "/" + f.attributes?.hash + f.attributes?.ext }));
    setGalleryImages(images || []);
  }, [savedFiles]);

  const moveGalleryImage = useCallback(
    (dragIndex, hoverIndex) => {
      const tmp = update([...savedFiles], {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, savedFiles[dragIndex]]
        ]
      });
      setFieldValue("gallerie", [...tmp]);
    },
    [savedFiles]
  );

  const renderGalleryImages = useCallback(
    ({ idx, data, onRotateFile, onDeleteFile, isProcessing }) => {
      return (
        <GalleryImage
          key={idx}
          index={idx}
          data={data}
          moveGalleryImage={moveGalleryImage}
          onRotateFile={onRotateFile}
          onDeleteFile={onDeleteFile}
          isProcessing={idx === isProcessing}
        />
      );
    },
    [galleryImages]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="FormGallery">
        <h2>2. Photos</h2>

        {isUploading && (
          <div className="FormGallery__uploading">
            <Icon
              icon="fa-2x fa-solid fa-circle-notch fa-spin"
              style={{
                color: "#327abe",
                "--fa-animation-duration": "1s"
              }}
            />
            <span style={{ margin: "0 0 0 15px" }}>Chargement en cours</span>
          </div>
        )}

        {!isUploading && (
          <input
            style={{ borderColor: props?.touched?.gallerie === undefined ? "" : "#d32f2f" }}
            ref={inpRef}
            type="file"
            className="btn theme-btn-3 mb-10"
            onChange={onAddFile}
            accept="image/*"
            multiple
          />
        )}

        <div className="FormGallery__preview">
          {galleryImages.map((data, idx) => renderGalleryImages({ idx, data, onRotateFile, onDeleteFile, isProcessing }))}
        </div>

        {/* <canvas ref={canvasRef} style={{ border: "1px solid red" }}></canvas> */}

        <a id="link">Download</a>

        <p>
          <small style={{ fontWeight: "bold", color: props?.touched?.gallerie === undefined ? "" : "#d32f2f" }}>
            * Au moins 2 images sont requises pour une soumission valide.La taille minimale est de 500/500px.
          </small>
          <br />
          <small>* Les images peuvent prendre plus de temps à être traitées.</small>
        </p>
      </div>
    </DndProvider>
  );
};

export default FormGallery;
