import React, { useEffect, useState } from "react";

import { GET_MY_ALERTS, DELETE_ALERT } from "../../services";

import Navbar from "../../components/base/navbar";
import FakeHeader from "../../components/section/fake-header";
import Footer from "../../components/base/footer";

import Icon from "../../components/Icon";
import AccountNav from "../../components/base//account-nav";
import ConfirmBox from "../../components/base/confirm-box";

import CardRow from "../../components/property/alert-row";
import EmptyRow from "../../components/property/empty-row";
import AlertDrawer from "../../components/drawers/alert-drawer";
import useModal from "../../hooks/useModal";

const Account = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [alerts, setAlerts] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [edit, setEdit] = useState({});

  const getUserAlerts = async () => {
    setIsLoading(true);
    const { data } = await GET_MY_ALERTS();

    const alerts = (data?.data || []).map((a) => ({ id: a.id, ...a.attributes }));
    setAlerts(alerts);
    setEdit({});
    setIsLoading(false);
  };

  const onDeleteAlert = async (isDelete) => {
    if (!isDelete) return setDeleteId(null);
    if (isLoading) return;

    await DELETE_ALERT(deleteId);
    getUserAlerts();
  };

  useModal();

  useEffect(() => {
    getUserAlerts();
  }, []);

  return (
    <div className="__expended-container">
      <Navbar />
      <FakeHeader />

      <div className="liton__wishlist-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__product-tab-area">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="ltn__tab-menu-list mb-50">
                        <AccountNav tabIdx={4} />
                      </div>
                    </div>

                    <div className="col-lg-8">
                      <div className="tab-content">
                        <div className="tab-pane active show" id="ltn_tab_1_3">
                          <div className="ltn__myaccount-tab-content-inner">
                            {!!alerts.length && (
                              <>
                                <div className="__cards">
                                  <div className="__cards__header">
                                    <div className="__title">Mes Alertes</div>
                                    <div className="__actions">
                                      <div>Actions</div>
                                    </div>
                                  </div>
                                  <div className="__cards__body">
                                    {alerts.map((data, idx) => (
                                      <CardRow key={idx} data={data} onEditAlert={(data) => setEdit(data)} onDeleteAlert={(id) => setDeleteId(id)} />
                                    ))}
                                  </div>
                                </div>

                                {/* <Pages /> */}
                              </>
                            )}

                            {!!alerts.length && (
                              <div style={{ display: "flex", justifyContent: "center", margin: "50px 0 0 0" }}>
                                <a href="#ltn__utilize-alert-drawer" className="ltn__utilize-toggle theme-btn-3 btn btn-effect-1 __btn-icon">
                                  <Icon icon="fa-solid fa-bell" />
                                  Créer une alerte
                                </a>
                              </div>
                            )}

                            <EmptyRow title="Vous n'avez pas d'alerts" isLoading={isLoading} noEntities={!alerts.length}>
                              <a href="#ltn__utilize-alert-drawer" className="ltn__utilize-toggle theme-btn-3 btn btn-effect-1 __btn-icon">
                                <Icon icon="fa-solid fa-bell" />
                                Créer une alerte
                              </a>
                            </EmptyRow>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmBox title="cette alerte?" handler={onDeleteAlert} />

      <AlertDrawer isSection edit={edit} setEdit={setEdit} getUserAlerts={getUserAlerts} />

      <FakeHeader auto />
      <Footer />
    </div>
  );
};

export default Account;
