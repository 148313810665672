import React, { useContext } from "react";
import { Link } from "react-router-dom";

import storage from "../../libs/storage";
import { SessionContext } from "../../context";

const Navbar = () => {
  const publicUrl = process.env.PUBLIC_URL;
  const ctx = useContext(SessionContext);
  const isAuthed = !!ctx.isAuthed;
  const isPro = !!ctx.isPro;
  const isOmbording = !!ctx.isOmbording;

  const onLogoutUser = () => {
    storage.setItem("__SESSION", "{}");
  };

  return (
    <div style={{ borderBottom: "1px solid #06060612" }}>
      <header className="ltn__header-area ltn__header-5 ltn__header-transparent--- gradient-color-4---">
        <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
          <div className="container">
            <div className="row">
              <div className="col" style={{ flex: 4 }}>
                <div className="site-logo-wrap">
                  <div className="site-logo go-top" style={{ width: "100%", maxWidth: "170px" }}>
                    <Link to="/">
                      <img src={publicUrl + "/images/logo.png"} alt="Logo" />
                    </Link>
                  </div>
                </div>
              </div>

              {!isOmbording && (
                <div className="col header-menu-column" style={{ flex: 1 }}>
                  <div className="header-menu ">
                    <nav>
                      <div className="ltn__main-menu">
                        <ul style={{float: "right"}}>
                          <li className="go-top always-visible">
                            <Link to="/properties">À Louer</Link>
                          </li>

                          {isAuthed && !isPro && (
                            <li>
                              <Link to="/account/mes-alertes">Mes alertes</Link>
                            </li>
                          )}
                          {isAuthed && isPro && (
                            <>
                              <li>
                                <Link to="/owner">Services et tarifs</Link>
                              </li>
                              <li>
                                <Link to="/account/mes-logements">Mes annonces</Link>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              )}

              {!isOmbording && (
                <div className="col ltn__header-options ltn__header-options-2">
                  <div className="ltn__drop-menu user-menu">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="icon-user" />
                        </Link>

                        {isAuthed && (
                          <ul className="go-top" style={{ width: isPro ? "190px" : "150px" }}>
                            {!isPro && (
                              <>
                                <li className="__mobile">
                                  <Link to="/properties">À Louer</Link>
                                </li>
                                <li className="__mobile">
                                  <Link to="/account/mes-alertes">Mes alertes</Link>
                                </li>
                              </>
                            )}

                            {isPro && (
                              <>
                                <li className="">
                                  <Link to="/properties/add">Ajouter un logement</Link>
                                </li>
                                <li className="">
                                  <Link to="/owner">Services et tarifs</Link>
                                </li>
                                <li>
                                  <Link to="/account/mes-logements">Mes annonces</Link>
                                </li>
                              </>
                            )}
                            <li>
                              <Link to="/account/details">Mon compte</Link>
                            </li>
                            <li onClick={onLogoutUser}>
                              <Link to="/">Se déconnecter</Link>
                            </li>
                          </ul>
                        )}

                        {!isAuthed && (
                          <ul className="go-top">
                            <li className="__mobile">
                              <Link to="/properties">À Louer</Link>
                            </li>
                            <li>
                              <Link to="/login">Se connecter</Link>
                            </li>
                            <li>
                              <Link to="/register">Créer un compte</Link>
                            </li>
                          </ul>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              )}

              {isAuthed && isOmbording && (
                <div className="col ltn__header-options ltn__header-options-2 mb-sm-20">
                  <div className="ltn__drop-menu user-menu">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="icon-user" />
                        </Link>

                        <ul className="go-top" style={{ width: isPro ? "190px" : "150px" }}>
                          <li onClick={onLogoutUser}>
                            <Link to="/">Se déconnecter</Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
