import React, { useEffect, useState, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";

import { GET_PROPERTY, GET_BAIL } from "../../services";
import useQuery from "../../hooks/useQuery";

import Navbar from "../../components/base/navbar";
import Slider from "../../components/property/slider";
import Details from "../../components/property/details";
import PropertiesAction from "../../components/section/properties-action";
import Footer from "../../components/base/footer";
import { SessionContext } from "../../context";

const PropertyDetails = () => {
  const { id } = useParams();
  const isBail = !!useQuery()?.get("cession-de-bail");
  const ctx = useContext(SessionContext);
  const isAuthed = !!ctx.isAuthed;
  // ...
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  // ...
  const getProperty = async () => {
    if (isLoading) return;
    setIsLoading(true);

    let method = GET_PROPERTY;
    if (isBail) method = GET_BAIL;
    const { error, reason, data } = await method(id);
    setIsLoading(false);
    if (error) return;

    setData(data?.data?.attributes || {});
  };
  // ...
  useEffect(() => {
    getProperty();
  }, []);
  // ...
  return (
    <div>
      <Navbar />
      <Slider gallerie={data?.gallerie} />
      <Details id={id} data={data} isBail={isBail} isLoading={isLoading} />
      {!isAuthed && (
        <PropertiesAction
          title="Vous êtes propriétaire ?"
          subTitle="Affichez de facon rapide afin que vos annonces soient automatiquement envoyées à plusieurs locataires."
          ctaLabel="Soumission"
          ctaTarget="/owner"
        />
      )}
      <Footer />
    </div>
  );
};

export default PropertyDetails;
