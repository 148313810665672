import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";
import cx from "classnames";
import { withFormik } from "formik";
import moment from "moment";
import Moment from "react-moment";

import MuiEntryText from "../entries/text";
import MuiEntryDate from "../entries/date";
import MuiEntrySelect from "../entries/select";
import MuiEntryCheckbox from "../entries/checkbox";
import MuiEntryTextRich from "../entries/text-rich";

import storage from "../../libs/storage";
import { onScrollTop } from "../../libs/dom";

import { ADD_VISITE, EDIT_VISITE } from "../../services";
import { dureLocation } from "../../libs/entries";
import { validationSchema } from "./demande-visite-form-data";

const iniValues = validationSchema.cast();

const DAYS_NUM = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];

const DemandeVisiteForm = ({ lId, vId, logement, visite, isProView, isReadOnly, ...props }) => {
  const navigate = useNavigate();
  const params = useParams();
  let proprietaire = logement?.proprietaire?.data?.id;
  let propId = params.id;
  const locataire = storage.getItem("__SESSION")?.user?.id || null;

  const [isLoading, setIsLoading] = useState(false);
  const [saveError, setSaveError] = useState("");
  const cantSave = !_.isEmpty(props?.errors);

  const onSaveHandler = async () => {
    if (isLoading) return;
    setIsLoading(true);

    let req = { ...props.values };
    if (locataire) req.locataire = locataire;
    if (proprietaire) req.proprietaire = proprietaire;
    if (propId) req.logement = propId;

    const { error } = await ADD_VISITE(req);
    setIsLoading(false);
    if (error) return setSaveError("Une erreur s'est produite réessayez");

    navigate(`/properties/${lId}`);
    onScrollTop();
  };

  const onPropHandler = async (etat_visite) => {
    if (isLoading) return;
    setIsLoading(true);

    let req = { ...props.values };
    const { error } = await EDIT_VISITE(vId, { etat_visite, note: req.note || "", date_visite: req.date_visite || null });
    setIsLoading(false);
    if (error) return setSaveError("Une erreur s'est produite réessayez");

    navigate("/account/mes-visite");
    onScrollTop();
  };

  //# Date/Time Slots
  const slots = {};
  logement?.disponibilite_visite?.slots?.forEach((s) => {
    const dts = s.split("__");
    const st = parseInt(dts[0], 10);
    const et = parseInt(dts[1], 10);
    const dNum = DAYS_NUM.indexOf(dts[2]);

    const tmp = slots[dNum];

    if (!tmp) {
      slots[dNum] = [st, et];
    } else {
      const iSt = slots[dNum][0];
      const iEt = slots[dNum][1];
      slots[dNum] = [Math.min(iSt, st), Math.max(iEt, et)];
    }
  });

  //? Date Visite Options
  const dateVisiteOpts = props.values?.date_visite_options?.list || [];

  //? Used Dates
  const dvos = dateVisiteOpts
    .map((o) => {
      const d = o.date;
      if (!d) return null;
      return moment(d).format("YYYY-MM-DD");
    })
    .filter((o) => !!o);

  useEffect(() => {
    if (_.isEmpty(visite)) return;
    props.resetForm({ values: { ...iniValues, ...visite } });
  }, [visite]);

  return (
    <div className={cx("ltn__appointment-area pb-120 demande-visite", { __readonly: !!isReadOnly })}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__appointment-inner">
              <div>
                <h2>{isProView ? "Revue de la visit" : "Demande de visite"}</h2>
                <p>Logement situé à : {logement?.situation_geographique?.adresse}</p>
                <p>
                  <small>Les champs marqués avec un * sont obligatoires.</small>
                </p>

                <h6>Vos informations *</h6>
                <div className="row">
                  <div className="col-md-6">
                    <MuiEntryText
                      type="number"
                      name="nbr_adultes"
                      label="Nombre d'adultes qui abiteront dans l'appartement *"
                      value={props.values.nbr_adultes}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.touched?.nbr_adultes && props.errors?.nbr_adultes}
                      disabled={!!isReadOnly}
                    />
                  </div>
                  <div className="col-md-6">
                    <MuiEntryText
                      type="number"
                      name="nbr_enfants"
                      label="Nombre d'enfants qui abiteront dans l'appartement *"
                      value={props.values.nbr_enfants}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.touched?.nbr_enfants && props.errors?.nbr_enfants}
                      disabled={!!isReadOnly}
                    />

                    {!!props.values.nbr_enfants && props.values.nbr_enfants > 0 && (
                      <>
                        {[...Array(props.values.nbr_enfants)].map((x, index) => (
                          <MuiEntryText
                            type="number"
                            name={`ages_enfants.ages[${index}].age`}
                            label={`Age de l'enfant ${index + 1} *`}
                            value={props.values.ages_enfants?.ages?.[index]?.age}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={props.touched?.ages_enfants?.ages?.[index]?.age && props.errors?.ages_enfants?.ages?.[index]?.age}
                            disabled={!!isReadOnly}
                          />
                        ))}
                      </>
                    )}
                  </div>
                </div>

                {/* condidats_locataires */}
                {!!props.values.nbr_adultes && props.values.nbr_adultes > 0 && (
                  <>
                    {[...Array(props.values.nbr_adultes)].map((x, index) => (
                      <>
                        <h6>Condidat locataire {index + 1} *</h6>
                        <div className="manage-form-habite__item">
                          <div className="manage-form-habite__item__entries">
                            <MuiEntryText
                              name={`condidats_locataires.personnes[${index}].prenom`}
                              label="Prenom*"
                              value={props.values.condidats_locataires?.personnes?.[index]?.prenom}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={
                                props.touched?.condidats_locataires?.personnes?.[index]?.prenom &&
                                props.errors?.condidats_locataires?.personnes?.[index]?.prenom
                              }
                              disabled={!!isReadOnly}
                            />
                            <MuiEntryText
                              name={`condidats_locataires.personnes[${index}].nom`}
                              label="Nom*"
                              value={props.values.condidats_locataires?.personnes?.[index]?.nom}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={
                                props.touched?.condidats_locataires?.personnes?.[index]?.nom &&
                                props.errors?.condidats_locataires?.personnes?.[index]?.nom
                              }
                              disabled={!!isReadOnly}
                            />
                            <MuiEntryText
                              name={`condidats_locataires.personnes[${index}].telephone`}
                              label="Téléphone*"
                              value={props.values.condidats_locataires?.personnes?.[index]?.telephone}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={
                                props.touched?.condidats_locataires?.personnes?.[index]?.telephone &&
                                props.errors?.condidats_locataires?.personnes?.[index]?.telephone
                              }
                              disabled={!!isReadOnly}
                            />
                            <MuiEntryText
                              name={`condidats_locataires.personnes[${index}].courriel`}
                              label="Courriel*"
                              value={props.values.condidats_locataires?.personnes?.[index]?.courriel}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={
                                props.touched?.condidats_locataires?.personnes?.[index]?.courriel &&
                                props.errors?.condidats_locataires?.personnes?.[index]?.courriel
                              }
                              disabled={!!isReadOnly}
                            />
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                )}

                <div className="row">
                  <div className="col-md-6">
                    <p style={{ color: "#000" }}>Souhaitez-vous lier un endosseur ?</p>
                  </div>
                  <div className="col-md-3">
                    {/* endosseur */}
                    <MuiEntryCheckbox
                      name="lie_endosseur"
                      label="Oui"
                      value={!!props.values.lie_endosseur}
                      onChange={(e) => {
                        props.setFieldValue("lie_endosseur", true);
                      }}
                      disabled={!!isReadOnly}
                    />
                  </div>
                  <div className="col-md-3">
                    <MuiEntryCheckbox
                      name="lie_endosseur"
                      label="Non"
                      value={!props.values.lie_endosseur}
                      onChange={(e) => {
                        props.setFieldValue("lie_endosseur", false);
                      }}
                      disabled={!!isReadOnly}
                    />
                  </div>
                </div>

                {!!props.values.lie_endosseur && (
                  <div className="manage-form-habite__item">
                    <div className="manage-form-habite__item__entries">
                      <MuiEntryText
                        name={`endosseur.personnes[0].prenom`}
                        label="Prenom*"
                        value={props.values.endosseur?.personnes?.[0]?.prenom}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.touched?.endosseur?.personnes?.[0]?.prenom && props.errors?.endosseur?.personnes?.[0]?.prenom}
                        disabled={!!isReadOnly}
                      />
                      <MuiEntryText
                        name={`endosseur.personnes[0].nom`}
                        label="Nom*"
                        value={props.values.endosseur?.personnes?.[0]?.nom}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.touched?.endosseur?.personnes?.[0]?.nom && props.errors?.endosseur?.personnes?.[0]?.nom}
                        disabled={!!isReadOnly}
                      />
                      <MuiEntryText
                        name={`endosseur.personnes[0].telephone`}
                        label="Téléphone*"
                        value={props.values.endosseur?.personnes?.[0]?.telephone}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.touched?.endosseur?.personnes?.[0]?.telephone && props.errors?.endosseur?.personnes?.[0]?.telephone}
                        disabled={!!isReadOnly}
                      />
                      <MuiEntryText
                        name={`endosseur.personnes[0].courriel`}
                        label="Courriel*"
                        value={props.values.endosseur?.personnes?.[0]?.courriel}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        error={props.touched?.endosseur?.personnes?.[0]?.courriel && props.errors?.endosseur?.personnes?.[0]?.courriel}
                        disabled={!!isReadOnly}
                      />
                    </div>
                  </div>
                )}

                <h6>Informations de location *</h6>
                <div className="row">
                  <div className="col-md-6">
                    <MuiEntryText
                      type="date"
                      isShrinked
                      name="date_amenagement_souaite"
                      label="Vous souhaitez aménager quelle date *"
                      value={props.values.date_amenagement_souaite}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={props.touched?.date_amenagement_souaite && props.errors?.date_amenagement_souaite}
                      disabled={!!isReadOnly}
                    />
                  </div>
                  <div className="col-md-6">
                    <MuiEntrySelect
                      label="Durée de location *"
                      name="duree_location"
                      value={props.values.duree_location}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      options={dureLocation}
                      error={props.touched?.duree_location && props.errors?.duree_location}
                      disabled={!!isReadOnly}
                    />
                  </div>
                </div>

                {logement?.fumeur_vapoteur && (
                  <div className="row">
                    <div className="col-md-6">
                      <p style={{ color: "#000" }}>Occupant(s) fumeur ou vapoteur ?</p>
                    </div>
                    <div className="col-md-3">
                      <MuiEntryCheckbox
                        name="fumeur_vapoteur"
                        label="Oui"
                        value={!!props.values.fumeur_vapoteur}
                        onChange={() => {
                          props.setFieldValue("fumeur_vapoteur", true);
                        }}
                        disabled={!!isReadOnly}
                      />
                    </div>
                    <div className="col-md-3">
                      <MuiEntryCheckbox
                        name="fumeur_vapoteur"
                        label="Non"
                        value={!props.values.fumeur_vapoteur}
                        onChange={() => {
                          props.setFieldValue("fumeur_vapoteur", false);
                        }}
                        disabled={!!isReadOnly}
                      />
                    </div>
                  </div>
                )}

                {logement?.enquete_prelocation && (
                  <div className="row">
                    <div className="col-md-6">
                      <p style={{ color: "#000" }}>
                        Etes vous a l'aise avec les enquetes de prélocation (Vérification de crédit, criminel, regie du logement, etc)?
                      </p>
                    </div>
                    <div className="col-md-3">
                      <MuiEntryCheckbox
                        name="enquete_prelocation"
                        label="Oui"
                        value={!!props.values.enquete_prelocation}
                        onChange={() => {
                          props.setFieldValue("enquete_prelocation", true);
                        }}
                        disabled={!!isReadOnly}
                      />
                    </div>
                    <div className="col-md-3">
                      <MuiEntryCheckbox
                        name="enquete_prelocation"
                        label="Non"
                        value={!props.values.enquete_prelocation}
                        onChange={() => {
                          props.setFieldValue("enquete_prelocation", false);
                        }}
                        disabled={!!isReadOnly}
                      />
                    </div>
                  </div>
                )}

                {logement?.besoin_stationnement && (
                  <div className="row">
                    <div className="col-md-6">
                      <p style={{ color: "#000" }}>Avez vous besoin d'un stationnement?</p>
                    </div>
                    <div className="col-md-3">
                      <MuiEntryCheckbox
                        name="besoin_stationnement"
                        label="Oui"
                        value={!!props.values.besoin_stationnement}
                        onChange={() => {
                          props.setFieldValue("besoin_stationnement", true);
                        }}
                        disabled={!!isReadOnly}
                      />
                    </div>
                    <div className="col-md-3">
                      <MuiEntryCheckbox
                        name="besoin_stationnement"
                        label="Non"
                        value={!props.values.besoin_stationnement}
                        onChange={() => {
                          props.setFieldValue("besoin_stationnement", false);
                        }}
                        disabled={!!isReadOnly}
                      />
                    </div>
                  </div>
                )}

                {logement?.vous_avez_des_animaux && (
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <p style={{ color: "#000" }}>Avez vous des animaux?</p>
                      </div>
                      <div className="col-md-3">
                        <MuiEntryCheckbox
                          name="vous_avez_des_animaux"
                          label="Oui"
                          value={!!props.values.vous_avez_des_animaux}
                          onChange={() => {
                            props.setFieldValue("vous_avez_des_animaux", true);
                          }}
                          disabled={!!isReadOnly}
                        />
                      </div>
                      <div className="col-md-3">
                        <MuiEntryCheckbox
                          name="vous_avez_des_animaux"
                          label="Non"
                          value={!props.values.vous_avez_des_animaux}
                          onChange={() => {
                            props.setFieldValue("vous_avez_des_animaux", false);
                          }}
                          disabled={!!isReadOnly}
                        />
                      </div>
                    </div>
                    <div>
                      {/* Chat */}
                      <div className="row">
                        <div className="col-md-3">
                          <p style={{ color: "#000" }}>Précisez </p>
                        </div>
                        <div className="col-md-3">
                          <p style={{ color: "#5c727d" }}>Chat</p>
                        </div>
                        <div className="col-md-3">
                          <MuiEntryText
                            type="number"
                            label="Age (en mois)"
                            isShrinked
                            name="precision_animaux.chat.age"
                            value={props.values.precision_animaux?.chat?.age}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={props.touched?.precision_animaux?.chat?.age && props.errors?.precision_animaux?.chat?.age}
                            disabled={!!isReadOnly}
                          />
                        </div>
                        <div className="col-md-3">
                          <MuiEntryText
                            type="number"
                            label="Poids (en lbs)"
                            isShrinked
                            name="precision_animaux.chat.poids"
                            value={props.values.precision_animaux?.chat?.poids}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={props.touched?.precision_animaux?.chat?.poids && props.errors?.precision_animaux?.chat?.poids}
                            disabled={!!isReadOnly}
                          />
                        </div>
                      </div>

                      {/* Chien */}
                      <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-3">
                          <p style={{ color: "#5c727d" }}>Chien</p>
                        </div>
                        <div className="col-md-3">
                          <MuiEntryText
                            type="number"
                            label="Age (en mois)"
                            isShrinked
                            name="precision_animaux.chien.age"
                            value={props.values.precision_animaux?.chien?.age}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={props.touched?.precision_animaux?.chien?.age && props.errors?.precision_animaux?.chien?.age}
                            disabled={!!isReadOnly}
                          />
                        </div>
                        <div className="col-md-3">
                          <MuiEntryText
                            type="number"
                            label="Poids (en lbs)"
                            isShrinked
                            name="precision_animaux.chien.poids"
                            value={props.values.precision_animaux?.chien?.poids}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={props.touched?.precision_animaux?.chien?.poids && props.errors?.precision_animaux?.chien?.poids}
                            disabled={!!isReadOnly}
                          />
                        </div>
                      </div>

                      {/* Autre */}
                      <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-3">
                          <p style={{ color: "#5c727d" }}>Autre</p>
                        </div>
                        <div className="col-md-3">
                          <MuiEntryText
                            type="number"
                            label="Age (en mois)"
                            isShrinked
                            name="precision_animaux.autre.age"
                            value={props.values.precision_animaux?.autre?.age}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={props.touched?.precision_animaux?.autre?.age && props.errors?.precision_animaux?.autre?.age}
                            disabled={!!isReadOnly}
                          />
                        </div>
                        <div className="col-md-3">
                          <MuiEntryText
                            type="number"
                            label="Poids (en lbs)"
                            isShrinked
                            name="precision_animaux.autre.poids"
                            value={props.values.precision_animaux?.autre?.poids}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            error={props.touched?.precision_animaux?.autre?.poids && props.errors?.precision_animaux?.autre?.poids}
                            disabled={!!isReadOnly}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <h6>Vos disponibilités *</h6>
                <div className="row">
                  <div className="col-md-6">
                    <p style={{ color: "#000" }}>Vous souhaitez visiter le logement en </p>
                  </div>
                  <div className="col-md-3">
                    <MuiEntryCheckbox
                      name="type_visite"
                      label="Présentiel"
                      value={props.values.type_visite === "presentiel"}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        if (checked) props.setFieldValue("type_visite", "presentiel");
                      }}
                      disabled={!!isReadOnly}
                    />
                  </div>
                  <div className="col-md-3">
                    <MuiEntryCheckbox
                      name="type_visite"
                      label="A distance"
                      value={props.values.type_visite === "a_distance"}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        if (checked) props.setFieldValue("type_visite", "a_distance");
                      }}
                      disabled={!!isReadOnly}
                    />
                  </div>
                </div>

                {/* Calendar */}
                {!isProView && (
                  <div style={{ margin: "10px 0 0 0" }}>
                    {dateVisiteOpts.map((vDate, vIdx) => {
                      return (
                        <div key={vIdx} className="__visite__date">
                          <div className="__title">{`Date visite option ${vIdx + 1}`}</div>
                          <div className="__entries">
                            <div className="__date">
                              <MuiEntryDate
                                slots={slots}
                                value={vDate}
                                usedDates={dvos}
                                disabled={!!isReadOnly}
                                onChange={(date) => {
                                  const tmp = dateVisiteOpts.map((d, cvIdx) => {
                                    if (cvIdx === vIdx) return { ...vDate, date };
                                    return { ...d };
                                  });
                                  props.setFieldValue("date_visite_options.list", tmp);
                                }}
                                onChangeTimes={(times) => {
                                  const tmp = dateVisiteOpts.map((d, cvIdx) => {
                                    if (cvIdx === vIdx) return { ...vDate, times };
                                    return { ...d };
                                  });

                                  props.setFieldValue("date_visite_options.list", tmp);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {/* Calendar Finalize */}
                {!!isProView && (
                  <div style={{ margin: "10px 0 0 0" }}>
                    {dateVisiteOpts.map((vDate, vIdx) => {
                      const currDVR = props.values?.date_visite;
                      const currDV = currDVR ? new Date(currDVR) : null;

                      return (
                        <div key={vIdx} className="__visite__date__confirm">
                          <div className="__title">
                            <span>{`Option ${vIdx + 1}: `}</span>
                            <span style={{ fontWeight: 500, margin: "0 0 0 10px" }}>
                              <Moment format="DD MMMM YYYY">{vDate.date}</Moment>
                            </span>
                          </div>
                          <div className="__entries">
                            {vDate.times.map((vtDate, vtIdx) => {
                              const optDV = new Date(vtDate.value.replace(/-/g, "/"));
                              const optIsOn = currDV?.getTime() === optDV.getTime();

                              return (
                                <div key={vtIdx} className="__date">
                                  <MuiEntryCheckbox
                                    label={vtDate.label}
                                    value={!!optIsOn}
                                    onChange={() => {
                                      props.setFieldValue("date_visite", optIsOn ? null : optDV);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {/* Note */}
                {!!isProView && (
                  <div className="row">
                    <div className="col-md-6">
                      <p style={{ color: "#000" }}>Commentaire ou Note : </p>
                    </div>
                    <div className="col-md-12">
                      <MuiEntryTextRich
                        name="note"
                        placeholder="Commentaire ou Note*"
                        value={props.values.note}
                        onChange={props.setFieldValue}
                        onBlur={props.handleBlur}
                        error={props.touched?.note && props.errors?.note}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {!isReadOnly && (
          <>
            <div className={cx("alert alert-warning mt-50", { "d-none": !cantSave })} role="alert">
              <p className="alert__title">Veuillez remplir les champs obligatoires</p>
            </div>

            <div className={cx("alert alert-danger mt-50", { "d-none": !saveError })} role="alert">
              {saveError}
            </div>

            <div className="mt-50">
              <div className="btn-wrapper text-center go-top">
                <button
                  className="theme-btn-1 btn btn-effect-1"
                  type="submit"
                  onClick={() => onSaveHandler()}
                  style={{ height: "60px" }}
                  disabled={cantSave}>
                  Envoyer la demande de visite
                </button>
              </div>
            </div>
          </>
        )}

        {!!isProView && (
          <div className="btn-wrapper text-center go-top">
            <button
              className="theme-btn-3 btn btn-effect-1"
              type="submit"
              onClick={() => onPropHandler("annule")}
              style={{ width: "223px", height: "60px" }}>
              Decline
            </button>
            <button
              className="theme-btn-1 btn btn-effect-1"
              type="submit"
              disabled={!props.values?.date_visite}
              onClick={() => onPropHandler("valide")}
              style={{ width: "223px", height: "60px" }}>
              Accept
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default withFormik({
  validationSchema,
  validateOnMount: true,
  mapPropsToValues: () => ({ ...iniValues })
})(DemandeVisiteForm);
