import React, { useState } from "react";
import TextField from "@mui/material/TextField";

import cx from "classnames";

const MuiEntryText = ({
  inputRef = null,
  name,
  type = "text",
  multiline,
  label = "",
  value,
  placeholder = "",
  error,
  onChange,
  onBlur,
  isShrinked,
  readOnly,
  disabled,
  style = null
}) => {
  const [toggled, setToggled] = useState(false);
  const isPasswordField = type === "password";

  return (
    <div className="MuiEntry MuiEntryText">
      <TextField
        inputRef={inputRef}
        variant="outlined"
        label={label}
        type={toggled ? "text" : type}
        name={name}
        // placeholder={placeholder}
        disabled={disabled}
        placeholder={label}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={readOnly}
        // InputLabelProps={{ shrink: isShrinked }}
        InputLabelProps={{ shrink: true }}
        error={!!error}
        helperText={!!error ? error : ""}
        multiline={multiline}
        minRows={5}
        maxRows={5}
        style={style}
      />

      {isPasswordField && (
        <div className="__password-toggler" onClick={() => setToggled((s) => !s)}>
          {toggled && <i className="fa-solid fa-eye-slash" />}
          {!toggled && <i className="fa-solid fa-eye" />}
        </div>
      )}
    </div>
  );
  // return (
  //   <div className={cx("input-item", { [classes]: !!classes })}>
  //     {!!label && <label>{label}</label>}

  //     <input
  //       ref={inputRef}
  //       type={toggled ? "text" : type}
  //       name={name}
  //       placeholder={placeholder}
  //       // min={0}
  //       value={value}
  //       onChange={(e) => {
  //         onChange(e);
  //       }}
  //       onClick={(e) => {
  //         e.preventDefault();
  //       }}
  //       readOnly={readOnly}
  //     />

  //     {isPasswordField && (
  //       <div className="__password-toggler" onClick={() => setToggled((s) => !s)}>
  //         {toggled && <i className="fa-solid fa-eye-slash" />}
  //         {!toggled && <i className="fa-solid fa-eye" />}
  //       </div>
  //     )}
  //   </div>
  // );
};

export default MuiEntryText;
