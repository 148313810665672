import React, { useState } from "react";

import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const EmptyTag = () => <></>;

const MuiEntryAutocompleteGrouped = ({ name, value = [], label = "", onChange, options, isOnlyOption }) => {
  const [openGroupKey, setOpenGroupKey] = useState(null);

  const onChangeAllHandler = (checked, group) => {
    const groupOpts = options.filter((opt) => opt.groupBy === group);
    const groupOptsValues = options.filter((opt) => opt.groupBy === group).map((opt) => opt.value);
    const valueOpts = [...value].filter((opt) => groupOptsValues.indexOf(opt.value) === -1);

    if (checked) {
      onChange([...valueOpts, ...groupOpts]);
    } else {
      onChange([...valueOpts]);
    }
  };

  const onChangeHandler = (e, values) => {
    if (isOnlyOption) {
      const value = [...values]?.[values.length - 1]?.value || "";
      const groupBy = options.find((o) => o.value === value)?.groupBy || "";
      onChange(value, groupBy);
    } else {
      onChange([...values]);
    }
  };

  return (
    <div className="MuiEntry MuiEntryAutocomplete">
      <Autocomplete
        multiple
        name={name}
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        groupBy={(option) => option.groupBy}
        isOptionEqualToValue={(o, v) => o.value === v.value}
        value={value}
        onChange={onChangeHandler}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} className="MuiEntryAutocomplete__option">
              <Checkbox checked={selected} />
              {option.label}
            </li>
          );
        }}
        renderTags={(tags) => {
          if (!isOnlyOption) return <EmptyTag />;
          return tags.map((tag) => <Chip label={tag.value} />);
        }}
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder={!!value?.length && !isOnlyOption ? `${value.length} selected` : ""} />
        )}
        renderGroup={({ key, group, children }) => {
          const innersValues = options.filter((opt) => opt.groupBy === group).map((opt) => opt.value);
          const innersChecked = value.filter((opt) => innersValues.indexOf(opt.value) > -1);
          const isGroupChecked = innersValues.length === innersChecked.length;
          const isGroupIndeterm = innersChecked.length > 0;

          return (
            <div key={key} className="MuiEntryAutocomplete__group">
              <div className="__label">
                <ArrowDropDownIcon/>
                <span
                  className="__inner"
                  onClick={() => {
                    const k = key === openGroupKey ? null : key;
                    setOpenGroupKey(k);
                  }}>
                  {group}
                </span>

                {!isOnlyOption && (
                  <div className="__checkbox">
                    <Checkbox
                      checked={isGroupChecked}
                      indeterminate={isGroupIndeterm && !isGroupChecked}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        onChangeAllHandler(checked, group);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="__children">{key === openGroupKey && children}</div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default MuiEntryAutocompleteGrouped;
