import React from "react";

import Navbar from "../../components/base/navbar";
import FakeHeader from "../../components/section/fake-header";
import LoginForm from "../../components/section/login-form";
import LoginForgot from "../../components/section/login-forgot";
import Footer from "../../components/base/footer";

const RegisterV1 = () => {
  return (
    <div className="__expended-container">
      <Navbar />
      <FakeHeader />
      <LoginForm />
      <LoginForgot />
      <FakeHeader auto />
      <Footer />
    </div>
  );
};

export default RegisterV1;
