import React, { useContext, useState, useEffect } from "react";
import Moment from "react-moment";
import { SessionContext } from "../../context";
import { Link } from "react-router-dom";
import storage from "../../libs/storage";
import { ADD_FAV, DELETE_FAV } from "../../services";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDog, faCat, faBath, faBed, faBan } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";

const Card = ({ data, faved }) => {
  const navigate = useNavigate();
  const [favedData, setFavedData] = useState({ isOn: false, favId: null });

  const id = data.id;
  const d = data.attributes;
  const adType = data?.type || "";

  let linkTo = `/properties/${id}`;
  if (adType === "bail") linkTo = `/properties/${id}?cession-de-bail=true`;

  const publicUrl = process.env.REACT_APP_AWS_CLOUDFRONT_DISTRIBUTION;
  const thumbnail = `${d?.gallerie?.data?.[0]?.attributes?.hash}${d?.gallerie?.data?.[0]?.attributes?.ext}`;

  const userId = storage.getItem("__SESSION")?.user?.id;
  const owner = data?.proprietaire?.data;
  const isOwner = userId === owner?.id;
  const ctx = useContext(SessionContext);
  const isAuthed = !!ctx.isAuthed;

  const onFavProperty = async () => {
    if (favedData.isOn && favedData.favId) {
      await DELETE_FAV(favedData.favId);
      setFavedData({ isOn: false, favId: null });
    } else {
      const res = await ADD_FAV({ client: userId, logement: data?.id });
      setFavedData({ isOn: true, favId: res?.data?.data?.id });
    }
  };

  useEffect(() => {
    (faved || []).forEach((f) => {
      const lId = parseInt(id, 10);
      const fId = f?.id;
      const flId = f?.attributes?.logement?.data?.id;
      if (!!fId && lId === flId) setFavedData({ isOn: true, favId: fId });
    });
  }, []);

  return (
    <div onClick={(e) => {
      if (e.target.tagName !== "I") {
        navigate(`${linkTo}`);
      }
    }} className="col-xl-4 col-sm-6 col-12 __custom-card">
      <div className="ltn__product-item ltn__product-item-4 text-center---">
        <div className="product-img go-top">
          <Link to={linkTo}>{thumbnail && <img src={publicUrl + "/" + thumbnail} alt="#" />}</Link>
          <div className="product-badge">
            <ul>
              <li className={`sale-badge + ${adType === "bail" ? "bg-orange" : "bg-green"}`}>{adType === "bail" ? "Cession de bail" : "à louer"}</li>
            </ul>
          </div>
          <div className="product-img-location-gallery">
            <div className="product-img-location">
              <ul>
                <li>
                  <i className="flaticon-pin" /> {d?.situation_geographique?.secteur}
                </li>
                <li>
                  <i className="far fa-calendar-alt" /> <Moment format="DD-MM-YYYY">{d.description.disponibilite}</Moment>
                </li>
              </ul>
            </div>
            <div className="product-img-gallery go-top">
              <ul>
                <li>
                  <Link to={linkTo}>
                    <i className="fas fa-camera" /> {d?.gallerie?.data?.length}
                  </Link>
                </li>
                {/* <li>
                  <Link to={linkTo}><i className="fas fa-film" /> 2</Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="product-info">
          <h2 className="product-title go-top">
            <Link to={linkTo}>
              {/* {d?.titre} */}
              {d?.situation_geographique?.secteur}
              {/* {d?.caracteristiques?.nombre_de_pieces} */}
            </Link>
          </h2>
          <ul className="ltn__list-item-2 ltn__list-item-2-before">
            <li>
              <span>
                {d?.caracteristiques?.chambres || ""} <FontAwesomeIcon icon={faBed} />
              </span>
              Chambre
            </li>
            <li>
              <span>
                {d?.caracteristiques?.salles_de_bain || ""} <FontAwesomeIcon icon={faBath} />
              </span>
              Salle de bain
            </li>
            <li>
              <span>
                {d?.caracteristiques?.animaux_acceptes === "Chat et chien" && (<><FontAwesomeIcon icon={faCat} /> / <FontAwesomeIcon icon={faDog} /></>)}
                {d?.caracteristiques?.animaux_acceptes === "Chat" && (<FontAwesomeIcon icon={faCat} />)}
                {/* {d?.caracteristiques?.animaux_acceptes === "Non applicable" && (<><FontAwesomeIcon icon={faDog} /> + <FontAwesomeIcon icon={faCat} /></>)} */}
                {d?.caracteristiques?.animaux_acceptes === "Aucun" && (<FontAwesomeIcon icon={faBan} />)}
              </span>
              {/* {(d?.caracteristiques?.animaux_acceptes === "Chat et chien") ? "Chat et chien": ((d?.caracteristiques?.animaux_acceptes === "Chat autorise") ? "Chat autorisé": d?.caracteristiques?.animaux_acceptes)} */}
              Animaux
            </li>
          </ul>
        </div>
        <div className="product-info-bottom">
          <div className="product-price">
            <span>
              ${d.description?.montant_du_loyer}
              <label>/Mois</label>
            </span>
          </div>
          <div className="product-hover-action">
            <ul>
              {!isOwner && isAuthed && (
                <li>
                  <div className={"__custom-fav"} onClick={() => onFavProperty()}>
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      {favedData.isOn ? <i className="fas fa-heart" /> : <i className="flaticon-heart-1" />}
                    </a>
                  </div>
                </li>
              )}
              {/* <li>
                <span className="go-top">
                  <Link to={linkTo} title="Product Details">
                    <i className="flaticon-add" />
                  </Link>
                </span>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
