import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "underscore";

import Icon from "../Icon";
import MuiEntryText from "../entries/text";
import MuiEntrySelect from "../entries/select";

import { GET_USER_PROFILE, EDIT_USER_PROFILE } from "../../services";
import storage from "../../libs/storage";

import { withFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  firstname: yup.string().required("Ce champs est obligatoire").default(""),
  lastname: yup.string().required("Ce champs est obligatoire").default(""),
  lastname: yup.string().required("Ce champs est obligatoire").default(""),
  birthdate: yup.string().default(""),
  telephone: yup.string().required().default(""),
  type_de_compte: yup.string().required("Ce champs est obligatoire").default(""),
  nombre_de_portes: yup
    .string()
    .notRequired()
    .when("type_de_compte", {
      is: (type_de_compte) => type_de_compte === "Proprietaire" || type_de_compte === "Gestionnaire",
      then: (schema) => schema.required("Ce champs est obligatoire")
    })
});

const iniValues = validationSchema.cast();

const Ombording = (props) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const cantSave = !_.isEmpty(props?.errors);

  const session = storage.getItem("__SESSION") || { jwt: "", user: {} };
  const userId = session.user?.id || null;

  const onUpdateAccount = async () => {
    if (isLoading) return;
    setIsLoading(true);

    let req = {
      firstname: props.values.firstname,
      lastname: props.values.lastname,
      type_de_compte: props.values.type_de_compte,
      birthdate: props.values.birthdate,
      telephone: props?.values.telephone
    };

    if (props.values.type_de_compte === "Proprietaire" || props.values.type_de_compte === "Gestionnaire")
      req.nombre_de_portes = { nombre_de_portes: props.values.nombre_de_portes };

    // TODO: Verify this
    const { error, data } = await EDIT_USER_PROFILE(userId, req);
    setIsLoading(false);

    if (error) return setLoginError("Une erreur s'est produite réessayez");

    storage.setItem("__SESSION", { ...session, user: { ...session.user, ...data } });
    navigate("/");
  };

  const getUserProfile = async () => {
    setIsLoading(true);

    const { data } = await GET_USER_PROFILE();
    props.resetForm({ values: { ...iniValues, firstname: data?.firstname, lastname: data?.lastname } });
    props.validateForm();
    setIsLoading(false);
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div className="ltn__login-area pb-110">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area text-center">
              <h1 className="section-title">
                Complete <br />
                Your Account
              </h1>
            </div>
          </div>
        </div>

        {!!loginError && (
          <div className="row">
            <div className="col-lg-12 form-messege">
              <p className="error text-center">{loginError}</p>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="account-login-inner">
              <div className="ltn__form-box contact-form-box">
                <MuiEntryText
                  type="text"
                  name="firstname"
                  label="Prénom*"
                  value={props.values.firstname}
                  onBlur={props.handleBlur}
                  error={props.touched?.firstname && props.errors?.firstname}
                  onChange={props.handleChange}
                />

                <MuiEntryText
                  type="text"
                  name="lastname"
                  label="Nom*"
                  value={props.values.lastname}
                  onBlur={props.handleBlur}
                  error={props.touched?.lastname && props.errors?.lastname}
                  onChange={props.handleChange}
                />

                <MuiEntryText
                  type="date"
                  name="birthdate"
                  label="Date de naissance"
                  value={props.values.birthdate}
                  onBlur={props.handleBlur}
                  error={props.touched?.birthdate && props.errors?.birthdate}
                  onChange={props.handleChange}
                  isShrinked
                />

                <MuiEntryText
                  type="phone"
                  name="telephone"
                  label="Téléphone*"
                  value={props?.values.telephone}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={props.touched?.telephone && props.errors?.telephone}
                />

                <MuiEntrySelect
                  name="type_de_compte"
                  value={props.values.type_de_compte}
                  label="Type de compte*"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={props.touched?.type_de_compte && props.errors?.type_de_compte}
                  options={[
                    { label: "Propriétaire", value: "Proprietaire" },
                    { label: "Gestionnaire", value: "Gestionnaire" },
                    { label: "Locataire", value: "Locataire" }
                  ]}
                />
                {(props.values.type_de_compte === "Proprietaire" || props.values.type_de_compte === "Gestionnaire") && (
                  <MuiEntrySelect
                    name="nombre_de_portes"
                    value={props.values.nombre_de_portes}
                    label="Nombre de portes*"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={props.touched?.nombre_de_portes && props.errors?.nombre_de_portes}
                    options={[
                      { label: "1 à 10", value: "1 a 10" },
                      { label: "10 à 25", value: "10 a 25" },
                      { label: "25 à  50", value: "25 a  50" },
                      { label: "50 à 100 ", value: "50 a 100 " },
                      { label: "100 et +", value: "100 et plus" }
                    ]}
                  />
                )}

                <div className="btn-wrapper">
                  <button
                    className="theme-btn-1 btn reverse-color btn-block"
                    type="submit"
                    onClick={onUpdateAccount}
                    style={{ width: "223px", height: "60px" }}
                    disabled={cantSave}>
                    {!isLoading && "UPDTAE"}
                    {isLoading && (
                      <Icon
                        icon="fa-1x fa-solid fa-circle-notch fa-spin"
                        style={{
                          "--fa-animation-duration": "1s"
                        }}
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFormik({
  validationSchema,
  validateOnMount: true,
  mapPropsToValues: () => ({ ...iniValues })
})(Ombording);
