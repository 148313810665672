import React from "react";
import Moment from "react-moment";

const CardRow = ({ data }) => {
  const d = data.attributes;

  const date = d?.date;
  const status = d?.status;
  const invoice_pdf = d?.invoice_pdf;
  const payment_link_url = d?.payment_link_url;

  return (
    <tr className="__c__card__row">
      <td>
        <Moment format="MMMM YYYY">{date}</Moment>
      </td>

      <td>{status ? "Payé" : "Non payé"}</td>

      <td style={{ textAlign: "right" }}>
        {status ? (
          <a href={invoice_pdf} target="__blank" style={{ textDecoration: "underline" }}>
            Ouvrir
          </a>
        ) : (
          <a href={payment_link_url} target="__blank" style={{ textDecoration: "underline" }}>
            Payez maintenant
          </a>
        )}
      </td>
    </tr>
  );
};

export default CardRow;
