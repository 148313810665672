import React from "react";

const MuiEntryPhoto = ({ inputRef = null, value, onChange, onDelete }) => {
  let src = null;
  if (value) src = value?.hash + value?.ext;

  const publicUrl = process.env.REACT_APP_AWS_CLOUDFRONT_DISTRIBUTION;

  return (
    <div className="entry-photo">
      <label>
        <input
          ref={inputRef}
          // ...
          type="file"
          onChange={onChange}
          accept="image/*"
        />

        {!src && (
          <div className="__empty">
            <p>
              Sélectionner
              <br /> une photo
            </p>
          </div>
        )}

        {!!src && (
          <>
            <div className="__img">
              <img src={`${publicUrl}/${src}`} alt="" />
            </div>
            <div className="__del" onClick={(e) => onDelete(e)}>
              <i className="fa-solid fa-trash-can" />
            </div>
          </>
        )}
      </label>
    </div>
  );
};

export default MuiEntryPhoto;
