import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate, useHref } from "react-router-dom";
import Moment from "react-moment";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import Icon from "../Icon";
import cx from "classnames";
import Avatar from "@mui/material/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDog, faCat, faBath, faBed, faBan } from "@fortawesome/free-solid-svg-icons";
import Map from "./manage-form-map";
import DetailsContact from "./details-contact";
import DemandeDeVisite from "../../components/drawers/alert-drawer";
import storage from "../../libs/storage";
import { SessionContext } from "../../context";
import { amenitiesList } from "../../libs/entries";
import { CHECKOUT, GET_MY_FAVS, ADD_FAV, DELETE_FAV } from "../../services";
import useModal from "../../hooks/useModal";

const PropertyDetails = ({ id, data, isBail, isLoading }) => {
  const $ = window.$;
  const navigate = useNavigate();
  const aHref = useHref();
  const [favedData, setFavedData] = useState({ isOn: false, favId: null });
  const [phoneIsvisible, setPhoneIsVisible] = useState(false);
  const [checkoutIsLoading, setCheckoutIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  let publicUrl = process.env.REACT_APP_AWS_CLOUDFRONT_DISTRIBUTION;

  const userId = storage.getItem("__SESSION")?.user?.id;
  const owner = data?.proprietaire?.data;
  const ctx = useContext(SessionContext);
  const isAuthed = !!ctx.isAuthed;
  const isOwner = userId === owner?.id;
  const ownerPhoto = owner?.attributes?.photo || null;
  const ownerFName = owner?.attributes?.firstname || "";
  const ownerLName = owner?.attributes?.lastname || "";
  const ownerPhoneNumber = owner?.attributes?.telephone || "";
  const isDraft = !data?.publishedAt;
  const isPayed = data?.payed;

  useModal();

  const onEditProperty = () => {
    if (isBail) navigate(`/bails/edit/${id}`);
    else navigate(`/properties/edit/${id}`);

    $(".quarter-overlay").fadeIn(1);
    $(window).scrollTop(0);
    setTimeout(function () {
      $(".quarter-overlay").fadeOut(300);
    }, 800);
  };

  const onFavProperty = async () => {
    if (favedData.isOn && favedData.favId) {
      await DELETE_FAV(favedData.favId);
      setFavedData({ isOn: false, favId: null });
    } else {
      const res = await ADD_FAV({ client: userId, logement: id });
      setFavedData({ isOn: true, favId: res?.data?.data?.id });
    }
  };

  const getUserFavs = async () => {
    const { data } = await GET_MY_FAVS();
    (data?.data || []).forEach((f) => {
      const lId = parseInt(id, 10);
      const fId = f?.id;
      const flId = f?.attributes?.logement?.data?.id;

      if (!!fId && lId === flId) setFavedData({ isOn: true, favId: fId });
    });
  };

  const onTryCheckout = async () => {
    if (checkoutIsLoading) return;
    setCheckoutIsLoading(true);

    const { error, data } = await CHECKOUT(id);

    if (error) {
      setCheckoutIsLoading(false);
      return setErrors("Une erreur s'est produite réessayez");
    }
    window.location.href = data?.checkout_url;
  };

  useEffect(() => {
    if (isAuthed && !isOwner) {
      getUserFavs();
    }
  }, []);

  return (
    <div className="ltn__shop-details-area pb-10">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="ltn__shop-details-inner ltn__page-details-inner mb-60">
              <div className="__details-actions">
                <h1>{data?.titre}</h1>

                {!isBail && !isLoading && (
                  <div className="__details-actions-btns">
                    {isOwner && isAuthed && (
                      <>
                        {!isPayed ? (
                          <button className="theme-btn-1 btn btn-effect-1" onClick={onTryCheckout}>
                            {checkoutIsLoading ? (
                              <Icon
                                icon="fa-1x fa-solid fa-circle-notch fa-spin"
                                style={{
                                  "--fa-animation-duration": "1s"
                                }}
                              />
                            ) : (
                              <>
                                <Icon icon="fa-solid fa-cart-shopping" />
                              </>
                            )}
                            <span
                              style={{
                                "padding-left": "8px"
                              }}>
                              Ré-activer
                            </span>
                          </button>
                        ) : (
                          <div className="__custom-edit" onClick={() => onEditProperty()}>
                            <span>Éditer</span>
                            <i className="fa-solid fa-pen-to-square __icon"></i>
                          </div>
                        )}
                      </>
                    )}
                    {!isOwner && isAuthed && (
                      <div className={cx("__custom-fav", { __faved: favedData.isOn })} onClick={() => onFavProperty()}>
                        <i className="fa-2x fa-solid fa-heart __icon"></i>
                      </div>
                    )}
                  </div>
                )}

                {isBail && !isLoading && (
                  <div className="__details-actions-btns">
                    {isOwner && isAuthed && (
                      <div className="__custom-edit" onClick={() => onEditProperty()}>
                        <span>Éditer</span>
                        <i className="fa-solid fa-pen-to-square __icon"></i>
                      </div>
                    )}

                    {!isOwner && isAuthed && (
                      <div className={cx("__custom-fav", { __faved: favedData.isOn })} onClick={() => onFavProperty()}>
                        <i className="fa-2x fa-solid fa-heart __icon"></i>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {isOwner && isDraft && isPayed && (
                <div className="__details-stauts">
                  <h2>Enregistré en tant que draft</h2>
                </div>
              )}

              {isOwner && isDraft && !isPayed && (
                <div className="__details-stauts">
                  <h2>Cette annonce est désactivée. </h2>
                </div>
              )}

              <label>
                <span className="ltn__secondary-color">
                  <i className="flaticon-pin" />
                </span>{" "}
                {data?.situation_geographique?.adresse}
              </label>
              <label style={{ display: "inline-block", padding: "0 10px" }} />
              <label>
                <span className="ltn__secondary-color">
                  <i className="far fa-calendar-alt" />
                </span>{" "}
                {data?.description?.disponibilite && <Moment format="DD-MM-YYYY">{data.description.disponibilite}</Moment>}
              </label>

              <h4 className="title-2">Description</h4>
              {data.description?.description && <ReactMarkdown rehypePlugins={[rehypeRaw]}>{data.description.description}</ReactMarkdown>}

              <h4 className="title-2">Détails du logement</h4>
              <div className="property-detail-info-list section-bg-1 clearfix mb-60">
                <ul>
                  <li>
                    <label>Nombre de pièces:</label> <span>{data.caracteristiques?.nombre_de_pieces}</span>
                  </li>
                  <li>
                    <label>Chambres:</label> <span>{data.caracteristiques?.chambres}</span>
                  </li>
                  <li>
                    <label>Salles de bain:</label> <span>{data.caracteristiques?.salles_de_bain}</span>
                  </li>
                </ul>
                <ul>
                  {/* <li>
                    <label>Adresse:</label> <span>{data?.situation_geographique?.adresse}</span>
                  </li> */}
                  {data.caracteristiques?.animaux_acceptes && (
                    <li>
                      <label>Animaux: </label>
                      {data.caracteristiques?.animaux_acceptes === "Chat et chien" && (
                        <>
                          <FontAwesomeIcon icon={faCat} /> / <FontAwesomeIcon icon={faDog} />
                        </>
                      )}
                      {data.caracteristiques?.animaux_acceptes === "Chat" && <FontAwesomeIcon icon={faCat} />}
                      {data.caracteristiques?.animaux_acceptes === "Aucun" && <FontAwesomeIcon icon={faBan} />}
                      <span>{data?.caracteristiques?.animaux_acceptes === "Chat et chien" && " chat et chien sont acceptés"}</span>
                      <span>{data?.caracteristiques?.animaux_acceptes === "Chat" && " seulement les chats sont acceptés"}</span>
                      <span>{data?.caracteristiques?.animaux_acceptes === "Aucun" && " aucun animal accepté"}</span>
                    </li>
                  )}

                  {data.caracteristiques?.surface && (
                    <li>
                      <label>Surface: </label> <span>{data.caracteristiques?.surface} pied2</span>
                    </li>
                  )}

                  {data.description?.montant_du_loyer && (
                    <li>
                      <label>Loyer:</label> <span>{data.description?.montant_du_loyer} $</span>
                    </li>
                  )}
                </ul>
              </div>

              {/* <h4 className="title-2 mb-20">Amenities</h4> */}
              <div className="property-details-amenities mb-60">
                <div className="row">
                  {amenitiesList.map((a, idx) => (
                    <div key={idx} className="col-lg-3 col-md-6">
                      <div style={{ fontWeight: "bold", margin: "0 0 20px 0" }}>{a.label}</div>
                      <div className="ltn__menu-widget">
                        <ul className="ltn__menu-widget">
                          {a.options.map((o, oIdx) => {
                            const checked = !!data?.[a.name]?.[o.name];
                            // ...
                            return (
                              <li key={oIdx}>
                                <label className="checkbox-item no-hover" style={{ cursor: "default" }}>
                                  {o.label}
                                  <input type="checkbox" checked={checked} readOnly={true} />
                                  <span className="checkmark" />
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <h4 className="title-2">Adresse du logement</h4>
              <label>
                <span className="ltn__secondary-color">
                  <i className="flaticon-pin" />
                </span>{" "}
                {data?.situation_geographique?.adresse}
              </label>
              <div className="property-details-google-map mb-60">
                <Map data={data} />
              </div>
            </div>
          </div>

          {!isLoading && (
            <div className="col-lg-4">
              <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
                <div className="widget ltn__author-widget" style={{ padding: isAuthed ? "35px 30px 40px 35px" : "30px 20px 30px 20px" }}>
                  <div className="ltn__author-widget-inner text-center">
                    {isAuthed && (
                      <>
                        {!!ownerPhoto?.data?.attributes?.formats?.small?.url && (
                          <img src={publicUrl + "/" + ownerPhoto?.data?.attributes?.hash + ownerPhoto?.data?.attributes?.ext} alt="owner photo" />
                          // <Avatar
                          //   alt="owner photo"
                          //   src={publicUrl + "/" +  ownerPhoto?.data?.attributes?.hash +  ownerPhoto?.data?.attributes?.ext}
                          //   sx={{ width: 56, height: 56 }}
                          // />
                        )}
                        <h5>
                          {ownerFName} {ownerLName}
                        </h5>
                        {ownerPhoneNumber && (
                          <h5
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setPhoneIsVisible(!phoneIsvisible);
                            }}>
                            {!phoneIsvisible && (
                              <span style={{ color: "var(--ltn__secondary-color)" }}>
                                {ownerPhoneNumber.substring(0, 2) + ownerPhoneNumber.substring(2).replace(/./g, "x")}
                              </span>
                            )}
                            {phoneIsvisible && (
                              <a style={{ color: "var(--ltn__secondary-color)" }} href={"tel:" + ownerPhoneNumber}>
                                {ownerPhoneNumber}
                              </a>
                            )}
                          </h5>
                        )}
                      </>
                    )}

                    <div
                      className="ltn__utilize-toggle theme-btn-1 btn btn-effect-1"
                      style={{ width: "100%" }}
                      onClick={() => {
                        navigate(`/properties/${id}/demande-visite`);
                      }}>
                      Demande de visite
                    </div>

                    {!isAuthed && (
                      <div
                        style={{ display: "flex", alignItems: "center", margin: "40px 0 0 0" }}
                        onClick={() => {
                          storage.setItem("__action_reg", { url: aHref });
                        }}>
                        <i className="fa-solid fa-circle-info" style={{ color: "#327abe", fontSize: "24px", margin: "0 10px 0 0" }}></i>
                        <p style={{ margin: "0" }}>
                          <Link to="/register">
                            <span style={{ color: "rgb(50, 122, 190)", textDecoration: "unset" }}>Créez un compte</span>
                          </Link>{" "}
                          <span>pour voir les coordonnées.</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {!isOwner && isAuthed && <DetailsContact id={id} data={data} isBail={isBail} />}
              </aside>
            </div>
          )}

          <div className="col-lg-12" style={{ margin: "0 0 200px 0" }}></div>
        </div>
      </div>
      <DemandeDeVisite />
    </div>
  );
};

export default PropertyDetails;
