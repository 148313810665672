import React from "react";

import Navbar from "../../components/base/navbar";
import FakeHeader from "../../components/section/fake-header";
import OmbordingForm from "../../components/section/ombording-form";
import Footer from "../../components/base/footer";

const RegisterV1 = () => {
  // ...
  return (
    <div>
      <Navbar />
      <FakeHeader />
      <OmbordingForm />
      <Footer />
    </div>
  );
};

export default RegisterV1;
