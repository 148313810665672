import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import _ from "underscore";

import Icon from "../Icon";
import MuiEntryText from "../entries/text";
import MuiEntryCheckbox from "../entries/checkbox";
import MuiEntrySelect from "../entries/select";
import LoginSocial from "../../components/base/login-social";

import { USER_REGISTER } from "../../services";
import storage from "../../libs/storage";

import { withFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  firstname: yup.string().required().default(""),
  lastname: yup.string().required().default(""),
  telephone: yup.string().required().default(""),
  email: yup.string().required().default(""),
  password: yup.string().required().default(""),
  type_de_compte: yup.string().required().default(""),
  nombre_de_portes: yup
    .string()
    .notRequired()
    .when("type_de_compte", {
      is: (type_de_compte) => type_de_compte === "Proprietaire" || type_de_compte === "Gestionnaire",
      then: (schema) => schema.required()
    }),

  // readPolicy: yup.bool().oneOf([true]).default(false),
  acceptPolicy: yup.bool().oneOf([true]).default(false)
});

const iniValues = validationSchema.cast();

const Register = ({ values, errors, ...props }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const cantSave = !_.isEmpty(errors);

  const onTryRegister = async () => {
    if (isLoading) return;
    setIsLoading(true);
    // ...
    let req = {
      username: values.email,
      firstname: values.firstname,
      lastname: values.lastname,
      birthdate: values.birthdate,
      telephone: values.telephone,
      email: values.email,
      password: values.password,
      type_de_compte: values.type_de_compte,
      nombre_de_portes: { nombre_de_portes: values.nombre_de_portes }
    };

    const { error, data } = await USER_REGISTER(req);
    setIsLoading(false);

    if (error) return setLoginError("Une erreur s'est produite réessayez");

    //? Redirect if set
    const urlReg = storage.getItem("__action_reg");

    if (!!urlReg?.url) {
      navigate(urlReg.url);
      storage.setItem("__SESSION", data);
      storage.setItem("__action_reg", {});
    } else {
      navigate("/");
      storage.setItem("__SESSION", data);
    }
  };

  /* eslint-disable */
  const onClick = (e) => {
    e.preventDefault();
    grecaptcha?.ready(function () {
      grecaptcha?.execute("6LeK8rMkAAAAAKxC7wcKPg6NjhtgaFuuR9N9AGC0", { action: "submit" }).then(function (token) {
        // onTryRegister();
      });
    });
  };
  /* eslint-enable */

  return (
    <div className="ltn__login-area pb-110">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title-area text-center">
              <h1 className="section-title">
                Inscrivez vous <br />
                via
              </h1>
            </div>
          </div>
        </div>

        <LoginSocial />
        <h4 style={{ textAlign: "center" }}>Ou</h4>
        {!!loginError && (
          <div className="row">
            <div className="col-lg-12 form-messege">
              <p className="error text-center">{loginError}</p>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="account-login-inner">
              <div className="ltn__form-box contact-form-box">
                <MuiEntryText type="text" name="firstname" label="Prénom*" value={values.firstname} onChange={props.handleChange} />
                <MuiEntryText type="text" name="lastname" label="Nom*" value={values.lastname} onChange={props.handleChange} />

                <MuiEntryText
                  type="date"
                  name="birthdate"
                  label="Date de naissance"
                  value={values.birthdate}
                  onChange={props.handleChange}
                  isShrinked
                />

                <MuiEntryText type="email" name="email" label="Email*" value={values.email} onChange={props.handleChange} />
                <MuiEntryText type="phone" name="telephone" label="Téléphone*" value={values.telephone} onChange={props.handleChange} />

                <MuiEntrySelect
                  name="type_de_compte"
                  value={values.type_de_compte}
                  label="Type de compte*"
                  onChange={props.handleChange}
                  options={[
                    { label: "Propriétaire", value: "Proprietaire" },
                    { label: "Gestionnaire", value: "Gestionnaire" },
                    { label: "Locataire", value: "Locataire" }
                  ]}
                />

                {(values.type_de_compte === "Proprietaire" || values.type_de_compte === "Gestionnaire") && (
                  <MuiEntrySelect
                    name="nombre_de_portes"
                    value={values.nombre_de_portes}
                    label="Nombre de portes*"
                    onChange={props.handleChange}
                    options={[
                      { label: "1 à 10", value: "1 a 10" },
                      { label: "10 à 25", value: "10 a 25" },
                      { label: "25 à  50", value: "25 a  50" },
                      { label: "50 à 100 ", value: "50 a 100 " },
                      { label: "100 et +", value: "100 et plus" }
                    ]}
                  />
                )}

                <MuiEntryText type="password" name="password" label="Mot de passe*" value={values.password} onChange={props.handleChange} />

                {/* <MuiEntryCheckbox
                  name="readPolicy"
                  label="I consent to Herboil processing my personal data in order to send personalized marketing material in accordance with the consent form and the privacy policy."
                  value={values.readPolicy}
                  onChange={props.handleChange}
                /> */}
                <MuiEntryCheckbox
                  name="acceptPolicy"
                  label="En cliquant sur 'créer mon compte', j'accepte la politique de confidentialité."
                  value={values.acceptPolicy}
                  onChange={props.handleChange}
                />

                <div className="btn-wrapper">
                  <button
                    className="theme-btn-1 btn reverse-color btn-block"
                    type="submit"
                    onClick={onTryRegister}
                    style={{ height: "60px" }}
                    disabled={cantSave}>
                    {!isLoading && "Créer mon compte"}
                    {isLoading && (
                      <Icon
                        icon="fa-1x fa-solid fa-circle-notch fa-spin"
                        style={{
                          "--fa-animation-duration": "1s"
                        }}
                      />
                    )}
                  </button>
                </div>
              </div>

              <div className="by-agree text-center">
                <p>
                  En créant votre compte, vous acceptez nos{" "}
                  <a href="/privacy-and-policy" target="_blank">
                    Politique de confidentialité
                  </a>
                </p>
                {/* <p>
                  <a href="/terms-and-conditions" target="_blank">
                    TERMS OF CONDITIONS
                  </a>
                  <span>&nbsp; &nbsp; | &nbsp; &nbsp;</span>
                  <a href="/privacy-and-policy" target="_blank">
                    Politique de confidentialité
                  </a>
                </p> */}
                <div className="go-to-btn mt-50 go-top">
                  <Link to="/login">Vous avez déjà un compte? cliquez ici pour vous connectez</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFormik({
  validationSchema,
  validateOnMount: true,
  mapPropsToValues: () => ({ ...iniValues })
})(Register);
