import React from "react";

import Navbar from "../../components/base/navbar";
import FakeHeader from "../../components/section/fake-header";
import ResetPasswordForm from "../../components/section/resert-form";
import Footer from "../../components/base/footer";

const ResetPassword = () => {
  return (
    <div className="__expended-container">
      <Navbar />
      <FakeHeader />
      <ResetPasswordForm />
      <FakeHeader auto />
      <Footer />
    </div>
  );
};

export default ResetPassword;
