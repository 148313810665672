import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/base/navbar";
import FakeHeader from "../../components/section/fake-header";
import Footer from "../../components/base/footer";
import AccountNav from "../../components/base//account-nav";
import ConfirmBox from "../../components/base/confirm-box";
import CardRow from "../../components/property/card-row";
import EmptyRow from "../../components/property/empty-row";
import useModal from "../../hooks/useModal";

import { GET_MY_FAVS, DELETE_FAV } from "../../services";

const Account = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [favProperties, setFavProperties] = useState([]);
  const [deleteId, setDeleteId] = useState(null);

  const getUserFavs = async () => {
    setIsLoading(true);
    const { data } = await GET_MY_FAVS();
    const favsList = (data?.data || []).map((f) => ({ ...f?.attributes?.logement?.data, favId: f.id })).filter((l) => !!l);

    setFavProperties(favsList);
    setIsLoading(false);
  };

  const onDeleteProperties = async (isDelete) => {
    if (!isDelete) return setDeleteId(null);
    if (isLoading) return;

    await DELETE_FAV(deleteId);
    getUserFavs();
  };

  useModal();

  useEffect(() => {
    getUserFavs();
  }, []);

  return (
    <div className="__expended-container">
      <Navbar />
      <FakeHeader />

      <div className="liton__wishlist-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__product-tab-area">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="ltn__tab-menu-list mb-50">
                        <AccountNav tabIdx={3} />
                      </div>
                    </div>

                    <div className="col-lg-8">
                      <div className="tab-content">
                        <div className="tab-pane active show" id="ltn_tab_1_3">
                          <div className="ltn__myaccount-tab-content-inner">
                            {!!favProperties.length && (
                              <>
                                <div className="__cards">
                                  <div className="__cards__header">
                                    <div className="__title">Logements</div>
                                    <div className="__actions">
                                      <div>Actions</div>
                                    </div>
                                  </div>
                                  <div className="__cards__body">
                                    {favProperties.map((data, idx) => (
                                      <CardRow key={idx} data={data} isFav={true} onDeleteProperties={(id) => setDeleteId(id)} />
                                    ))}
                                  </div>
                                </div>
                                {/* <div className="ltn__my-properties-table table-responsive">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th scope="col">Logement</th>
                                        <th scope="col" />
                                        <th scope="col" />
                                        <th scope="col" />
                                        <th scope="col" style={{ textAlign: "right" }}>
                                          Actions
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {favProperties.map((data, idx) => (
                                        <CardRow key={idx} data={data} isFav={true} onDeleteProperties={(id) => setDeleteId(id)} />
                                      ))}
                                    </tbody>
                                  </table>
                                </div> */}

                                {/* <Pages /> */}
                              </>
                            )}

                            <EmptyRow title="Vous n'avez pas de favoris" isLoading={isLoading} noEntities={!favProperties.length}>
                              <Link className="theme-btn-3 btn btn-effect-1" to="/properties">
                                Découvrez
                              </Link>
                            </EmptyRow>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmBox title="ce logement?" handler={onDeleteProperties} />

      <FakeHeader auto />
      <Footer />
    </div>
  );
};

export default Account;
