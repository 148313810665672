import React, { useEffect, useState, useRef } from "react";

import MuiEntryAutocompleteGrouped from "../entries/autocomplete-grouped";
import MuiEntryText from "../entries/text";
import { GET_INIT_DATA } from "../../services";
import { baseIniData, getInitOptions } from "../../libs/entries";

const FormMap = ({ values, handleChange, handleBlur, touched, errors, ...props }) => {
  const [initData, setInitData] = useState(baseIniData);

  const google = window.google;
  const searchBoxRef = useRef(null);
  const inputRef = useRef(null);
  const situation_geographique = values?.situation_geographique;

  const onPlacesChanged = () => {
    const adresse = inputRef.current.value;
    const places = searchBoxRef.current.getPlaces();
    const gps = places?.[0]?.geometry?.location;

    props.setFieldValue("situation_geographique.adresse", adresse);
    props.setFieldValue("situation_geographique.gps.lat", gps?.lat?.() || "");
    props.setFieldValue("situation_geographique.gps.long", gps?.lng?.() || "");
  };

  const onInitFilters = async () => {
    const { data } = await GET_INIT_DATA();
    setInitData(data);
  };

  useEffect(() => {
    if (!inputRef.current || !google) return;
    if (!searchBoxRef?.current) searchBoxRef.current = new google.maps.places.SearchBox(inputRef.current);
    if (searchBoxRef.current) searchBoxRef.current.addListener("places_changed", onPlacesChanged);

    inputRef.current.value = situation_geographique?.adresse || "";

    return () => {
      google.maps.event.clearInstanceListeners(searchBoxRef?.current);
    };
  }, [values, inputRef.current]);

  useEffect(() => {
    onInitFilters();
  }, []);

  const { villeOptions } = getInitOptions(initData);

  return (
    <>
      <h2>3. Adresse</h2>
      <h6>Adresse du logement *</h6>

      <div className="row  mb-50">
        <div className="col-md-6">
            <MuiEntryText
              inputRef={inputRef}
              type="text"
              name="situation_geographique.adresse"
              label="Adresse *"
              value={values?.situation_geographique.adresse}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched?.situation_geographique?.adresse && errors?.situation_geographique?.adresse}
            />
        </div>

        <div className="col-md-6">
          <MuiEntryAutocompleteGrouped
            isOnlyOption
            name="secteurs"
            label="Région/Ville/Secteur *"
            value={situation_geographique?.secteur ? [{ label: situation_geographique?.secteur, value: situation_geographique?.secteur }] : []}
            onChange={(secteur, ville) => {
              props.setFieldValue("situation_geographique.ville", ville);
              props.setFieldValue("situation_geographique.secteur", secteur);
            }}
            options={villeOptions}
          />
        </div>
      </div>
    </>
  );
};

export default FormMap;
