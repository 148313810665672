import "react-markdown-editor-lite/lib/index.css";
import { ErrorMessage } from 'formik';

import cx from "classnames";

import MarkdownIt from "markdown-it";
import React from "react";
import MdEditor, { Plugins } from "react-markdown-editor-lite";

MdEditor.unuse(Plugins.Clear);

const mdParser = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true
});

const MuiEntryTextRich = ({ classes, name, value, placeholder = "", onChange, error, onBlur }) => {
  const onChangeHandler = ({ html, text }) => {
    // if(text.length > 3000 ) return;
    onChange(name, text);
  };

  const renderHTML = (text) => {
    return mdParser.render(text);
  };

  return (
    <div className="MuiEntry MuiEntryTextRich">
      <MdEditor
        className="BaseMarkdown"
        style={{borderColor:!!error ? "#d32f2f" : "#e0e0e0"}}
        // ...
        name={name}
        placeholder={placeholder}
        value={value || ""}
        onChange={onChangeHandler}
        isFullScreen={true}
        view={{ menu: true, md: true, html: false }}
        renderHTML={renderHTML}
        onBlur={onBlur}
        error={!!error}
        helperText={!!error ? error : ""}
      />
      <div style={{color: "#d32f2f"}}>
        <ErrorMessage name="description.description" />
      </div>
      {/* <p className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained MuiFormHelperText-filled css-1wc848c-MuiFormHelperText-root" >3000 caractères au maximum</p> */}
    </div>
  );
};

export default MuiEntryTextRich;
