import React, { useEffect, useState } from "react";
import _ from "underscore";

import EntryRange from "../entries/range";
import MuiEntryAutocomplete from "../entries/autocomplete";
import MuiEntryAutocompleteGrouped from "../entries/autocomplete-grouped";

import storage from "../../libs/storage";

import { GET_INIT_DATA, ADD_ALERT, EDIT_ALERT } from "../../services";
import { baseIniData, lotSizesOptions, getDateRanges, getInitOptions } from "../../libs/entries";

import { withFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup
  .object({
    secteurs: yup.object({ data: yup.array().min(1) }).required(),
    nombre_de_pieces: yup.object({ data: yup.array().min(1) }).required(),
    animaux_acceptes: yup.object({ data: yup.array().min(1) }).required(),
    montant_du_loyer_minimum: yup.string().required().min(0),
    montant_du_loyer_maximum: yup.string().required().min(0)
  })
  .default({
    secteurs: { data: [] },
    // ...
    nombre_de_pieces: { data: [] },
    animaux_acceptes: { data: [] },
    // ...
    disponibilite: { data: [], options: [] },
    // ...
    montant_du_loyer_minimum: "100",
    montant_du_loyer_maximum: "5000"
  });

const iniValues = validationSchema.cast();

const AlertForm = ({ isSection, populate = {}, edit = {}, setEdit, getUserAlerts, ...props }) => {
  const [initData, setInitData] = useState(baseIniData);
  const [isLoading, setIsLoading] = useState(false);
  const cantSave = !_.isEmpty(props.errors);
  const isEdit = !!edit?.id;
  const client = storage.getItem("__SESSION")?.user || null;

  const onSaveAlert = async () => {
    if (isLoading) return;
    setIsLoading(true);

    let filtersValues = {
      montant_du_loyer_minimum: props.values.montant_du_loyer_minimum,
      montant_du_loyer_maximum: props.values.montant_du_loyer_maximum,
      animaux_acceptes: { data: props.values.animaux_acceptes.data.map((i) => i.value) },
      nombre_de_pieces: { data: props.values.nombre_de_pieces.data.map((i) => i.value) },
      secteurs: { data: props.values.secteurs.data.map((i) => i.value) }
    };

    if (client && client.id) filtersValues.client = client.id;

    if (isEdit) {
      delete filtersValues.id;
      const id = edit.id;

      await EDIT_ALERT(id, filtersValues);
    } else await ADD_ALERT(filtersValues);

    if (isSection) getUserAlerts();
    setIsLoading(false);
  };

  const onResetForm = () => {
    if (setEdit) setEdit({});
    props.resetForm({ values: { ...iniValues } });
  };

  const onInitData = async () => {
    const { data } = await GET_INIT_DATA();
    setInitData(data);
  };

  useEffect(() => {
    if (!edit?.id) return;

    const editvalues = {
      montant_du_loyer_minimum: edit.montant_du_loyer_minimum,
      montant_du_loyer_maximum: edit.montant_du_loyer_maximum,
      animaux_acceptes: { data: edit.animaux_acceptes.data.map((i) => ({ label: i, value: i })) },
      nombre_de_pieces: { data: edit.nombre_de_pieces.data.map((i) => ({ label: i, value: i })) },
      secteurs: { data: edit.secteurs.data.map((i) => ({ label: i, value: i })) }
    };

    props.resetForm({ values: { ...iniValues, ...editvalues } });
  }, [edit]);

  useEffect(() => {
    if (!populate?.custom) return;
    const data = { ...populate };
    delete data.custom;
    props.resetForm({ values: { ...iniValues, ...data } });
  }, [populate]);

  useEffect(() => {
    if (!client) return;
    props.resetForm({ values: { ...iniValues } });
    onInitData();
  }, []);

  const { villeOptions, animauxOptions } = getInitOptions(initData);

  return (
    <div id="ltn__utilize-alert-drawer" className="ltn__utilize ltn__utilize-cart-menu">
      <div className="ltn__utilize-menu-inner" style={{ padding: "0" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__utilize-menu-head">
                <span className="ltn__utilize-menu-title">Créer une alerte</span>
                <button className="ltn__utilize-close" onClick={onResetForm}>
                  ×
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mini-cart-footer" style={{ margin: "0" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p>Pour ne pas manquer aucune nouvelle annonce correspondant à vos critère, créez votre alerte courielle personnalisée.</p>
                <div className="btn-wrapper"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="mini-cart-product-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <MuiEntryAutocompleteGrouped
                  name="secteurs"
                  label="Région/Ville/Secteur"
                  value={props.values?.secteurs?.data || []}
                  onChange={(data) => {
                    // if (isEdit) return;
                    props.setFieldValue("secteurs", { data });
                  }}
                  options={villeOptions}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <MuiEntryAutocomplete
                  name="nombre_de_pieces"
                  label="Nombre de pièces"
                  value={props.values?.nombre_de_pieces?.data || []}
                  onChange={(data) => {
                    // if (isEdit) return;
                    props.setFieldValue("nombre_de_pieces", { data });
                  }}
                  options={lotSizesOptions}
                />
              </div>
              <div className="col-lg-6">
                <MuiEntryAutocomplete
                  name="animaux_acceptes"
                  label="Animaux acceptes"
                  value={props.values?.animaux_acceptes?.data || []}
                  onChange={(data) => {
                    // if (isEdit) return;
                    props.setFieldValue("animaux_acceptes", { data });
                  }}
                  options={animauxOptions}
                />
              </div>
            </div>

            <div className="row" style={{ padding: "0 10px" }}>
              <EntryRange
                _id="alert"
                range={true}
                label="Montant du loyer"
                isReadOnly={isEdit}
                value={[parseInt(props.values.montant_du_loyer_minimum, 10), parseInt(props.values.montant_du_loyer_maximum, 10)]}
                onChange={(range) => {
                  // if (isEdit) return;
                  props.setFieldValue("montant_du_loyer_minimum", range[0]);
                  props.setFieldValue("montant_du_loyer_maximum", range[1]);
                }}
              />
            </div>
          </div>
        </div>

        <div className="mini-cart-footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="btn-wrapper">
                  {/* {!isEdit && ( */}
                    <button className="theme-btn-1 btn btn-effect-1 ltn__utilize-close" onClick={() => onSaveAlert()} disabled={cantSave}>
                      Sauvegarder mon alerte
                    </button>
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFormik({
  validationSchema,
  validateOnMount: true,
  mapPropsToValues: () => ({ ...iniValues })
})(AlertForm);
